import React, { useState, useReducer, useContext, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import { useDropzone } from 'react-dropzone';
import QRCode from 'qrcode.react';
import jsQR from 'jsqr';
import './App.css';
import decimal from 'decimal.js';
import * as bip39 from 'bip39';
import BIP32Factory from 'bip32';
import ECPairFactory from 'ecpair';
import ecc from '@bitcoinerlab/secp256k1';
import coininfo from 'coininfo';
import * as bitcoin from 'bitcoinjs-lib';
import * as bitcoinMessage from 'bitcoinjs-message';
import logoMonashell from './monashell_logo_logo.png';
import logoMonacotto from './logoMonacotto.png';
import iconMpurse from './mpurse.png';
import iconLogin from './login_black_24dp.svg';
import iconLoginList from './patient_list_FILL0_wght400_GRAD0_opsz48.svg';
import iconSign from './history_edu_FILL0_wght400_GRAD0_opsz48.svg';
import iconCircleLeft from './arrow_circle_left_FILL0_wght400_GRAD0_opsz48.svg';
import iconCircleRight from './arrow_circle_right_FILL0_wght400_GRAD0_opsz48.svg';
import iconSearch from './search_FILL0_wght400_GRAD0_opsz48.svg';
import iconDetail from './more_horiz_FILL0_wght400_GRAD0_opsz48.svg';
import iconKeyCard from './playing_cards_FILL0_wght400_GRAD0_opsz24.svg';
import iconQr from './qr_code_2_FILL0_wght400_GRAD0_opsz24.svg';
import iconLock from './lock_FILL0_wght400_GRAD0_opsz48.svg';
import iconCard from './book_FILL0_wght400_GRAD0_opsz48.svg';
import iconHome from './home_FILL0_wght400_GRAD0_opsz48.svg';
import iconSend from './send_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconFilter from './filter_alt_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconCopy from './content_copy_FILL0_wght400_GRAD0_opsz48.svg';
import iconEdit from './edit_FILL0_wght400_GRAD0_opsz48.svg';
import iconCheck from './check_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconSwords from './swords_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconMenu from './menu_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconExpand from './expand_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCollapse from './collapse_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconAddress from './slab_serif_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCotto from './cotto.png';
import imageNotAMonacard from './notAMonacard.png';
import termsAndConditionsUserHtml from './tac_user.static.html';
import privacyPolicyHtml from './privacypolicy.static.link';
import sctHtml from './sct.static.html';

const words = require('./words.json');
const marginOfSessionTime = 60000;
const bip32 = BIP32Factory(ecc);
const ECPair = ECPairFactory(ecc);
const networkMona = coininfo('MONA').toBitcoinJS();
// const test = {
//   face: 0,
// };

let GlobalState;
let wallet;


// APP
function App() {
  const urlParams = (new URL(document.location)).searchParams;

  // 検索期間のデフォルト値
  const now = new Date();
  let yearLastMonth;
  let monthLastMonth;
  let dayEndLastMonth;

  if (now.getMonth() === 0) {
    yearLastMonth = now.getFullYear() - 1;
    monthLastMonth = 12;
  }
  else {
    yearLastMonth = now.getFullYear();
    monthLastMonth = now.getMonth();
  }

  if (now.getMonth() === 4 || now.getMonth() === 6 || now.getMonth() === 9 || now.getMonth() === 11) {
    dayEndLastMonth = 30;
  }
  else if (now.getMonth() === 2) {
    if (now.getFullYear() % 4 === 0) {
      dayEndLastMonth = 29;
    }
    else {
      dayEndLastMonth = 28;
    }
  }
  else {
    dayEndLastMonth = 31;
  }


  const initialState = {
    language: urlParams.get('language') === null ? 'japanese' : urlParams.get('language'),
    device: {
      hasCamera: undefined,
    },
    config: {
      clientParameters: {
        versionOfTheTermsAndConditions: 'dummy',
        versionOfPrivacyPolicy: 'dummy',
      },
    },
    configMP: {
      clientParameters: {
      },
    },
    login: {
      addressMain: '',
      displayAddressSection: false,
    },
    configure: {
      addressMain: '',
      userName: '',
      // images: {
      //   main: null,
      // },
      // imagesNew: {
      //   main: null,
      //   mainUrl: null,
      // },
      readTheTermsAndConditions: false,
      readPrivacyPolicy: false,
      acceptedVersionOfTheTermsAndConditions: null,
      acceptedVersionOfPrivacyPolicy: null,
      // profileText: '',
      displayAddressSection: false,
      status: null,
    },
    keyPairs: {},
    sendMona: {
      addressFrom: null,
      addressTo: null,
      amount: { face: '1', value: 1 },
    },
    sendXmp: {
      addressFrom: null,
      addressTo: null,
      amount: { face: '1', value: 1 },
    },
    sendToken: {
      addressFrom: null,
      addressTo: null,
      asset: null,
      amount: { face: '1', value: 1 },
    },
    cartMonaparty: {
    },
    cartMona: {
    },
    actionHistory: [],
    tokenFilter: {
      conditions: [
        {
          assetCommon: [],
          addressOwners: [],
          lockStatus: [],
          monacardName: [],
          monacardIssuerNames: [],
          monacardDescription: [],
          monacardTags: [],
          monadom: [],
        },
      ],
      addressOwner: null,
      lockStatus: null,
      monacardName: null,
      monacardIssuerName: null,
      monacardDescription: null,
      monacardTag: null,
      monadom: null,
      conditionIndex: 0,
    },
    active: {
      addressMain: undefined,
    },
    balance: {
      mona: {},
      monaparty: {}
    },
    user: {},
    usersMonacotto: {},
    session: {},
    assetInfo: {},
    monacard: {},
    registeredCard: {},
    searchDateRange: {
      from: {
        epoch: undefined, // new Date(yearLastMonth, monthLastMonth - 1, 1, 0, 0, 0, 0).getTime(),
        year: undefined, // yearLastMonth,
        month: undefined, // monthLastMonth,
        day: undefined, // 1, // now.getDate,
        hours: 0, // now.getHours,
        minutes: 0, // now.getMinutes,
        seconds: 0, // now.getSeconds,
        milliseconds: 0, // now.getMilliseconds,
      },
      to: {
        epoch: undefined, // new Date(yearLastMonth, monthLastMonth - 1, dayEndLastMonth, 23, 59, 59, 999).getTime(),
        year: undefined, // yearLastMonth,
        month: undefined, // monthLastMonth,
        day: undefined, // dayEndLastMonth,
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      },
    },
    notification: {
      body: [ '' ],
      index: 0,
      fixed: false,
      inAnimation: false,
    },
    popup: [
      { type: null, body: null },
      { type: null, body: null },
      { type: null, body: null },
      { type: null, body: null },
    ],
    accessing: false,
    pointSum: {},
    registeredItemAll: {},
    addressCheck: 'strict',
    creatorInformation: {
      creater: '',
      createrText: '',
      monacottoAddressMain: '',
      createrLink: [
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
      ],
    },
    animation: {
      youWinMona: 'visible',
    },
    displaySecret: {
      mnemonic: '',
      mnemonicHidden: '',
      wif: '',
      address: '',
      swapMnemonics: '',
      mnemonicToWif: '',
      wifFromMnemonic: '',
    },
    walletType: urlParams.get('wallettype') === null ? walletsAvailable()[0] : urlParams.get('wallettype'),
    qrCodeScanner: {
      qrCode: undefined,
    },
    displayName: 'asset_longname',
    // test: test,
    issuance: {
      issueMode: 'issueMonacard',
      assetType: 'tokenNameLeftToTheSystem',
      imagesNew: {
        monacard: null,
        monacardUrl: null,
      },
      addressIssuer: '',
      parent: '',
      subAsset: '',
      assetCommon: '',
      amount: { face: '', value: '' },
      monacardName: '',
      monacardIssuerName: '',
      monacardDescription: '',
      monacardTag: '',
      // lock: false,
      divisible: false,
      listed: true,
      vendable: true,
      reassignable: true,
    },
    editAssetInfo: {
      editMode: 'editMonacard',
      imagesNew: {
        monacard: null,
        monacardUrl: null,
      },
      assetIssuer: '',
      assetCommon: '',
      supply: null,
      amount: { face: '', value: '' },
      monacardName: '',
      monacardIssuerName: '',
      monacardDescription: '',
      monacardTag: '',
      cid: '',
      lock: null,
      divisible: null,
      listed: null,
      vendable: null,
      reassignable: null,
    },
    ownedAssets: {},
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  GlobalState = React.createContext([state, dispatch, navigate, cookies, setCookie, removeCookie]);

  useEffect( () => {
    const func = async () => {
      let configKeys;
      let config;

      // // AppConnect対応
      // window.monapaletteConnect.getAddress().then(() => window.mpurse = window.monapaletteConnect).catch(() => {});

      // カメラある？

      hasCamera().then( (hasCamera) => {
        devLog('has camera; ', hasCamera);

        dispatch({ type: 'setStateMultiLayers', keys: ['device', 'hasCamera'], value: hasCamera });

        if (hasCamera && (state.walletType === undefined || state.walletType === null)) {
          dispatch({ type: 'setState', key: 'walletType', value: 'qr' });
        }
      });

      // 各種設定値取得
      // devLog('mainaddress', urlParams.get('mainaddress'));

      // -- cookie情報取得
      // if (cookies.addressCheck !== undefined) {
      //   dispatch({ type: 'setState', key: 'addressCheck', value: cookies.addressCheck });
      // }

      dispatch({ type: 'setState', key: 'accessing', value: true });

      configKeys = ['clientParameters'];
      config = await getConfig(dispatch, 'monashell', configKeys , 'config');

      // configKeys = ['clientParameters'];
      // config = await getConfig(dispatch, 'monatokaPoint', configKeys , 'configMP');

      // 初期通知表示
      // dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'body', 0], value: config.body.initialNotification.value });

      // // -- 登録カード情報取得

      getRegisteredCard(state, dispatch);

      dispatch({ type: 'setState', key: 'accessing', value: false });

      devLog('domain', document.domain);
      console.log('domain', document.domain);
    };

    func();
  }, []);

  let bottomUp;

  if (wallet === 'MonaPallet') {
    bottomUp =
    <div className="appConnectBottomUp">
    </div>;
  }

  return (
    <div className="App">
      <Routes>
        {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<LP />} /> */}
        {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<LP />} /> */}
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/setting' : '/setting'} element={<Setting />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/history' : '/history'} element={<ActionHistory />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/issuance' : '/issuance'} element={<Issuance />} />
      </Routes>
      { bottomUp }
      <NotificationFadingOut />
      <RollingPearl />
    </div>
  );
}

// TOP
function Top() {
  const [state, dispatch, navigate] = useContext(GlobalState);


  return (
    <div className=''>
      {/* PC */}
      <div className="visibleMiddleOrMore mainKOM">
        <div className="flexColumn alignItemsCenter relative">
          <Header />
          <BalanceMonaparty />
          {/* development */}
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
        {/* footer */}
        <Footer />
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess mainKOM ">
        <div className="flexColumn alignItemsCenter relative widthMax">
          <Header />
          <BalanceMonaparty />
        </div>
        {/* menu button */}
        <MenuButton />
        {/* footer */}
        <Footer />
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// HEADER
function Header() {
  const [state, dispatch, navigate] = useContext(GlobalState);


  return (
    <div className='widthMax'>
      {/* PC */}
      <div className='visibleMiddleOrMore flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax'>
        <div>
          <img className='width14' src={logoMonashell} alt='' />
          {/*
            <button onClick={ () => { test.face++; } } >
              { test.face }
            </button>
            <button onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['test', 'face'], value: state.test.face + 1 }) }} >
              { state.test.face }
            </button>
          */}
        </div>
        <div className='flexRow'>
          <div className='marginSide1' >
            <BalanceMona />
          </div>
          <div className='marginSide1' >
            <BalanceXmp />
          </div>
        </div>
        <MenuSection />
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <img className='width14' src={logoMonashell} alt='' />
        <div className='marginSide1' >
          <BalanceMona />
        </div>
        <div className='marginSide1' >
          <BalanceXmp />
        </div>
        <MenuSection />
      </div>
    </div>
  );
}

// FOOTER
function Footer() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  return (
    <div className='footer'>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          window.open('https://' + process.env.REACT_APP_ABOUT_US);
        }}
      >
        {words.aboutUs[state.language]}
      </button>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          const popup = { type: 'generalItems', body: <div dangerouslySetInnerHTML={ { __html: sctHtml } } /> };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
        }}
      >
        {words.notationBasedOnTheActOnSpecifiedCommercialTransactions[state.language]}
      </button>
      {/*
        <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
          onClick={ () => {
            window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
          }}
        >
          {words.guideline[state.language]}
        </button>
      */}
    </div>
  );
}

// MENU BUTTON
function MenuButton() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  return (
    <button className='button1 buttonTopRight'
      onClick={ () => {
        const popup = { type: 'menuPopup' };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
      }}
    >
      <img className='size2x2' src={iconMenu} alt='' />
    </button>
  );
}

// MENU SECTION
function MenuSection(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const popupLayerNext = popupLayer !== undefined ? popupLayer + 1 : 0;

  let cartMonaBackground = '';

  if (Object.keys(state.cartMona).length > 0) {
    cartMonaBackground = ' backgroundColorPinkPale';
  }

  let cartMonapartyBackground = '';

  if (Object.keys(state.cartMonaparty).length > 0) {
    cartMonapartyBackground = ' backgroundColorPinkPale';
  }


  return (
    <div className='flexRow justifyContentCenter' >
      <button className={'button2 flexColumn alignItemsCenter' + cartMonaBackground}
        onClick={ () => {
          const popup = { type: 'cartMonaPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }}
      >
        <div className='' >
          { words.cart[state.language] }
        </div>
        <div className='' >
          { words.monaInBrackets[state.language] }
        </div>
      </button>
      <button className={'button2 flexColumn alignItemsCenter' + cartMonapartyBackground}
        onClick={ () => {
          const popup = { type: 'cartMonapartyPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }}
      >
        <div className='' >
          { words.cart[state.language] }
        </div>
        <div className='' >
          { words.tokenInBrackets[state.language] }
        </div>
      </button>
      <TokenFilterSection popupLayerNext={popupLayerNext} />
      <button className='button2'
        onClick={ () => {
          navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/issuance' : '/issuance');

          if (popupLayer !== undefined) {
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
          }
        }}
      >
        { words.issueMonacard[state.language] }
      </button>
      <button className='button2'
        onClick={ () => {
          navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/history' : '/history');
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }}
      >
        { words.history[state.language] }
      </button>
      <button className='button2'
        onClick={ () => {
          handleClickSetting(state, dispatch, navigate);
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }}
      >
        { words.setting[state.language] }
      </button>
      <button className='button2'
        onClick={ () => {
          handleClickReload(state, dispatch);
        }}
      >
        { words.reload[state.language] }
      </button>
      <button className='button2'
        onClick={ () => {
          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }}
      >
        { words.userRegistration[state.language] }
      </button>
      <button className='button2'
        onClick={ () => {
          const popup = { type: 'loginPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }}
      >
        { words.login[state.language] }
      </button>
    </div>
  );
}


// ACCEPTANCE OF TEMS AND CONDITIONS
function AcceptanceOfTermsAndConditions(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  if (state.configure.acceptedVersionOfTheTermsAndConditions !== state.config.clientParameters.versionOfTheTermsAndConditions ||
      state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy) {
    return (
      <button className='flexColumn justifyContentCenter alignItemsCenter borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 colorRed cursor borderRadius2 riseOut2 marginTopBottom0p5' tabindex='0'
        onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }
      }>
        <div>
          {words.iAcceptTheTermsAndConditionsAndPrivacypolicy[state.language]}
        </div>
      </button>
    );
  }
  else {
    return (
      <button className='borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 borderRadius2 riseOut2 marginTopBottom0p5'
        onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }
      }>
          {words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language]}
      </button>
    );
  }
}


// TERMS AND CONDITIONS POPUP
function TermsAndConditionsPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  const termsAndConditions = {
    user: {
      face: words.termsAndConditions[state.language],
      html: termsAndConditionsUserHtml,
    },
    privacyPolicy: {
      face: words.privacyPolicy[state.language],
      html: privacyPolicyHtml,
    },
  };

  const buttons = ['user', 'privacyPolicy'].filter( target1 => target1 !== props.target ).map( target2 =>
    <button className='backgroundColorMonacottoPale borderMonacotto margin0p5'
      onClick={ () => {
        const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target={target2} /> };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
      }}
    >
      { termsAndConditions[target2].face }
    </button>
  );

  return (
    <div>
      <div>
        { buttons }
      </div>
      <AcceptanceOfTermsAndConditions popupLayer={popupLayer} />
      <div dangerouslySetInnerHTML={ { __html: termsAndConditions[props.target].html } } />
      <AcceptanceOfTermsAndConditions popupLayer={popupLayer} />
    </div>
  );
}

// SCT POPUP
function SCTPopup(props) {
  return (
    <div dangerouslySetInnerHTML={ { __html: sctHtml } } />
  );
}

// USER REGISTRATION
function UserRegistration(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const popupLayerNext = popupLayer !== undefined ? popupLayer + 1 : 0;
  let registrationSection;

  // アドレスセクション・スイッチアイコン

  let addressSection;
  let switchIcon;

  if (state.configure.displayAddressSection) {
    addressSection = <AddressSection keys={['configure', 'addressMain']} />;
    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  // ユーザー名セクション

  const userNameSection =
  <div className='flexRow justifyContentFlexStart alignItemsCenter '>
    <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip='right' />
    <button className={'button1'} tabindex='0'
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'displayAddressSection'], value: state.configure.displayAddressSection ? false : true });
      }}
    >
      <img className='size2x2' src={switchIcon} alt='' />
    </button>
  </div>


  // TAC文言

  let tacWord;

  if (state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
      state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
    tacWord = words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language];
  }
  else {
    tacWord = words.termsAndConditionsAndPrivacypolicy[state.language];
  }

  // 利用規約ボタン

  const tacButton =
  <button
    className={
      'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' +
      (state.configure.readTheTermsAndConditions ? '' : 'colorRed')
    }
    tabindex='0'
    onClick={ () => {
      const popup = {
        type: 'generalItems',
        body: <TermsAndConditionsPopup target='user' popupLayer={ popupLayerNext } />,
      };

      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
    }}
  >
    { tacWord }
  </button>;

  // 利用規約同意チェックボックス

  const tacCheckbox =
  <button
    className={
      'width1 height1 borderMonacottoNoRadius marginSide1 ' +
      (
        (
          state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
          state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy
        ) ? 'backgroundColorPinkPale' : 'backgroundColorTransparent'
      )
    }
    onClick={ () => {
      if (!state.configure.acceptedVersionOfTheTermsAndConditions || !state.configure.acceptedVersionOfPrivacyPolicy) {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: null });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: null });
      }
    }}  
  >
  </button>;

  // 登録ボタン

  const registrationButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
    onClick={ async () => {
      const result = await handleClickSetup(state, dispatch);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickSetup(state, dispatch, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickSetup(state, dispatch, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
      }
    }}
  >
    <div>
      {words.signByMainAddressToRegister[state.language]}
    </div>
    { SignIcon() }
  </button>;

  // 登録セクション

  registrationSection =
  <div>
    <div className="visibleMiddleOrMore flexColumn alignItemsCenter borderKOM marginTop1 padding1">
      <div className=''>
        { words.userRegistration[state.language] }
      </div>
      <SelectWalletBox />
      <div className='marginTop0p5 '>
        { addressSection }
      </div>
      <div className='marginTop1 '>
        { userNameSection }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom0p5 '>
        { tacCheckbox }
        { tacButton }
      </div>
      { registrationButton }
      {/* generate mnemonic */}
      { 
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
          >
            generate mnemonic
          </button>
        : null
      }
    </div>
    <div className="visibleSmallOrLess flexColumn alignItemsCenter borderKOM marginTop1 padding1">
      <div className=''>
        { words.userRegistration[state.language] }
      </div>
      <SelectWalletBox />
      <div className='marginTop0p5 '>
        { addressSection }
      </div>
      <div className='marginTop1 '>
        { userNameSection }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom0p5 '>
        { tacCheckbox }
        { tacButton }
      </div>
      { registrationButton }
      {/* generate mnemonic */}
      { 
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
          >
            generate mnemonic
          </button>
        : null
      }
    </div>
  </div>;


  // let userRegistrationBox;

  // if (state.walletType === 'mpurse') {
  //   userRegistrationBox =
  //   <div>
  //     <div className="visibleMiddleOrMore flexColumn alignItemsFlexStart borderKOM marginTop1 padding1">
  //       <div className='marginTop1 '>
  //         { words.userRegistration[state.language] }
  //       </div>
  //       <SelectWalletBox />
  //       {/* address main */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='addressMain' keys={['configure', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
  //         <button className='button1' tabindex='0' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressMain']) }>
  //           <img className='size2x2' src={iconMpurse} alt='' />
  //         </button>
  //       </div>
  //       {/* user name */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //         {/* <button className='dummyPadButton1' disabled={true} /> */}
  //       </div>
  //       {/* terms and conditions */}
  //       <div className='flexColumn alignItemsFlexStart '>
  //         <button className={
  //                   'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')
  //                 }
  //           tabindex='0'
  //           onClick={ () => {
  //             const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //             dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //           }}
  //         >
  //           {tacWord}
  //         </button>
  //         {/* <TermsAndConditions /> */}
  //       </div>
  //       {/* register button */}
  //       <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickSetup(state, dispatch) }>
  //         <div>
  //           {words.signByMainAddressToRegister[state.language]}
  //         </div>
  //         <img className='size2x2' src={iconSign} alt='' />
  //       </button>
  //       {/* generate mnemonic */}
  //       { 
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
  //           >
  //             generate mnemonic
  //           </button>
  //         : null
  //       }
  //     </div>
  //     <div className="visibleSmallOrLess flexColumn borderKOM marginTop1 padding1">
  //       <div className='marginTop1 '>
  //         { words.userRegistration[state.language] }
  //       </div>
  //       <SelectWalletBox />
  //       {/* address main */}
  //       <div className='flexColumn alignItemsFlexStart marginTop0p5'>
  //         <TextLine3 fieldName='addressMain' keys={['configure', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
  //         <div className='flexRow justifyContentFlexStart alignItemsCenter '>
  //           <button className='button1' tabindex='0' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressMain']) }>
  //             <img className='size2x2' src={iconMpurse} alt='' />
  //           </button>
  //         </div>
  //       </div>
  //       {/* user name */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //         {/* <button className='dummyPadButton1' disabled={true} /> */}
  //       </div>
  //       {/* terms and conditions */}
  //       <div className='flexColumn alignItemsFlexStart '>
  //         <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //           tabindex='0'
  //           onClick={ () => {
  //             const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //             dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //           }}
  //         >
  //           {tacWord}
  //         </button>
  //         {/* <TermsAndConditions /> */}
  //       </div>
  //       {/* register button */}
  //       <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickSetup(state, dispatch) }>
  //         <div>
  //           {words.signByMainAddressToRegister[state.language]}
  //         </div>
  //         <img className='size2x2' src={iconSign} alt='' />
  //       </button>
  //       {/* generate mnemonic */}
  //       { 
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
  //           >
  //             generate mnemonic
  //           </button>
  //         : null
  //       }
  //       { 
  //         /*
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => {
  //               const callback = (keyPairs) => handleClickSetup(state, dispatch, keyPairs);
  //               handleClickScanQr(state, dispatch, callback);
  //             }}
  //           >
  //             QR
  //           </button>
  //         : null
  //         */
  //       }
  //     </div>
  //   </div>;
  // }
  // else if (state.walletType === 'nfc') {
  //   userRegistrationBox =
  //   <div className="flexColumn borderKOM marginTop1 padding1">
  //     <div className='marginTop1 '>
  //       { words.userRegistration[state.language] }
  //     </div>
  //     <SelectWalletBox />
  //     {/* user name */}
  //     <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //       <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //       {/* <button className='dummyPadButton1' disabled={true} /> */}
  //     </div>
  //     {/* terms and conditions */}
  //     <div className='flexColumn alignItemsFlexStart '>
  //       <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //         tabindex='0'
  //         onClick={ () => {
  //           const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //           dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //         }}
  //       >
  //         {tacWord}
  //       </button>
  //       {/* <TermsAndConditions /> */}
  //     </div>
  //     {/* register with NFC */}
  //     <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //       onClick={ () => {
  //         const callback = (keyPairs) => {
  //           handleClickSetup(state, dispatch, keyPairs);
  //           keepKeyPairsInMemory(state, dispatch, keyPairs);
  //         };

  //         handleClickNfc(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         {words.signByMainAddressToRegister[state.language]}
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconKeyCard} alt='' />
  //       </div>
  //     </button>
  //   </div>;
  // }
  // else { // qr
  //   userRegistrationBox =
  //   <div className="flexColumn borderKOM marginTop1 padding1">
  //     <div className='marginTop1 '>
  //       { words.userRegistration[state.language] }
  //     </div>
  //     <SelectWalletBox />
  //     {/* user name */}
  //     <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //       <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //       {/* <button className='dummyPadButton1' disabled={true} /> */}
  //     </div>
  //     {/* terms and conditions */}
  //     <div className='flexColumn alignItemsFlexStart '>
  //       <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //         tabindex='0'
  //         onClick={ () => {
  //           const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //           dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //         }}
  //       >
  //         {tacWord}
  //       </button>
  //       {/* <TermsAndConditions /> */}
  //     </div>
  //     {/* register with QR */}
  //     <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //       onClick={ () => {
  //         const callback = (keyPairs) => {
  //           handleClickSetup(state, dispatch, keyPairs);
  //           keepKeyPairsInMemory(state, dispatch, keyPairs);
  //         };

  //         handleClickScanQr(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         {words.signByMainAddressToRegister[state.language]}
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconQr} alt='' />
  //       </div>
  //     </button>
  //   </div>;
  // }


  return registrationSection;
}

// ADDRESS SECTION
function AddressSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const keys = props.keys;
  let addressSection;

  addressSection =
  <div className='flexRow justifyContentCenter alignItemsCenter '>
    <TextLine3 fieldName='addressSectionAddressMain' keys={keys} face={words.addressMain[state.language]} tooltip='right' />
    <button className='button1'
      onClick={ () => handleClickMpurse(state, dispatch, keys) }
    >
      <img className='size2x2' src={iconAddress} alt='' />
    </button>
    {/*
      <button className='button1'
        onClick={ () =>
          handleClickAddressHistory(state, dispatch, cookies, ['login', 'addressMain'], popupLayer)
        }
      >
        <img className='size2x2' src={iconLoginList} alt='' />
      </button>
    */}
  </div>;

  return addressSection;
}

// QR CODE SCANNER
function QrCodeScanner(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  // const [resultData, setResultData] = useState(undefined);
  const popupLayer = props.popupLayer;
  const callback = props.callback;
  const callbackType = props.callbackType;
  const options = props.options;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  devLog('QrCodeScanner rendered.');

  useEffect( () => {
    devLog('useEffect triggered.');
    const constraints = {
      video: {
        facingMode: 'environment',
        width: { ideal: 300 },
        height: { ideal: 300 },
      },
    };

    // デバイスのカメラにアクセスする
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      // デバイスのカメラにアクセスすることに成功したら、video要素にストリームをセットする
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        scanQrCode();
      }
    })
    .catch((err) => console.error('Error accessing media devices:', err));

    const currentVideoRef = videoRef.current

    // コンポーネントがアンマウントされたら、カメラのストリームを停止する
    return () => {
      devLog('cleanup');
      if (currentVideoRef && currentVideoRef.srcObject) {
        const stream = currentVideoRef.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const scanQrCode = () => {
    devLog('scanQrCode');
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      if (ctx) {
        // カメラの映像をcanvasに描画する
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // QRコードをスキャンする
        const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height);

        if (qrCodeData) {
          // スキャンされた内容を確認する
          // if (false) {
          //   setTimeout(scanQrCode, 100); // スキャンの頻度を制限
          //   return;
          // }

          // setResultData(qrCodeData.data)
          dispatch({ type: 'setStateMultiLayers', keys: ['qrCodeScanner', 'qrCode'], value: qrCodeData.data });
          dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'addressMain'], value: qrCodeData.data }); 
          dispatch({ type: 'setNotification', key: 'notification', value: words.scaned[state.language] });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });

          if (callbackType === 'decryptMnemonic') {
            doSomethingWithEncryptedMnemonics(state, dispatch, [qrCodeData.data], callback, options);
          }
          else { // simpelCallback
            callback(qrCodeData.data);
          }

          return;
        }

        setTimeout(scanQrCode, 100);
      }
    }
  };

  // let scanner;

  // if (resultData === undefined) {
  //   scanner =
  //   <div className=''>
  //     <div className=''>
  //       <video ref={videoRef} autoPlay playsInline className='' />
  //       <canvas ref={canvasRef} width='300' height='300' className='' />
  //     </div>
  //   </div>;
  // }

  // let button;
  // 
  // if (resultData !== undefined) {
  //   button =
  //   <div className=''>
  //     <button>push</button>
  //   </div>
  // }


  return (
    <div className='flexColumn justifyContentCenter alignItemsCenter relative border'>
      <video ref={videoRef} autoPlay playsInline className='absoluteZero zM10' />
      <canvas ref={canvasRef} width='300' height='300' className='' />
    </div>
  );
}


// SETTING
function Setting() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <div className='flexRow ' >
            <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
              { words.back[state.language] }
            </button>
          </div>
        </div>
        <UserRegistration />
        <SwitchDisplayName />
        <DisplayMnemonic />
        <DisplayWif />
        <DisplayAddress />
        <SwapMnemonics />
        <GetThePrivateKeyFromTheMnemonic />
        <WriteMnemonicOnNfc />
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <UserRegistration />
        <DisplayMnemonic />
        <DisplayWif />
        <DisplayAddress />
        <SwapMnemonics />
        <GetThePrivateKeyFromTheMnemonic />
        <WriteMnemonicOnNfc />
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// SWITCH DISPLAY NAME
function SwitchDisplayName() {
  const [state, dispatch] = useContext(GlobalState);


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div>
        { words.switchDisplayNamesInTheCardList[state.language] }
      </div>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <button className={'box3 focusEffect01 riseOut2 ' + (state.displayName === 'cardName' ? 'colorRed borderSelected' : 'borderNone')}
          tabindex='0'
          onClick={ () => {
            dispatch({type: 'setState', key: 'displayName', value: 'cardName'});
          }}
        >
          <div>
            {words.monacardName[state.language]}
          </div>
        </button>
        <button className={'box3 focusEffect01 riseOut2 ' + (state.displayName === 'asset_longname' ? 'colorRed borderSelected' : 'borderNone')}
          tabindex='0'
          onClick={ () => {
            dispatch({type: 'setState', key: 'displayName', value: 'asset_longname'});
          }}
        >
          <div>
            {words.asset_longname[state.language]}
          </div>
        </button>
        <button className={'box3 focusEffect01 riseOut2 ' + (state.displayName === 'asset' ? 'colorRed borderSelected' : 'borderNone')}
          tabindex='0'
          onClick={ () => {
            dispatch({type: 'setState', key: 'displayName', value: 'asset'});
          }}
        >
          <div>
            {words.asset[state.language]}
          </div>
        </button>
      </div>
    </div>
  );
}

// DISPLAY MNEMONIC
function DisplayMnemonic() {
  const [state, dispatch] = useContext(GlobalState);

  let buttons;

  const nfcButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics) => handleClickDisplayMnemonic(state, dispatch, mnemonics);
      handleClickNfc(state, dispatch, callback, { args: ['mnemonics'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconKeyCard} alt='' />
    </div>
  </button>;

  const qrButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics) => handleClickDisplayMnemonic(state, dispatch, mnemonics);
      handleClickScanQr(state, dispatch, callback, { args: ['mnemonics'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconQr} alt='' />
    </div>
  </button>;

  if ('NDEFReader' in window && state.device.hasCamera) {
    buttons =
    <div className=''>
      { nfcButton }
      { qrButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    buttons =
    <div className=''>
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    buttons =
    <div className=''>
      { qrButton }
    </div>;
  }
  else {
    return null;
  }


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.displayMnemonic[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => handleClickClearMnemonic(state, dispatch) }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <div className='widthMax colorRed marginTop1 '>
        { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
      </div>
      { buttons }
      <div className='flexColumn alignItemsFlexStart width90PC borderKOM marginTop1 padding0p5'>
        { state.displaySecret.mnemonic }
      </div>
    </div>
  );
}

// DISPLAY WIF
function DisplayWif() {
  const [state, dispatch] = useContext(GlobalState);

  let buttons;

  const nfcButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics, keyPairs) => handleClickDisplayWif(state, dispatch, mnemonics, keyPairs);
      handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconKeyCard} alt='' />
    </div>
  </button>;

  const qrButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics, keyPairs) => handleClickDisplayWif(state, dispatch, mnemonics, keyPairs);
      handleClickScanQr(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconQr} alt='' />
    </div>
  </button>;

  if ('NDEFReader' in window && state.device.hasCamera) {
    buttons =
    <div className=''>
      { nfcButton }
      { qrButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    buttons =
    <div className=''>
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    buttons =
    <div className=''>
      { qrButton }
    </div>;
  }
  else {
    return null;
  }


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.displayWif[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => handleClickClearWif(state, dispatch) }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <div className='widthMax colorRed marginTop1 '>
        { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
      </div>
      { buttons }
      <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
        { state.displaySecret.wif }
      </div>
    </div>
  );
}

// DISPLAY ADDRESS
function DisplayAddress() {
  const [state, dispatch] = useContext(GlobalState);

  let buttons;

  const nfcButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics, keyPairs) => handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs);
      handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconKeyCard} alt='' />
    </div>
  </button>;

  const qrButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
    onClick={ async () => {
      const callback = (mnemonics, keyPairs) => handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs);
      handleClickScanQr(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
    }}
  >
    <div>
      { words.display[state.language] }
    </div>
    <div>
      <img className='size2x2' src={iconQr} alt='' />
    </div>
  </button>;

  if ('NDEFReader' in window && state.device.hasCamera) {
    buttons =
    <div className=''>
      { nfcButton }
      { qrButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    buttons =
    <div className=''>
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    buttons =
    <div className=''>
      { qrButton }
    </div>;
  }
  else {
    return null;
  }


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.displayAddress[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => handleClickClearAddress(state, dispatch) }
        >
          {words.clear[state.language]}
        </button>
      </div>
      { buttons }
      <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
        { state.displaySecret.address }
      </div>
    </div>
  );
}

// SWAP MNEMONICS
function SwapMnemonics() {
  const [state, dispatch] = useContext(GlobalState);

  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    return null;
  }

  if (!('NDEFReader' in window)) {
    return null;
  }


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.swapMnemonics[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => handleClickClearAddress(state, dispatch) }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
        onClick={ async () => {
          handleClickSwapMnemonics(state, dispatch);
        }}
      >
        <div>
          { words.display[state.language] }
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} alt='' />
        </div>
      </button>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
        onClick={ async () => {
          localStorage.removeItem('swapStatus');
          localStorage.removeItem('oldAddress');
          localStorage.removeItem('oldKeyPair');
          localStorage.removeItem('oldMnemonic');
          localStorage.removeItem('newAddress');
          localStorage.removeItem('newMnemonic');
          localStorage.removeItem('sweepAssetsTxid');
          localStorage.removeItem('sendMonaTxid');
          dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' }); // 暫定
        }}
      >
        <div>
          { words.display[state.language] }
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} alt='' />
        </div>
      </button>
      <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
        { state.displaySecret.swapMnemonics }
      </div>
    </div>
  );
}

// GET THE PRIVATE KEY FROM THE MNEMONIC
function GetThePrivateKeyFromTheMnemonic() {
  const [state, dispatch] = useContext(GlobalState);


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div className='flexColumn alignItemsFlexStart '>
          <div>
            { words.getThePrivateKeyFromTheMnemonic[state.language] }
          </div>
          <div>
            { words.restoringToAnotherWalletFromABackup[state.language] }
          </div>
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => handleClickClearMnemonicAndWif(state, dispatch) }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <div className='widthMax colorRed marginTop1 '>
        { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
      </div>
      <TextArea2 fieldName='settingWifFromMnemonic' keys={['displaySecret', 'mnemonicToWif']} face={words.mnemonic[state.language]}
        outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1'
      />
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
        onClick={ () => {
          handleClickDisplayWifFromMnemonicFilledIn(state, dispatch);
        }}
      >
        <div>
          { words.display[state.language] }
        </div>
      </button>
      <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
        { state.displaySecret.wifFromMnemonic }
      </div>
    </div>
  );
}

// WRITE MNEMONIC ON NFC
function WriteMnemonicOnNfc() {
  const [state, dispatch] = useContext(GlobalState);

  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    return null;
  }

  if (!('NDEFReader' in window)) {
    return null;
  }


  return (
    <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
        onClick={ () => {
          writeMnemonicOnNfc(state, dispatch);
        }}
      >
        <div>
          { words.display[state.language] }
        </div>
      </button>
    </div>
  );
}

// SESSION TITLE
function SessionTitle() {
  const [state] = useContext(GlobalState);

  return (
    <div className='widthMax'>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
    </div>
  );
}

// SESSION RECORD
function SessionRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { record.address }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { record.address }
        </div>
      </div>
    </div>
  );
}

// BALANCE MONA
function BalanceMona(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  let addressMain;
  let balanceMona;

  if (state.active.addressMain !== undefined) {
    addressMain = state.active.addressMain;

    if (state.balance.mona[addressMain] !== undefined) {
      balanceMona = new decimal(state.balance.mona[addressMain]).div(100000000).toNumber();
    }
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn justifyContentCenter alignItemsCenter monaBox marginTop1">
        <div className="marginTopBottom1">
          MONA
        </div>
        <div className="marginTopBottom1">
          { balanceMona }
        </div>
        <button className='button1 marginTopBottom1'
          onClick={ () => {
            const popup = { type: 'sendMonaPopoup' };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
          }}
        >
          {/* words.send[state.language] */}
          <img className='size2x2' src={iconSend} alt='' />
        </button>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn justifyContentCenter alignItemsCenter monaBox marginTop1 ">
        <div className="marginTopBottom1">
          MONA
        </div>
        <div className="marginTopBottom1">
          { balanceMona }
        </div>
        <button className='button1 marginTopBottom1'
          onClick={ () => {
            const popup = { type: 'sendMonaPopoup' };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
          }}
        >
          {/* words.send[state.language] */}
          <img className='size2x2' src={iconSend} alt='' />
        </button>
      </div>
    </div>
  );
}

// BALANCE XMP
function BalanceXmp(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  let addressMain;
  let balanceXmp = 0;

  if (state.active.addressMain !== undefined) {
    addressMain = state.active.addressMain;

    if (state.balance.monaparty[addressMain]?.XMP !== undefined) {
      balanceXmp = new decimal(state.balance.monaparty[addressMain].XMP.quantity).div(100000000).toNumber();
    }
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn justifyContentCenter alignItemsCenter monaBox marginTop1">
        <div className="marginTopBottom1">
          XMP
        </div>
        <div className="marginTopBottom1">
          { balanceXmp }
        </div>
        <button className='button1 marginTopBottom1'
          onClick={ () => {
            const popup = { type: 'sendXmpPopoup' };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
          }}
        >
          {/* words.send[state.language] */}
          <img className='size2x2' src={iconSend} alt='' />
        </button>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn justifyContentCenter alignItemsCenter monaBox marginTop1 ">
        <div className="marginTopBottom1">
          XMP
        </div>
        <div className="marginTopBottom1">
          { balanceXmp }
        </div>
        <button className='button1 marginTopBottom1'
          onClick={ () => {
            const popup = { type: 'sendXmpPopoup' };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
          }}
        >
          {/* words.send[state.language] */}
          <img className='size2x2' src={iconSend} alt='' />
        </button>
      </div>
    </div>
  );
}

// BALANCE MONAPARTY
function BalanceMonaparty(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  let addressMain;

  if (state.active.addressMain !== undefined) {
    addressMain = state.active.addressMain;
  }
  else {
    return null;
  }

  let balanceMonaparty;

  if (state.balance.monaparty[addressMain] !== undefined) {
    balanceMonaparty = state.balance.monaparty[state.active.addressMain];
  }
  else {
    return null;
  }

  // オウンドアセット

  let assetOwned =
   (state.ownedAssets[state.active.addressMain] || [])
  .filter( item => !Object.keys(state.config.clientParameters.asssetsForbidden).includes(item.asset) )
  .map( item => {
    if (balanceMonaparty[item.asset] !== undefined) {
      const itemWithBalance = balanceMonaparty[item.asset];
      itemWithBalance.owner = item.owner;
      return itemWithBalance;
    }
    else {
      const itemWithBalance = {
        asset: item.asset,
        // asset_longname: item.asset_longname,
        // owner: item.owner,
        quantity: 0,
      };

      return itemWithBalance;
    }
  });

  assetOwned = sortByTokenName(state, assetOwned);

  // バランスドアセット

  let assetsBalanced =
   Object.values(state.balance.monaparty[state.active.addressMain])
  .filter( item => item.quantity > 0 )
  .filter( item => item.asset !== 'XMP' )
  .filter( item => !Object.keys(state.config.clientParameters.asssetsForbidden).includes(item.asset) )
  .filter( item => !assetOwned.some( owned => owned.asset === item.asset ) );

  assetsBalanced = sortByTokenName(state, assetsBalanced);

  let items = assetOwned.concat(assetsBalanced);

  // // トークン名でソート

  // items = assetsBalanced.sort( (a, b) => {
  //   let aComp;
  //   let bComp;

  //   if (state.assetInfo[a.asset]?.asset_longname !== undefined && state.assetInfo[a.asset]?.asset_longname !== null) {
  //     aComp = state.assetInfo[a.asset].asset_longname; 
  //   }
  //   else {
  //     aComp = a.asset; 
  //   }

  //   if (state.assetInfo[b.asset]?.asset_longname !== undefined && state.assetInfo[b.asset]?.asset_longname !== null) {
  //     bComp = state.assetInfo[b.asset].asset_longname; 
  //   }
  //   else {
  //     bComp = b.asset; 
  //   }

  //   if (aComp < bComp) {
  //     return -1;
  //   }
  //   else if (aComp > bComp) {
  //     return 1;
  //   }
  //   else {
  //     return 0;
  //   }
  // });
  // // .sort( (a, b) => a.asset - b.asset )

  // 本命フィルタ
  items = state.tokenFilter.conditions.reduce( (acc, cur) =>
    acc.filter( item => {
      let attributesUnified = {};

      if (state.assetInfo[item.asset]?.asset_longname !== undefined && state.assetInfo[item.asset].asset_longname !== null) {
        attributesUnified.assetCommon = state.assetInfo[item.asset].asset_longname;
      }
      else {
        attributesUnified.assetCommon = item.asset;
      }

      if (state.assetInfo[item.asset] !== undefined) {
        attributesUnified.addressOwners = state.assetInfo[item.asset].owner;
        attributesUnified.lockStatus = state.assetInfo[item.asset].locked === true ? 'locked' : 'unlocked';
      }

      if (state.assetInfo[item.asset]?.description !== undefined && state.assetInfo[item.asset]?.description !== null && state.assetInfo[item.asset]?.description !== '' ) {
        try {
          const description = JSON.parse(state.assetInfo[item.asset].description);
          attributesUnified.monacardName = description.monacard.name;
          attributesUnified.monacardIssuerNames = description.monacard.owner;
          attributesUnified.monacardDescription = description.monacard.desc;
          attributesUnified.monacardTags = description.monacard.tag.split(',');
        }
        catch (err) {
          // モナカードのdescriptionじゃない。
        }
      }

      if (state.registeredCard[item.asset] !== undefined) {
        attributesUnified.monadom = 'registered';
      }
      else {
        attributesUnified.monadom = 'unregistered';
      }

      if (Object.keys(cur).some( key => cur[key].length >= 1 )) {
        return Object.keys(cur).some( key => {
          if (key === 'monacardName' || key === 'monacardDescription' || key === 'assetCommon') {
            return cur[key].some( condition => {
              return new RegExp(condition).test(attributesUnified[key]);
            });
          }
          else if (Array.isArray(attributesUnified[key])) {
            return attributesUnified[key].some( attribute => cur[key].includes(attribute) );
          }
          else { // scalar
            return cur[key].includes(attributesUnified[key]);
          }
        });
      }
      else {
        return true;
      }
    })
    , items
  );

  // コンポーネントにマップ

  items = items.map ( item => <Item item={item} style='small' quantity={true} arrows={false} />);

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className="flexRow justifyContentSpaceAround">
            { items }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className="flexRow justifyContentSpaceAround">
            { items }
        </div>
      </div>
    </div>
  );
}

// ITEM
function Item(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'itemDetail', body: props.item };
  const item = props.item;
  const quantity = props.quantity;
  const arrows = props.arrows;
  const popupLayerNext = props.popupLayer !== undefined ? props.popupLayer + 1 : 0;

  let {
    cardName,
    cardOwnerName,
    cardDescription,
    cid,
    cardImageUrl,
    cardImageUrlSP,
    isAMonacard,
  } = getMonacardInfoAfterAll(state, item, 'small');

  // スタイル

  let styleBox;
  let styleCard;
  let styleBoxSp;
  let styleCardSp;

  if (props.style === 'small') {
    styleBox = 'boxCardSmall';
    styleCard = 'cardImageSmall';
    styleBoxSp = 'boxCardSP padding0p5';
    styleCardSp = 'cardImageSmallSP';
  }
  else {
    styleBox = 'boxCard';
    styleCard = 'cardImage';
  }

  if (item.owner === state.active.addressMain) {
    styleBox += ' backgroundColorMonacottoAlmostWhite';
    styleBoxSp += ' backgroundColorMonacottoAlmostWhite';
  }

  // 画像

  let image;
  let imageSp;

  if (isAMonacard === false) {
    image = <img className={styleCard} src={imageNotAMonacard} alt='' />;
    imageSp = <img className={styleCardSp} src={imageNotAMonacard} alt='' />;
  }
  else {
    image = <img className={styleCard} src={cardImageUrl} alt='' />;
    imageSp = <img className={styleCardSp} src={cardImageUrlSP} alt='' />;
  }

  // SPにおいて、モナカードじゃなかった場合、モナカードではありません画像の上に、トークン名を表示する。

  let tokenNameOverImage;

  if (isAMonacard === false) {
    tokenNameOverImage =
    <div className={'absoluteZero z10 ' + styleCardSp + ' cardImageSmallSPHeight overflowHidden backgroundColorHalfWhite flexColumn justifyContentCenter'} >
      <div className='breakAll' >
        { cardName }
      </div>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <button className={styleBox} disabled={props.disabled ? true : false}
          onClick={ () => {
            const body = {
              item: item,
            };

            const popup = { type: 'itemDetail', body: body };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
          }}
        >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <div className='relative' >
              { image }
            </div>
            <div className='flexRow justifyContentFlexStart textCenter '>
              <CardName item={item} cardName={cardName} />
            </div>
            { quantity === true ? <Quantity item={item} /> : null }
          </div>
        </button>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <button className={styleBoxSp} disabled={props.disabled ? true : false}
          onClick={ () => {
            const body = {
              item: item,
            };

            const popup = { type: 'itemDetail', body: body };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
          }}
        >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <div className='relative' >
              { imageSp }
              { tokenNameOverImage }
            </div>
            { quantity === true ? <Quantity item={item} /> : null }
          </div>
        </button>
      </div>
    </div>
  );
}

// QUANTITY
function Quantity(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;
  let addressMain;

  if (state.active.addressMain !== undefined) {
    addressMain = state.active.addressMain;
  }
  else {
    return null;
  }


  return (
    <div className='flexRow justifyContentCenter '>
      <div className=''>
        { quantityForDisplay(state, item) }
      </div>
    </div>
  );
}

// CARD NAME
function CardName(props) {
  const [state] = useContext(GlobalState);
  const item = props.item;
  const cardName = props.cardName;

  let asset_longname;

  if (state.assetInfo[item.asset] !== undefined) {
    const assetInfo = state.assetInfo[item.asset];
    asset_longname = assetInfo.asset_longname;
  }

  let displayName;

  if (state.displayName === 'cardName') {
    if (cardName !== null) {
      displayName = cardName;
    }
    else if (asset_longname !== undefined && asset_longname !== null) {
      displayName = asset_longname;
    }
    else {
      displayName = item.asset;
    }
  }
  else if (state.displayName === 'asset_longname') {
    if (asset_longname !== undefined && asset_longname !== null) {
      displayName = asset_longname;
    }
    else {
      displayName = item.asset;
    }
  }
  else {
    displayName = item.asset;
  }


  return (
    <div className='flexColumn alignItemsCenter '>
      <div className='textCenter '>
        { displayName }
      </div>
    </div>
  );
}


// ITEM DETAIL
function ItemDetail(props) {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const item = state.popup[popupLayer].body.item;
  const assetCommon = (state.assetInfo[item.asset] === undefined || state.assetInfo[item.asset].asset_longname === null) ? item.asset : state.assetInfo[item.asset].asset_longname;
  const information = state.registeredCard[item.asset];

  let {
    cardName,
    cardOwnerName,
    cardDescription,
    cardTag,
    cid,
    cardImageUrl,
    cardImageUrlSP,
    isAMonacard,
  } = getMonacardInfoAfterAll(state, item, 'large');

  // useEffect( () => {
  //   const func = async () => {
  //     // アセット情報取得
  //     getAssetInfo(state, dispatch, [item.asset]);
  //   };

  //   func();
  // }, []);

  // 発行数

  let supply;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].divisible === true) {
      supply = new decimal(state.assetInfo[item.asset].supply).div(100000000).toNumber();
    }
    else {
      supply = state.assetInfo[item.asset].supply;
    }
  }
  else {
    supply = null;
  }

  // ロック画像

  let lockedImage;

  if (state.assetInfo[item.asset] !== undefined && state.assetInfo[item.asset].locked) {
    lockedImage = <img className='size1p7x1p7' src={iconLock} alt='' />;
  }
  else {
    lockedImage = null;
  }

  // ディスペンサー可否

  let vendable;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].vendable) {
      vendable =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5'>
          {words.vendable[state.language]}
        </div>;
    }
    else {
      vendable =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5'>
          {words.notVendable[state.language]}
        </div>;
    }
  }
  else {
    vendable = null;
  }

  // DEX可否

  let listed;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].listed) {
      listed =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5 marginLeft0p5'>
          {words.listed[state.language]}
        </div>;
    }
    else {
      listed =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5 marginLeft0p5'>
          {words.notListed[state.language]}
        </div>;
    }
  }
  else {
    listed = null;
  }

  /*
  let reassignable;

  if (state.assetInfo[item.asset] !== undefined && state.assetInfo[item.asset].reassignable) {
    reassignable =
      <div className='borderMonacotto'>
        reassignable
      </div>;
  }
  else {
    reassignable = null;
  }
  */

  // // let cardName;
  // // let cardDescription;

  // // if (state.assetInfo[item.asset] !== undefined) {
  // //   try {
  // //     const description = JSON.parse(state.assetInfo[item.asset].description);
  // //     cardName = description.monacard.name;
  // //     cardDescription = description.monacard.desc;
  // //   }
  // //   catch (err) {
  // //     if (state.monacard[item.asset] !== undefined) {
  // //       cardName = state.monacard[item.asset].card_name;
  // //       cardDescription = state.monacard[item.asset].add_description;
  // //     }
  // //     else {
  // //       cardName = null;
  // //       cardDescription = null;
  // //     }
  // //   }
  // // }
  // // else {
  // //   cardName = null;
  // //   cardDescription = null;
  // // }

  // 保有数

  let balance;
  let balanceNum = 0;

  for (const address of Object.keys(state.session)) {
    if (state.session[address].expirationOfSession >= Date.now()) {
      balanceNum += state.balance.monaparty[address]?.[item.asset]?.quantity || 0;
    }
  }

  if (Object.keys(state.session).length > 0) {
    balance = <div className='flexRow alignItemsFlexEnd marginRight1 '>
      <div className=''>
        {words.numberOfHoldings[state.language]}
      </div>
      <div className='font1p5 marginSide0p5'>
        { quantityForDisplay(state, item, balanceNum) }
      </div>
    </div>
  }
  else {
    balance = null;
  }

  // オーナー
  let ownerBlock;
  let ownerBlockSp;

  if (state.assetInfo[item.asset] !== undefined) {
    const owner = state.assetInfo[item.asset].owner;

    if (cardOwnerName !== undefined) {
      ownerBlock =
      <div className='flexColumn alignItemsFlexStart paddingLeft1'>
        <div >
          {cardOwnerName}
        </div >
        <div >
          {owner}
        </div >
      </div>;

      ownerBlockSp =
      <div className='flexColumn alignItemsFlexStart paddingLeft1'>
        <div >
          {cardOwnerName}
        </div >
        <div className='breakAll' >
          {owner}
        </div >
      </div>;
    }
    else {
      ownerBlock =
      <div className='flexColumn alignItemsFlexStart paddingLeft1'>
        <div >
          {owner}
        </div >
      </div>;

      ownerBlockSp =
      <div className='flexColumn alignItemsFlexStart paddingLeft1'>
        <div className='breakAll' >
          {owner}
        </div >
      </div>;
    }
  }
  else {
    ownerBlock = null;
    ownerBlockSp = null;
  }

  // 送信ボタン

  const sendButton =
  <button className='button1' tabindex='0'
    onClick={ () => {
      dispatch({ type: 'setStateMultiLayers', keys: ['sendToken', 'asset'], value: item.asset });
      // const body = <SendToken asset={item.asset} popupLayer={popupLayer + 1} />;
      const body = {
        asset: item.asset,
      };
      const popup = { type: 'sendTokenPopup', body: body };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
    }}
  >
    <img className='size2x2' src={iconSend} alt='' />
  </button>;

  // アセット情報編集ボタン

  let editAssetInfoButton;

  if (true) {
    editAssetInfoButton =
    <button className='button1' tabindex='0'
      onClick={ () => {
        const assetInfo = state.assetInfo[item.asset];

        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'addressOwner'], value: assetInfo.owner });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'asset'], value: assetInfo.asset });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'assetCommon'], value: assetInfo.asset_longname !== null ? assetInfo.asset_longname : item.asset });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'supply'], value: assetInfo.supply });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'monacardName'], value: isAMonacard ? cardName : null});
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'monacardIssuerName'], value: cardOwnerName });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'monacardDescription'], value: isAMonacard ? cardDescription : null });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'monacardTag'], value: cardTag });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'cid'], value: cid });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'description'], value: assetInfo.description });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'lock'], value: assetInfo.locked });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'divisible'], value: assetInfo.divisible });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'listed'], value: assetInfo.listed });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'vendable'], value: assetInfo.vendable });
        dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'reassignable'], value: assetInfo.reassignable });

        const body = {
          asset: item.asset,
          monacardName: cardName,
          monacardOwnerName: cardOwnerName,
          monacardDescription: cardDescription,
          monacardTag: cardTag,
          cid,
          cardImageUrl,
          cardImageUrlSP,
          isAMonacard,
          description: state.assetInfo[item.asset].description,
          locked: state.assetInfo[item.asset].locked,
          divisible: state.assetInfo[item.asset].divisible,
          listed: state.assetInfo[item.asset].listed,
          vendable: state.assetInfo[item.asset].vendable,
          reassignable: state.assetInfo[item.asset].reassignable,
        };
        const popup = { type: 'editAssetInfoPopup', body: body };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
      <img className='size2x2' src={iconEdit} alt='' />
    </button>;
  }

  // 画像

  let image;
  let imageSp;

  if (isAMonacard === false) {
    image = <img className='cardImageLarge' src={imageNotAMonacard} alt='' />;
    imageSp = <img className='cardImageFullWidth' src={imageNotAMonacard} alt='' />;
  }
  else {
    image = <img className='cardImageLarge' src={cardImageUrl} alt='' />
    imageSp = <img className='cardImageFullWidth' src={cardImageUrlSP} alt='' />
  }

  // モナダム情報

  let monadomInfoLite;
  let monadomInfoButton;

  if (information !== undefined) {
    // エディション(レプリカ)
    let edition;

    if (information.edition === 'replica') {
      edition =
      <div className='marginSide0p5'>
        【OFFICIAL REPLICA】
      </div>;
    }

    if (information.category === 'magic') {

      monadomInfoLite =
      <div className='flexColumn alignItemsCenter widthMax ' >
        <div className='flexColumn alignItemsFlexStart width90PC padding1 backgroundImageKOM borderRadius0p5 ' >
          <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
            <div className=''>
              { words.magic[state.language] }
            </div>
            <div className='flexRow alignItemsCenter'>
              { edition }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
            <div className=''>
              { words.name[state.language] }
            </div>
            <div className=''>
              { information.name }
            </div>
            <div className='preWrap marginTop1'>
              { information.flavorText }
            </div>
          </div>
        </div>
      </div>
    }
    else { // monster
      monadomInfoLite =
      <div className='flexColumn alignItemsCenter widthMax ' >
        <div className='flexColumn alignItemsFlexStart width90PC padding1 backgroundImageKOM borderRadius0p5 ' >
          <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
            <div className=''>
              { words.knight[state.language] }
            </div>
            <div className='flexRow alignItemsCenter'>
              { edition }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
            <div className=''>
              { words.name[state.language] }
            </div>
            <div className=''>
              { information.name }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
            <div className=''>
              { words.profile[state.language] }
            </div>
            <div className=''>
              { information.feature }
            </div>
          </div>
        </div>
      </div>
    }

    // monadom detail button

    monadomInfoButton =
    <button className='button1' tabindex='0'
      onClick={ () => {
        const card = {
          ...item,
          cardImageUrl,
          cardImageUrlSP,
          isAMonacard,
        };

        const popup = {
          type: 'monadomInfo',
          body: {
            card,
          },
        };

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
      <img className='size2x2' src={iconSwords} alt='' />
    </button>;
  }


  return (
    <div className=''>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow justifyContentCenter'>
          {/* image */}
          <div className='margin2'>
            { image }
          </div>
          {/* description */}
          <div className='flexColumn cardImageLarge' >
            <div className='margin2'>
              <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                <div className='flexColumn' >
                  <div className='marginBottom0p5 font1p5'>
                    {assetCommon}
                  </div>
                </div>
              </div>
              <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
                { balance }
                <div className=''>
                  {words.amountIssued[state.language]}
                </div>
                <div className='font1p5 marginSide0p5'>
                  {`${supply}`}
                </div>
                { lockedImage }
              </div>
              <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
                { vendable }
                { listed }
              </div>
              <div className='flexColumn marginBottom0p5'>
                <div>
                  {words.owner[state.language]}
                </div>
                { ownerBlock }
              </div>
            </div>
            <div className='flexColumn margin2'>
              <div className='widthMax25 font1p5 marginBottom0p5'>
                { cardName }
              </div>
              <div className='widthMax25'>
                { cardDescription }
              </div>
            </div>
            <div className='flexRow margin2'>
              {/* item detail more */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  const popup = { type: 'itemDetailMore', body: item };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
                }}
              >
                <img className='size2x2' src={iconDetail} alt='' />
              </button>
              { monadomInfoButton }
              {/* Monacard */}
              <button className='button1' tabindex='0'
                onClick={ () => window.open(`https://card.mona.jp/explorer/card_detail?asset=${item.asset}`) }
              >
                <img className='size2x2' src={iconCard} alt='' />
              </button>
              { editAssetInfoButton }
              { sendButton }
            </div>
            { monadomInfoLite }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter visibleSmallOrLess ">
        {/* image */}
        <div className='marginTopBottom1'>
          { imageSp }
        </div>
        <div className='flexColumn alignItemsCenter marginTopBottom1'>
          <div className='width96vw'>
            <div className='flexRow justifyContentFlexStart alignItemsCenter' >
              <div className='flexColumn' >
                <div className='marginBottom0p5 font1p5'>
                  {assetCommon}
                </div>
              </div>
            </div>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
              { balance }
              <div className=''>
                {words.amountIssued[state.language]}
              </div>
              <div className='font1p5 marginSide0p5'>
                {`${supply}`}
              </div>
              { lockedImage }
            </div>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
              { vendable }
              { listed }
            </div>
            {/* owner */}
            <div className='flexColumn marginBottom0p5 '>
              <div>
                {words.owner[state.language]}
              </div>
              { ownerBlockSp }
            </div>
            <div className='flexColumn marginTopBottom1'>
              {/* card name */}
              <div className='widthMax25 font1p5 marginBottom0p5'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].card_name : null */}
                { cardName }
              </div>
              {/* description */}
              <div className='widthMax25'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].add_description : null */}
                { cardDescription }
              </div>
            </div>
            <div className='flexRow marginTopBottom1'>
              {/* item detail more */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  const popup = { type: 'itemDetailMore', body: item };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
                }}
              >
                <img className='size2x2' src={iconDetail} alt='' />
              </button>
              { monadomInfoButton }
              {/* Monacard */}
              <button className='button1' tabindex='0'
                onClick={ () => window.open(`https://card.mona.jp/explorer/card_detail?asset=${item.asset}`) }
              >
                <img className='size2x2' src={iconCard} alt='' />
              </button>
              { editAssetInfoButton }
              { sendButton }
            </div>
            { monadomInfoLite }
          </div>
        </div>
      </div>
    </div>
  );
}

// MONADOM INFO
function MonadomInfo(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const card = state.popup[popupLayer].body.card;

  // 画像

  let image;
  let imageSp;

  if (card.isAMonacard === false) {
    image = <img className='cardImageLarge marginSide1' src={imageNotAMonacard} alt='' />;
    imageSp = <img className='cardImageFullWidth' src={imageNotAMonacard} alt='' />;
  }
  else {
    image = <img className='cardImageLarge marginSide1' src={card.cardImageUrl} alt='' />;
    imageSp = <img className='cardImageFullWidth' src={card.cardImageUrlSP} alt='' />;
  }

  // クリエイター情報編集

  const popup = { type: 'creatorInformation', body: card };

  const editButton =
  <button className='button1'
    onClick={ () => {
      const monacottoAddressMain = state.registeredCard[card.asset].monacottoLink?.url?.match(/mainaddress=(\w+)$/)[1];

      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'creater'], value: state.registeredCard[card.asset].creater }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrText'], value: state.registeredCard[card.asset].createrText }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'monacottoAddressMain'], value: monacottoAddressMain }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup }); 
    }}
  >
    <img className='size2x2' src={iconEdit} alt='' />
  </button>;


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexRow justifyContentCenter'>
        <div className=''>
          { image }
        </div>
        <div className='flexColumn alignItemsFlexStart cardImageLarge marginSide1'>
          <div className='flexColumn alignItemsFlexStart widthMax '>
            <CardSpec card={card} />
          </div>
          <div className='flexColumn alignItemsFlexStart widthMax borderKOMFatBorder padding1 marginTop0p5'>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
              <div className='marginRight1'>
                { words.creater[state.language] }
              </div>
              <div className='font1p5'>
                { state.registeredCard[card.asset].creater }
              </div>
            </div>
            <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
              value={ state.registeredCard[card.asset].createrText }
            />
            <div className='flexRow justifyContentFlexStart '>
              <MonacottoLink asset={card.asset}  />
              <CreaterLink asset={card.asset} index={0} />
              <CreaterLink asset={card.asset} index={1} />
              <CreaterLink asset={card.asset} index={2} />
            </div>
            <div className='flexRow justifyContentFlexEnd widthMax '>
              { editButton }
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <div className=''>
          { imageSp }
        </div>
        <div className='flexColumn alignItemsFlexStart width96vw '>
          <div className='flexColumn alignItemsFlexStart widthMax '>
            <CardSpec card={card} />
          </div>
          <div className='flexColumn alignItemsFlexStart widthMax borderKOMFatBorder padding1 marginTop0p5'>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
              <div className='marginRight1'>
                { words.creater[state.language] }
              </div>
              <div className='font1p5'>
                { state.registeredCard[card.asset].creater }
              </div>
            </div>
            <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
              value={ state.registeredCard[card.asset].createrText }
            />
            <div className='flexRow justifyContentFlexStart '>
              <MonacottoLink asset={card.asset}  />
              <CreaterLink asset={card.asset} index={0} />
              <CreaterLink asset={card.asset} index={1} />
              <CreaterLink asset={card.asset} index={2} />
            </div>
            <div className='flexRow justifyContentFlexEnd widthMax '>
              { editButton }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// CARD SPEC
function CardSpec(props) {
  const [state] = useContext(GlobalState);
  const card = props.card;
  const information = state.registeredCard[card.asset];

  // エディション(レプリカ)
  let edition;

  if (information.edition === 'replica') {
    edition =
    <div className='marginSide0p5'>
      【OFFICIAL REPLICA】
    </div>;
  }

  // カードスペック

  let spec;

  if (information.category === 'magic') {

    // タイプ
    let typeText = '';

    if (information.preEffects !== undefined) {
      typeText = information.preEffects.reduce( (acc, cur) => {
        if (cur.type !== undefined && cur.type !== null && cur.type !== '') {
          return acc + words[cur.type][state.language] + ' ';
        }
        else {
          return acc;
        }
      },
      '');
    }

    if (typeText === '') {
      typeText = words.nothing[state.language];
    }

    // コスト
    let costText = '';

    if (information.costs !== undefined) {
      for (const cost of information.costs) {
        if (cost.type === 'discardCards') {
          if (cost.comparison === 'equal') {
            costText += words.discardCards01[state.language] + cost.amount + words.discardCards02[state.language];
          }
        }
      }
    }

    if (costText === '') {
      costText = words.nothing[state.language];
    }

    // 排他
    let exclusionText = '';

    if (information.exclusions !== undefined) {
      for (const exclusion of information.exclusions) {
        if (exclusion.type === 'excludeOpponent') {
          exclusionText += exclusion.typesToExclude.reduce( (acc, cur) => acc + words[cur][state.language] + ' ', '');
        }
      }
    }

    if (exclusionText === '') {
      exclusionText = words.nothing[state.language];
    }

    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.magic[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { information.name }
        </div>
        <div className='preWrap marginTop1'>
          { information.flavorText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.type[state.language] }
        </div>
        <div className='preWrap'>
          { typeText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.effect[state.language] }
        </div>
        <div className='preWrap'>
          { information.effectText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.cost[state.language] }
        </div>
        <div className='preWrap'>
          { costText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.exclusion[state.language] }
        </div>
        <div className='preWrap'>
          { exclusionText }
        </div>
      </div>
      <div className='flexRow alignItemsCenter borderMonacotto marginTop1' >
        <div className='textCenter width2p4 height1p2' >
          { '0' }
        </div>
        {
          information.maginaThresholds.map( threshold =>
            <div className='textCenter width2p4 height1p2' >
              { threshold.face !== undefined ? threshold.face : threshold.upperBound }
            </div>
          )
        }
      </div>
    </div>
  }
  else { // monster
    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.knight[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { information.name }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.profile[state.language] }
        </div>
        <div className=''>
          { information.feature }
        </div>
      </div>
    </div>
  }

  return spec;
}

// MONACOTTO LINK
function MonacottoLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;

  if (state.registeredCard[asset].monacottoLink?.url === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].monacottoLink.url );
        }}
      >
        <img className='heightMax' src={logoMonacotto} alt='' />
        <img className='heightMax' src={iconCotto} alt='' />
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].monacottoLink.url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} alt='' />
      </button>
    </div>
  );
}

// CREATER LINK
function CreaterLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;
  const index = props.index;

  if (state.registeredCard[asset].createrLink?.[index] === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 textCenter flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].createrLink[index].url );
        }}
      >
        { state.registeredCard[asset].createrLink[index].title }
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].createrLink[index].url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} alt='' />
      </button>
    </div>
  );
}

// CREATOR INFORMATION
function CreatorInformation(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = state.popup[props.popupLayer].body;

  // 登録ボタン

  let registerButton;

  if (state.walletType === 'mpurse') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          handleClickEditCreatorInformation(state, dispatch, item.asset);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt=''/>
      </button>
    </div>
  }
  else if (state.walletType === 'nfc') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickNfc(state, dispatch, callback);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} alt=''/>
        </div>
      </button>
    </div>
  }
  else { // qr
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickScanQr(state, dispatch, callback, { popupLayer: props.popupLayer + 1 });
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconQr} alt=''/>
        </div>
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexColumn alignItemsFlexStart borderKOM marginSide1 marginTop1 padding0p5'>
          {/* asset */}
          <div className='margin1'>
            { item.asset }
          </div>
          {/* creater */}
          <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrText */}
          <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
            boxClass='box4 margin1' textAreaClass='textArea1'
          />
          {/* monacottoAddressMain */}
          <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
            face={words.monacottoAddressMain[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrLink */}
          <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* register button */}
          { registerButton }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsFlexStart widthMax padding1">
        {/* asset */}
        <div className=''>
          { item.asset }
        </div>
        {/* creater */}
        <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrText */}
        <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1 '
        />
        {/* monacottoAddressMain */}
        <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
          face={words.monacottoAddressMain[state.language]} outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrLink */}
        <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* register button */}
        { registerButton }
      </div>
    </div>
  );
}


// ITEM DETAIL MORE
function ItemDetailMore(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = state.popup[props.popupLayer].body;

  let asset_longname;

  if (state.assetInfo[item.asset] !== undefined) {
    asset_longname = state.assetInfo[item.asset].asset_longname;
  }
  else {
    asset_longname = null;
  }

  let addressOwner;

  if (state.assetInfo[item.asset] !== undefined) {
    addressOwner = state.assetInfo[item.asset].owner;
  }
  else {
    addressOwner = null;
  }

  let supply;

  if (state.assetInfo[item.asset] !== undefined) {
    supply = state.assetInfo[item.asset].supply;
  }
  else {
    supply = null;
  }

  let lockState;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].locked) {
      lockState = 'locked';
    }
    else {
      lockState = 'unlocked';
    }
  }
  else {
    lockState = 'blank';
  }

  let vendable;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].vendable) {
      vendable = 'ok';
    }
    else {
      vendable = 'ng';
    }
  }
  else {
    vendable = 'blank';
  }

  let listed;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].listed) {
      listed = 'ok';
    }
    else {
      listed = 'ng';
    }
  }
  else {
    listed = 'blank';
  }

  let reassignable;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].reassignable) {
      reassignable = 'ok';
    }
    else {
      reassignable = 'ng';
    }
  }
  else {
    reassignable = 'blank';
  }

  let divisible;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].divisible) {
      divisible = 'ok';
    }
    else {
      divisible = 'ng';
    }
  }
  else {
    divisible = 'blank';
  }


  return (
    <div className=''>
      {/* PC */}
      <div className="flexColumn visibleMiddleOrMore">
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {item.asset}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1'>
            {addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.issueLockStatus[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[lockState][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.dispenser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[vendable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.dex[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[listed][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.divisibleSend[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[divisible][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.reassign[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[reassignable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.totalSupply[state.language]}
          </div>
          <div className='paddingLeft1'>
            {supply}
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter widthMax visibleSmallOrLess">
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {item.asset}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1 breakAll'>
            {addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.issueLockStatus[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[lockState][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.dispenser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[vendable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.dex[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[listed][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.divisibleSend[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[divisible][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.reassign[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[reassignable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.totalSupply[state.language]}
          </div>
          <div className='paddingLeft1'>
            {supply}
          </div>
        </div>
      </div>
    </div>
  );
}


// TOKEN FILTER SECTION
function TokenFilterSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayerNext = props.popupLayerNext;


  return (
    <button className='button2'
      onClick={ () => {
        const popup = { type: 'tokenFilterPopup' };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      }}
    >
      { words.filter[state.language] }
    </button>
  );
}

// MENU POPUP
function MenuPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  return <MenuSection popupLayer={popupLayer} />;
}

// USER REGISTRATION POPUP
function UserRegistrationPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  return <UserRegistration popupLayer={popupLayer} />;
}

// LOGIN POPUP
function LoginPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  // アドレスセクション・スイッチアイコン

  let loginSection;
  let addressSection;
  let switchIcon;

  if (state.login.displayAddressSection) {
    addressSection =
    <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5'>
      <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='right' />
      <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain'], popupLayer) }>
        <img className='size2x2' src={iconAddress} alt='' />
      </button>
    </div>;

    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  // セッション選択ボタン

  const selectSessionButton =
  <button
    className={'button1 ' + indicateSessions(state)}
    onClick={ () => {
      const popup = {
        type: 'selectSessionPopup',
        body: {
          keysArray: [
            ['login', 'addressMain'],
            ['active', 'addressMain'],
            ['sendMona', 'addressFrom'],
            ['sendXmp', 'addressFrom'],
            ['sendToken', 'addressFrom'],
          ],
          qr: true,
        },
      };

      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
    }}
  >
    <img className='size2x2' src={iconLoginList} alt='' />
  </button>;

  // ログインボタンクリック関数

  let onClickLoginFunction;

  if (state.walletType === 'mpurse') {
    onClickLoginFunction = () => {
      handleClickLogin(state, dispatch, popupLayer);
    };
  }
  else if (state.walletType === 'nfc') {
    onClickLoginFunction = async () => {
      const callback = (keyPairs) => {
        handleClickLogin(state, dispatch, popupLayer, keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);
      };
        
      handleClickNfc(state, dispatch, callback);

      dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    };
  }
  else { // qr
    onClickLoginFunction = () => {
      const callback = (keyPairs) => {
        handleClickLogin(state, dispatch, popupLayer, keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);
      };

      handleClickScanQr(state, dispatch, callback);

      dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    };
  }

  // ログインセクション

  loginSection =
  <div>
    <div className='flexColumn alignItemsCenter marginTop0p5'>
      {/*
        <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='rightLong' />
        <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain']) }>
          <img className='size2x2' src={iconMpurse} alt='' />
        </button>
      */}
      { addressSection }
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ onClickLoginFunction } >
        <div>
          { words.login[state.language] }
        </div>
        { SignIcon() }
      </button>
      <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5'>
        { selectSessionButton }
        <button className={'button1'} tabindex='0'
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['login', 'displayAddressSection'], value: state.login.displayAddressSection ? false : true });
          }}
        >
          <img className='size2x2' src={switchIcon} alt='' />
        </button>
      </div>
    </div>
    {/*
      {/ SP /}
      <div className='visibleSmallOrLess flexColumn alignItemsFlexStart marginTop0p5'>
        <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='right' />
        <div className='flexRow justifyContentFlexStart alignItemsCenter '>
          <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain']) }>
            <img className='size2x2' src={iconMpurse} alt='' />
          </button>
          <button className='button1' tabindex='0'
            onClick={ () => {
              handleClickLogin(state, dispatch, popupLayer);
            }}
          >
            <img className='size2x2' src={iconLogin} alt='' />
          </button>
          { selectSessionButton }
        </div>
      </div>
    */}
  </div>;

  // }
  // else if (state.walletType === 'nfc') {
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ async () => {
  //         const callback = (keyPairs) => {
  //           handleClickLogin(state, dispatch, popupLayer, keyPairs);
  //           keepKeyPairsInMemory(state, dispatch, keyPairs);
  //         };

  //         handleClickNfc(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconKeyCard} alt='' />
  //       </div>
  //     </button>
  //     { selectSessionButton }
  //   </div>;
  // }
  // else { // qr
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ () => {
  //         const callback = (keyPairs) => {
  //           handleClickLogin(state, dispatch, popupLayer, keyPairs);
  //           keepKeyPairsInMemory(state, dispatch, keyPairs);
  //         };

  //         handleClickScanQr(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconQr} alt='' />
  //       </div>
  //     </button>
  //     { selectSessionButton }
  //   </div>;
  // }

  // // ウォレット選択ボタン

  // let walletSelectBox;

  // const mpurseButton =
  // <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'mpurse' ? 'colorRed borderSelected' : 'borderNone')}
  //   tabindex='0'
  //   onClick={ () => {
  //     dispatch({type: 'setState', key: 'walletType', value: 'mpurse'});
  //   }}
  // >
  //   <div>
  //     {words.mpurse[state.language]}
  //   </div>
  //   <div>
  //     {words.monapalette[state.language]}
  //   </div>
  // </button>;

  // const nfcButton =
  // <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'nfc' ? 'colorRed borderSelected' : 'borderNone')}
  //   tabindex='0'
  //   onClick={ () => {
  //     dispatch({type: 'setState', key: 'walletType', value: 'nfc'});
  //   }}
  // >
  //   <div>
  //     {words.keyCard[state.language]}
  //   </div>
  //   <div>
  //     {words.nfc[state.language]}
  //   </div>
  // </button>;

  // const bcButton =
  // <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'qr' ? 'colorRed borderSelected' : 'borderNone')}
  //   tabindex='0'
  //   onClick={ () => {
  //     dispatch({type: 'setState', key: 'walletType', value: 'qr'});
  //   }}
  // >
  //   <div>
  //     {words.keyCard[state.language]}
  //   </div>
  //   <div>
  //     {words.bc[state.language]}
  //   </div>
  // </button>

  // if ('NDEFReader' in window && state.device.hasCamera) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { nfcButton }
  //     { bcButton }
  //   </div>;
  // }
  // else if ('NDEFReader' in window) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { nfcButton }
  //   </div>;
  // }
  // else if (state.device.hasCamera) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { bcButton }
  //   </div>;
  // }
  // else {
  //   walletSelectBox = null;
  // }


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className="marginBottom1 ">
          <SelectWalletBox />
        </div>
        <div className="marginBottom1 ">
          { loginSection }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn alignItemsCenter widthMax'>
        <div className='widthMax padding0p5 marginTopBottom0p5 '>
          <SelectWalletBox />
        </div>
        <div className="">
          { loginSection }
        </div>
      </div>
    </div>
  );
}

// ウォレット選択ボックス
// SELECT WALLET BOX
function SelectWalletBox(props) {
  const [state, dispatch] = useContext(GlobalState);

  let walletSelectBox;

  // ウォレット選択ボタン

  let walletButtons = {};

  walletButtons.mpurse =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'mpurse' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'mpurse'});
    }}
  >
    <div>
      {words.mpurse[state.language]}
    </div>
  </button>;

  walletButtons.nfc =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'nfc' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'nfc'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.nfc[state.language]}
    </div>
  </button>;

  walletButtons.qr =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'qr' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'qr'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.bc[state.language]}
    </div>
  </button>;

  // if ('NDEFReader' in window && state.device.hasCamera) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { nfcButton }
  //     { bcButton }
  //   </div>;
  // }
  // else if ('NDEFReader' in window) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { nfcButton }
  //   </div>;
  // }
  // else if (state.device.hasCamera) {
  //   walletSelectBox =
  //   <div className='flexRow justifyContentSpaceAround widthMax '>
  //     { mpurseButton }
  //     { bcButton }
  //   </div>;
  // }
  // else {
  //   walletSelectBox = null;
  // }

  if (walletsAvailable(state).length >= 2) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { walletsAvailable(state).map( walletType => walletButtons[walletType] ) }
    </div>;
  }


  return walletSelectBox;
}

// 使用可能ウォレット
function walletsAvailable(state) {
  let walletsAvailable =[];

  if (window.mpurse) {
    walletsAvailable.push('mpurse');
  }

  if ('NDEFReader' in window) {
    walletsAvailable.push('nfc');
  }

  if (state?.device.hasCamera) {
    walletsAvailable.push('qr');
  }

  return walletsAvailable;
}

// SIGN ICON
function SignIcon() {
  const [state] = useContext(GlobalState);
  let signIcon;

  if (state.walletType === 'mpurse') {
    signIcon = iconMpurse;
  }
  else if (state.walletType === 'nfc') {
    signIcon = iconKeyCard;
  }
  else { // qr
    signIcon = iconQr;
  }


  return <img className='size2x2 margin0p5' src={signIcon} alt='' />;
}

// SELECT SESSION POPUP
function SelectSessionPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const keysArray = state.popup[popupLayer].body.keysArray;
  const qr = state.popup[popupLayer].body.qr;

  let sessionAddresses = Object.keys(state.session);

  sessionAddresses = sessionAddresses.filter( address =>
    state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
  );

  let qrButtonDummy;

  if (qr === true) {
    qrButtonDummy =
    <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
    </button>
  }

  const title =
  <div className='widthMax'>
    {/* PC */}
    <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
      <div className='marginSide1 widthMin25' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
      { qrButtonDummy }
    </div>
    {/* SP */}
    <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
      <div className='textCenter' >
        { words.loggedInAddress[state.language] }
      </div>
      {/*
        <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
        </button>
      */}
      {/* qrButtonDummy */}
    </div>
  </div>

  const records = sessionAddresses.map( address => {
    let qrButton;

    const selectButton =
    <button className='button1' tabindex='0'
      onClick={ () => {
        for (const keys of keysArray) {
          dispatch({ type: 'setStateMultiLayers', keys: keys, value: address });
        }

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
      }}
    >
      <img className='size2x2' src={iconCheck} alt='' />
    </button>

    if (qr === true) {
      qrButton =
      <button className='button1' tabindex='0'
        onClick={ () => {
          const popup = {
            type: 'displayQrPopup',
            body: {
              data: address,
            },
          };

          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
        }}
      >
        <img className='size2x2' src={iconQr} alt='' />
      </button>
    }

    // アクティブカラー

    let activeColor = '';

    if (state.active.addressMain === address) {
      activeColor = ' backgroundColorPinkPale';
    }

    return (
      <div className=''>
        {/* PC */}
        <div className={'visibleMiddleOrMore boxMonacotto1 margin0p5' + activeColor} >
          <div className='marginSide1 widthMin25' >
            { address }
          </div>
          { selectButton }
          { qrButton }
        </div>
        {/* SP */}
        <div className={'visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' + activeColor} >
          <div className='textCenter' >
            { address }
          </div>
          { selectButton }
          { qrButton }
        </div>
      </div>
    );
  });


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { records }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { records }
        </div>
      </div>
    </div>
  );
}

// VIEW SESSION POPUP
function ViewSessionPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  let sessionAddresses = Object.keys(state.session);

  sessionAddresses = sessionAddresses.filter( address =>
    state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
  );

  const title =
  <div className='widthMax'>
    {/* PC */}
    <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
      <div className='marginSide1 widthMin25' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
    </div>
    {/* SP */}
    <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
      <div className='textCenter' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
    </div>
  </div>

  const records = sessionAddresses.map( address =>
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { address }
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            const popup = {
              type: 'displayQrPopup',
              body: {
                data: address,
              },
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
          }}
        >
          <img className='size2x2' src={iconQr} alt='' />
        </button>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { address }
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            const popup = {
              type: 'displayQrPopup',
              body: {
                data: address,
              },
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
          }}
        >
          <img className='size2x2' src={iconQr} alt='' />
        </button>
      </div>
    </div>
  );


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { records }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { records }
        </div>
      </div>
    </div>
  );
}

// DISPLAY QR POPUP
function DisplayQrPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const data = state.popup[popupLayer].body.data;

  return (
    <div className='flexColumn alignItemsCenter '>
      <QRCode value={data} />
    </div>
  );
}

// SEND MONA POPUP
function SendMonaPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  // タイトル

  const title =
  <div className=''>
    MONA
  </div>;

  // 送信量

  const amountSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendMonaAmount' keys={['sendMona', 'amount']} face={words.amountToSend[state.language]} type='setStateMultiLayersFloat' tooltip='right' />
  </div>;

  // 送信元アドレス

  const addressFromSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendMonaAddressFrom' keys={['sendMona', 'addressFrom']} face={words.addressFrom[state.language]} tooltip='right' />
    <button className={'button1 ' + indicateSessions(state)}
      onClick={ () => {
        const popup = {
          type: 'selectSessionPopup',
          body: {
            keysArray: [['sendMona', 'addressFrom']],
          },
        };

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
      <img className='size2x2' src={iconLoginList} alt='' />
    </button>
  </div>;

  // QR読み取りボタン

  let qrButton;

  if (state.device.hasCamera) {
    qrButton =
    <button className='button1'
      onClick={ () => {
        const callback = (addressTo) => {
          dispatch({ type: 'setStateMultiLayers', keys: ['sendMona', 'addressTo'], value: addressTo });
        };

        handleClickScanQr(state, dispatch, callback, { callbackType: 'simpelCallback', popupLayer: popupLayer + 1 });
      }}
    >
      <img className='size2x2' src={iconQr} alt='' />
    </button>;
  }
  else {
    qrButton = 
    <button className='dummyPadButton1 ' disabled={true} >
    </button>;
  }

  // 送信先セクション

  const addressToSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendMonaAddressTo' keys={['sendMona', 'addressTo']} face={words.addressTo[state.language]} tooltip='right' />
    { qrButton }
  </div>

  // 送信ボタン

  const commitButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
    onClick={ async () => {
      // 入力チェック

      if (state.sendMona.amount.value === undefined || state.sendMona.amount.value === null || state.sendMona.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendMona.addressFrom === undefined || state.sendMona.addressFrom === null || state.sendMona.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendMona.addressTo === undefined || state.sendMona.addressTo === null || state.sendMona.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      const amountMonaDecimal = new decimal(state.sendMona.amount.value);
      const amountWatanabe = decimalAdjust( 'floor',  amountMonaDecimal.times(100000000).toNumber(), 0 ); 
      const actionItemsMultiSources = addSendsToTheCartMona(state, dispatch, state.sendMona.addressFrom, state.sendMona.addressTo, amountWatanabe);

      for (const addressFrom of Object.keys(actionItemsMultiSources)) {
        ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
          actionItem.status = 'unprocessed';
        }
      }

      const keyPairs = getValidKeyPairs(state, dispatch);

      dispatch({ type: 'setState', key: 'accessing', value: true });

      const actionItemsMultiSourcesSigned = await buildMonaTransactions(
        state, dispatch, keyPairs, actionItemsMultiSources,
        {
          provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
          transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
        }
      );

      devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

      dispatch({ type: 'setState', key: 'accessing', value: false });

      handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'mona');
    }}
  >
    <div>
      {words.send[state.language]}
    </div>
    <img className='size2x2' src={iconSign} alt='' />
  </button>

  // まとめて送信ボタン

  const addToTheCartButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
    onClick={ () => {
      // 入力チェック

      if (state.sendMona.amount.value === undefined || state.sendMona.amount.value === null || state.sendMona.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendMona.addressFrom === undefined || state.sendMona.addressFrom === null || state.sendMona.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendMona.addressTo === undefined || state.sendMona.addressTo === null || state.sendMona.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      const amountMonaDecimal = new decimal(state.sendMona.amount.value);
      const amountWatanabe = decimalAdjust( 'floor',  amountMonaDecimal.times(100000000).toNumber(), 0 ); 

      addSendsToTheCartMona(state, dispatch, state.sendMona.addressFrom, state.sendMona.addressTo, amountWatanabe);

      dispatch({ type: 'setNotification', key: 'notification', value: words.addedYourCart[state.language] });
    }}
  >
    <div>
      {words.sendAllAtOnce[state.language]}
    </div>
    <img className='size2x2' src={iconSign} alt='' />
  </button>;


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsFlexStart '>
        { title }
        { amountSection }
        { addressFromSection }
        { addressToSection }
        { commitButton }
        { addToTheCartButton }
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <div className='flexColumn alignItemsFlexStart '>
          { title }
          { amountSection }
          { addressFromSection }
          { addressToSection }
          { commitButton }
          { addToTheCartButton }
        </div>
      </div>
    </div>
  );
}

// SEND XMP POPUP
function SendXmpPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  // タイトル

  const title =
  <div className=''>
    XMP
  </div>;

  // 送信量

  const amountSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendXmpAmount' keys={['sendXmp', 'amount']} face={words.amountToSend[state.language]} type='setStateMultiLayersFloat' tooltip='right' />
  </div>;

  // 送信元アドレス

  const addressFromSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendXmpAddressFrom' keys={['sendXmp', 'addressFrom']} face={words.addressFrom[state.language]} tooltip='right' />
    <button className={'button1 ' + indicateSessions(state)}
      onClick={ () => {
        const popup = {
          type: 'selectSessionPopup',
          body: {
            keysArray: [['sendXmp', 'addressFrom']],
          },
        };

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
      <img className='size2x2' src={iconLoginList} alt='' />
    </button>
  </div>;

  // QR読み取りボタン

  let qrButton;

  if (state.device.hasCamera) {
    qrButton =
    <button className='button1'
      onClick={ () => {
        const callback = (addressTo) => {
          dispatch({ type: 'setStateMultiLayers', keys: ['sendXmp', 'addressTo'], value: addressTo });
        };

        handleClickScanQr(state, dispatch, callback, { callbackType: 'simpelCallback', popupLayer: popupLayer + 1 });
      }}
    >
      <img className='size2x2' src={iconQr} alt='' />
    </button>;
  }
  else {
    qrButton = 
    <button className='dummyPadButton1 ' disabled={true} >
    </button>;
  }

  // 送信先セクション

  const addressToSection =
  <div className='flexRow justifyContentFlexStart'>
    <TextLine3 fieldName='sendXmpAddressTo' keys={['sendXmp', 'addressTo']} face={words.addressTo[state.language]} tooltip='right' />
    { qrButton }
  </div>;

  // 送信ボタン

  const commitButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTopBottom0p5 ' tabindex='0'
    onClick={ async () => {
      // 入力チェック

      if (state.sendXmp.amount.value === undefined || state.sendXmp.amount.value === null || state.sendXmp.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendXmp.addressFrom === undefined || state.sendXmp.addressFrom === null || state.sendXmp.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendXmp.addressTo === undefined || state.sendXmp.addressTo === null || state.sendXmp.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      const actionItemsMultiSources = addTokensToTheCartMonaparty(state, dispatch, state.sendXmp.addressFrom, state.sendXmp.addressTo, 'XMP', new decimal(state.sendXmp.amount.value).times(100000000).toNumber());
      const keyPairs = getValidKeyPairs(state, dispatch);

      for (const addressFrom of Object.keys(actionItemsMultiSources)) {
        ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
          actionItem.status = 'unprocessed';
        }
      }

      const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
      devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

      handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
    }}
  >
    <div>
      {words.send[state.language]}
    </div>
    <img className='size2x2' src={iconSend} alt='' />
  </button>;

  // まとめて送信ボタン

  const addToTheCartButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTopBottom0p5 ' tabindex='0'
    onClick={ () => {
      // 入力チェック

      if (state.sendXmp.amount.value === undefined || state.sendXmp.amount.value === null || state.sendXmp.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendXmp.addressFrom === undefined || state.sendXmp.addressFrom === null || state.sendXmp.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendXmp.addressTo === undefined || state.sendXmp.addressTo === null || state.sendXmp.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      addTokensToTheCartMonaparty(state, dispatch, state.sendXmp.addressFrom, state.sendXmp.addressTo, 'XMP', new decimal(state.sendXmp.amount.value).times(100000000).toNumber());

      dispatch({ type: 'setNotification', key: 'notification', value: words.addedYourCart[state.language] });
    }}
  >
    <div>
      {words.sendAllAtOnce[state.language]}
    </div>
    <img className='size2x2' src={iconSend} alt='' />
  </button>;

  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsFlexStart '>
        { title }
        { amountSection }
        { addressFromSection }
        { addressToSection }
        { commitButton }
        { addToTheCartButton }
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <div className='flexColumn alignItemsFlexStart '>
          { title }
          { amountSection }
          { addressFromSection }
          { addressToSection }
          { commitButton }
          { addToTheCartButton }
        </div>
      </div>
    </div>
  );
}

// SEND TOKEN POPUP
function SendTokenPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const asset = state.popup[popupLayer].body.asset;

  // useEffect( () => {
  //   dispatch({ type: 'setStateMultiLayers', keys: ['sendToken', 'asset'], value: asset });
  // }, []);


  // // アセット名

  // const assetSection =
  // <div className='marginTopBottom0p5'>
  //   <TextLine3 fieldName='sendTokenAsset' keys={['sendToken', 'asset']} face={words.tokenName[state.language]} />
  // </div>;

  // 送信量

  const amountSection =
  <div className='marginTopBottom0p5'>
    <TextLine3 fieldName='sendTokenAmount' keys={['sendToken', 'amount']} face={words.amountToSend[state.language]} type='setStateMultiLayersFloat' tooltip='right' />
  </div>;


  // 送信元アドレス

  const addressFromSection =
  <div className='flexRow justifyContentFlexStart marginTopBottom0p5'>
    <TextLine3 fieldName='sendTokenAddressFrom' keys={['sendToken', 'addressFrom']} face={words.addressFrom[state.language]} tooltip='right' />
    <button className={'button1 ' + indicateSessions(state)}
      onClick={ () => {
        const popup = {
          type: 'selectSessionPopup',
          body: {
            keysArray: [['sendToken', 'addressFrom']],
          },
        };

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
      <img className='size2x2' src={iconLoginList} alt='' />
    </button>
  </div>;

  // QR読み取りボタン

  let qrButton;

  if (state.device.hasCamera) {
    qrButton =
    <button className='button1'
      onClick={ () => {
        const callback = (addressTo) => {
          dispatch({ type: 'setStateMultiLayers', keys: ['sendToken', 'addressTo'], value: addressTo });
        };

        handleClickScanQr(state, dispatch, callback, { callbackType: 'simpelCallback', popupLayer: popupLayer + 1 });
      }}
    >
      <img className='size2x2' src={iconQr} alt='' />
    </button>;
  }
  else {
    qrButton = 
    <button className='dummyPadButton1 ' disabled={true} >
    </button>;
  }

  // 送信先セクション

  const addressToSection =
  <div className='flexRow justifyContentFlexStart marginTopBottom0p5'>
    <TextLine3 fieldName='sendTokenAddressTo' keys={['sendToken', 'addressTo']} face={words.addressTo[state.language]} tooltip='right' />
    { qrButton }
  </div>;

  // 送信ボタン

  const commitButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTopBottom0p5 ' tabindex='0'
    onClick={ async () => {
      // 入力チェック

      if (state.sendToken.amount.value === undefined || state.sendToken.amount.value === null || state.sendToken.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.addressFrom === undefined || state.sendToken.addressFrom === null || state.sendToken.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.addressTo === undefined || state.sendToken.addressTo === null || state.sendToken.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.asset === undefined || state.sendToken.asset === null || state.sendToken.asset === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.tokenNameIsRequired[state.language] });
        return { status: 'rejected' };
      }

      let quantity;

      if (state.assetInfo[asset] !== undefined) {
        if (state.assetInfo[asset].divisible === true) {
          quantity = new decimal(state.sendToken.amount.value).times(100000000).toNumber();
        }
        else {
          quantity = state.sendToken.amount.value;
        }
      }
      else {
        return;
      }

      const actionItemsMultiSources = addTokensToTheCartMonaparty(state, dispatch, state.sendToken.addressFrom, state.sendToken.addressTo, state.sendToken.asset, quantity);
      const keyPairs = getValidKeyPairs(state, dispatch);

      for (const addressFrom of Object.keys(actionItemsMultiSources)) {
        ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
          actionItem.status = 'unprocessed';
        }
      }

      const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
      devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

      handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
    }}
  >
    <div>
      {words.send[state.language]}
    </div>
    <img className='size2x2' src={iconSend} alt='' />
  </button>;

  // まとめて送信ボタン

  const addToTheCartButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTopBottom0p5 ' tabindex='0'
    onClick={ () => {
      // 入力チェック

      if (state.sendToken.amount.value === undefined || state.sendToken.amount.value === null || state.sendToken.amount.value === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSendIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.addressFrom === undefined || state.sendToken.addressFrom === null || state.sendToken.addressFrom === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressFromIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.addressTo === undefined || state.sendToken.addressTo === null || state.sendToken.addressTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressToIsRequired[state.language] });
        return { status: 'rejected' };
      }

      if (state.sendToken.asset === undefined || state.sendToken.asset === null || state.sendToken.asset === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.tokenNameIsRequired[state.language] });
        return { status: 'rejected' };
      }

      let quantity;

      if (state.assetInfo[asset] !== undefined) {
        if (state.assetInfo[asset].divisible === true) {
          quantity = new decimal(state.sendToken.amount.value).times(100000000).toNumber();
        }
        else {
          quantity = state.sendToken.amount.value;
        }
      }
      else {
        return;
      }

      addTokensToTheCartMonaparty(state, dispatch, state.sendToken.addressFrom, state.sendToken.addressTo, state.sendToken.asset, quantity);

      dispatch({ type: 'setNotification', key: 'notification', value: words.addedYourCart[state.language] });
    }}
  >
    <div>
      {words.sendAllAtOnce[state.language]}
    </div>
    <img className='size2x2' src={iconSend} alt='' />
  </button>


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsFlexStart '>
        {/* assetSection */}
        { amountSection }
        { addressFromSection }
        { addressToSection }
        { commitButton }
        { addToTheCartButton }
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <div className='flexColumn alignItemsFlexStart '>
          {/* assetSection */}
          { amountSection }
          { addressFromSection }
          { addressToSection }
          { commitButton }
          { addToTheCartButton }
        </div>
      </div>
    </div>
  );
}

// EDIT ASSET INFO POPUP
function EditAssetInfoPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const body = state.popup[popupLayer].body;
  const cardImageUrl = body.cardImageUrl;
  const cardImageUrlSP = body.cardImageUrlSP;
  const isAMonacard = body.isAMonacard;

  const editModeOption = [
    { value: 'editMonacard', label: words.editMonacard[state.language] },
    { value: 'additionalIssuance', label: words.additionalIssuance[state.language] },
    { value: 'lockIssuance', label: words.lockIssuance[state.language] },
    { value: 'transferOwnership', label: words.transferOwnership[state.language] },
    { value: 'editAdvancedToken', label: words.editAdvancedToken[state.language] },
  ];

  const editModeDropdown =
  <select className="custom-dropdown" value={state.editAssetInfo.editMode}
    onChange={ event => {
      dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'editMode'], value: event.target.value });
    }}
  >
    {
      editModeOption.map( option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))
    }
  </select>

  const dispatchDescription = (key, value) => {
    let description;

    try {
      description = JSON.parse(state.editAssetInfo.description);
    }
    catch {
      description = {
        monacard: {
            name: state.editAssetInfo.monacardName,
            owner: state.editAssetInfo.monacardIssuerName,
            desc: state.editAssetInfo.monacardDescription,
            tag: state.editAssetInfo.monacardTag,
            cid: state.editAssetInfo.cid,
            ver: '2',
        }
      };
    }

    description.monacard[key] = value;

    dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'description'], value: JSON.stringify(description) });
  };

  const getExtraFunctions = (key) => {
    return [
      {
        function: dispatchDescription,
        arguments: [ key ],
        targetValue: true,
      }
    ];
  };

  // 画像セクション

  let image;
  let imageSp;

  if (state.editAssetInfo.imagesNew.monacardUrl !== undefined && state.editAssetInfo.imagesNew.monacardUrl !== null) {
    image = <img className='monacardImageAbsolute' src={state.editAssetInfo.imagesNew.monacardUrl} alt='' />;
    imageSp = <img className='monacardImageAbsolute' src={state.editAssetInfo.imagesNew.monacardUrl} alt='' />;
  }
  else if (isAMonacard) {
    image = <img className='monacardImageAbsolute' src={cardImageUrl} alt='' />
    imageSp = <img className='monacardImageAbsolute' src={cardImageUrlSP} alt='' />
  }

  const onDropAccepted = useCallback((acceptedFiles) => {
    devLog('acceptedFiles', acceptedFiles);
    dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'imagesNew', 'monacard'], value: acceptedFiles[0] });
    const dataUrl = URL.createObjectURL(acceptedFiles[0]);
    devLog('dataUrl', dataUrl);
    dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'imagesNew', 'monacardUrl'], value: dataUrl });
  }, []);

  const maxFiles = 1;
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
  };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({ onDropAccepted, maxFiles, accept });

  const dropZoneStyle = {
    width: '272px',
    height: '388px',
    border: "1px dotted #888",
  };

  let imageSection;

  if (state.editAssetInfo.editMode === 'editMonacard' || state.editAssetInfo.editMode === 'editAdvancedToken') {
    imageSection =
    <div className='relative margin1 '>
        <div {...getRootProps()} style={dropZoneStyle} >
          <input {...getInputProps} style={{ display: 'none' }} />
          <div className='padding0p5'>
            { words.monacardImageSpecifications[state.language] }<br/>
          </div>
          <div className='padding0p5'>
            { words.dragAndDrop[state.language] }<br/>
          </div>
        </div>
        { image }
    </div>;
  }

  const inputImageFile = useRef(null);

  let imageSectionSP;

  if (state.editAssetInfo.editMode === 'editMonacard' || state.editAssetInfo.editMode === 'editAdvancedToken') {
    imageSectionSP =
    <div>
      <input ref={inputImageFile} className='invisible' type='file' accept='image/png, .png, image/jpeg, .jpg, .jpeg, image/gif, .gif' 
        onChange={ (e) => {
          devLog('acceptedFiles', JSON.stringify(e.currentTarget.files[0].name));
          dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'imagesNew', 'monacard'], value: e.currentTarget.files[0] });
          const dataUrl = URL.createObjectURL(e.currentTarget.files[0]);
          devLog('dataUrl', dataUrl);
          dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'imagesNew', 'monacardUrl'], value: dataUrl });
        }}
      />
      <button className='monacardImageContainer flexColumn justifyContentFlexStart margin1 ' onClick={ () => { inputImageFile.current.click(); } }>
        <div className='padding0p5 textLeft'>
          { words.monacardImageSpecifications[state.language] }<br/>
        </div>
        <div className='padding0p5 textLeft'>
          { words.tap[state.language] }<br/>
        </div>
        { imageSp }
      </button>
    </div>;
  }

  // モナカード更新セクション

  let editMonacardSection;

  if (state.editAssetInfo.editMode === 'editMonacard' || state.editAssetInfo.editMode === 'editAdvancedToken') {
    editMonacardSection =
    <div className='flexColumn alignItemsCenter ' >
      {/*
        <div className='relative margin1 '>
            <div {...getRootProps()} style={dropZoneStyle} >
              <input {...getInputProps} style={{ display: 'none' }} />
              <div className='padding0p5'>
                {/ words.mainImageWithDescription[state.language] /}<br/>
                {words.dragAndDrop[state.language]}<br/>
              </div>
            </div>
            { image }
        </div>
      */}
      <TextLine3 fieldName='editAssetInfoMonacardName' keys={['editAssetInfo', 'monacardName']} face={words.monacardName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('name')} />
      <TextLine3 fieldName='editAssetInfoMonacardIssuerName' keys={['editAssetInfo', 'monacardIssuerName']} face={words.issuerName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('owner')} />
      <TextLine3 fieldName='editAssetInfoMonacardDescription' keys={['editAssetInfo', 'monacardDescription']} face={words.monacardDescription[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('desc')} />
      <TextLine3 fieldName='editAssetInfoMonacardTag' keys={['editAssetInfo', 'monacardTag']} face={words.monacardTag[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('tag')} />
    </div>;
  }

  // モナカード更新ボタン

  let editMonacardButton;

  if (state.editAssetInfo.editMode === 'editMonacard') {
    editMonacardButton =
    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
      onClick={ async () => {
        handleClickEditAssetInfo(state, dispatch, popupLayer, state.editAssetInfo.editMode);
      }}
    >
      <div>
        {words.editMonacard[state.language]}
      </div>
      <img className='size2x2' src={iconSign} alt='' />
    </button>;
  }

  // 高度なトークン更新セクション

  let advancedSection;

  if (state.editAssetInfo.editMode === 'editAdvancedToken') {
    advancedSection =
    <div className='flexColumn alignItemsCenter ' >
      <TextArea2 fieldName='editAssetInfoDescription' keys={['editAssetInfo', 'description']} face='description'
        outerClass='box1Width' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1'
      />
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ async () => {
          handleClickEditAssetInfo(state, dispatch, popupLayer, state.editAssetInfo.editMode);
        }}
      >
        <div>
          {words.editAdvancedToken[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>;
  }

  // 追加発行セクション

  let additonalIssuanceSection;

  if (state.editAssetInfo.editMode === 'additionalIssuance') {
    additonalIssuanceSection =
    <div className='flexColumn alignItemsCenter ' >
      <div className='marginTopBottom0p5 ' >
        { words.currentSupply[state.language] }
      </div>
      <div className='marginTopBottom0p5' >
        { quantityForDisplay(state, { asset: state.editAssetInfo.asset }, state.editAssetInfo.supply) }
      </div>
      <TextLine3 fieldName='editAssetInfoAmount' keys={['editAssetInfo', 'amount']} face={words.amountToBeIssued[state.language]} type='setStateMultiLayersFloat' tooltip={true} />
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ async () => {
          handleClickAdditionalIssuance(state, dispatch, popupLayer);
        }}
      >
        <div>
          {words.additionalIssuance[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>;
  }

  // 新規発行ロックセクション

  let lockIssuanceSection;

  if (state.editAssetInfo.editMode === 'lockIssuance') {
    lockIssuanceSection =
    <div className='flexColumn alignItemsCenter ' >
      <div>
        { words.cautionAboutLock[state.language] }
      </div>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ async () => {
          handleClickLockIssuance(state, dispatch, popupLayer);
        }}
      >
        <div>
          {words.lockIssuance[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>
  }

  // オーナー移転セクション

  let transferOwnershipSection;

  if (state.editAssetInfo.editMode === 'transferOwnership') {
    transferOwnershipSection =
    <div className='flexColumn alignItemsCenter ' >
      <div>
        <TextLine3 fieldName='editAssetInfoNewOwner' keys={['editAssetInfo', 'addressNewOwner']} face={words.addressNewOwner[state.language]} tooltip='right' />
      </div>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ async () => {
          handleClickTransferOwnership(state, dispatch, popupLayer);
        }}
      >
        <div>
          {words.transferOwnership[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter '>
        { editModeDropdown }
        { imageSection }
        { editMonacardSection }
        { editMonacardButton }
        { additonalIssuanceSection }
        { lockIssuanceSection }
        { transferOwnershipSection }
        { advancedSection }
        {/* commitButton */}
        {/* addToTheCartButton */}
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        { editModeDropdown }
        { imageSectionSP }
        { editMonacardSection }
        { editMonacardButton }
        { additonalIssuanceSection }
        { lockIssuanceSection }
        { transferOwnershipSection }
        { advancedSection }
          {/* commitButton */}
          {/* addToTheCartButton */}
      </div>
    </div>
  );
}

// TOKEN FILTER POPUP
function TokenFilterPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;


  return (
    <div className='flexColumn alignItemsCenter'>
      <div className='flexRow justifyContentCenter ' >
        {
          state.tokenFilter.conditions.map( (condition, index) => {
            let styleFocused;

            if (index === state.tokenFilter.conditionIndex) {
              styleFocused = ' backgroundColorPinkPale';
            }
            else {
              styleFocused = ' backgroundColorTransparent';
            }

            return (
              <button className={'flexColumn alignItemsFlexEnd widthMin5 heightMin5 margin0p5 borderMonacotto ' + styleFocused}
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: index });
                }}
              >
                <button className='backgroundColorTransparent borderNone '
                  onClick={ (event) => {
                    if (state.tokenFilter.conditions.length >= 2) {
                      const conditions = [ ...state.tokenFilter.conditions];
                      conditions.splice(index, 1);

                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditions});

                      if (state.tokenFilter.conditionIndex === state.tokenFilter.conditions.length - 1) {
                        dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: state.tokenFilter.conditions.length - 2 });
                      }
                    }
                    else {
                      const conditionsInitial = [
                        {
                          assetCommon: [],
                          addressOwners: [],
                          lockStatus: [],
                          monacardName: [],
                          monacardIssuerNames: [],
                          monacardDescription: [],
                          monacardTags: [],
                          monadom: [],
                        },
                      ];

                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditionsInitial });
                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: 0 });
                    }

                    event.stopPropagation();
                  }}
                >
                  <div className='font2 ' >
                    ×
                  </div>
                </button>
                <div className='flexColumn alignItemsCenter ' >
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='assetCommon' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='addressOwners' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='lockStatus' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardName' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardIssuerNames' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardDescription' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardTags' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monadom' />
                </div>
              </button>
            );
          })
        }
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterAddressOwner' keys={['tokenFilter', 'assetCommon']} face={`${words.assetCommon[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let assetCommons;

            if (state.tokenFilter.assetCommon !== undefined && state.tokenFilter.assetCommon !== null && state.tokenFilter.assetCommon !== '') {
              assetCommons = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].assetCommon, state.tokenFilter.assetCommon ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'assetCommon'], value: assetCommons });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'assetCommon'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterAddressOwner' keys={['tokenFilter', 'addressOwner']} face={words.addressOwner[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let addressOwners;

            if (state.tokenFilter.addressOwner !== undefined && state.tokenFilter.addressOwner !== null && state.tokenFilter.addressOwner !== '') {
              addressOwners = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].addressOwners, state.tokenFilter.addressOwner ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'addressOwners'], value: addressOwners });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'addressOwner'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <div className='flexRow justifyContentCenter box1Width ' >
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.lockStatus === 'locked' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'lockStatus'], value: 'locked'});
            }}
          >
            <div>
              {words.locked[state.language]}
            </div>
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.lockStatus === 'unlocked' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'lockStatus'], value: 'unlocked'});
            }}
          >
            <div>
              {words.unlocked[state.language]}
            </div>
          </button>
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            let lockStatuses;

            if (state.tokenFilter.lockStatus !== undefined && state.tokenFilter.lockStatus !== null && state.tokenFilter.lockStatus !== '') {
              lockStatuses = [state.tokenFilter.lockStatus];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'lockStatus'], value: lockStatuses });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardName' keys={['tokenFilter', 'monacardName']} face={`${words.monacardName[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardNames;

            if (state.tokenFilter.monacardName !== undefined && state.tokenFilter.monacardName !== null && state.tokenFilter.monacardName !== '') {
              monacardNames = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardName, state.tokenFilter.monacardName ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardName'], value: monacardNames });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardName'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardIssuerName' keys={['tokenFilter', 'monacardIssuerName']} face={words.issuerName[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardIssuerNames;

            if (state.tokenFilter.monacardIssuerName !== undefined && state.tokenFilter.monacardIssuerName !== null && state.tokenFilter.monacardIssuerName !== '') {
              monacardIssuerNames = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardIssuerNames, state.tokenFilter.monacardIssuerName ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardIssuerNames'], value: monacardIssuerNames });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardIssuerName'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardDescription' keys={['tokenFilter', 'monacardDescription']} face={`${words.monacardDescription[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardDescriptions;

            if (state.tokenFilter.monacardDescription !== undefined && state.tokenFilter.monacardDescription !== null && state.tokenFilter.monacardDescription !== '') {
              monacardDescriptions = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardDescription, state.tokenFilter.monacardDescription ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardDescription'], value: monacardDescriptions });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardDescription'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardTag' keys={['tokenFilter', 'monacardTag']} face={words.monacardTag[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardTags;

            if (state.tokenFilter.monacardTag !== undefined && state.tokenFilter.monacardTag !== null && state.tokenFilter.monacardTag !== '') {
              monacardTags = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardTags, state.tokenFilter.monacardTag ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardTags'], value: monacardTags });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardTag'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='widthMax textLeft marginTop0p5' >
        { words.monadomRegistration[state.language] }
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <div className='flexRow justifyContentCenter box1Width ' >
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.monadom === 'registered' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'monadom'], value: 'registered'});
            }}
          >
            <div>
              {words.registered[state.language]}
            </div>
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.monadom === 'unregistered' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'monadom'], value: 'unregistered'});
            }}
          >
            <div>
              {words.unregistered[state.language]}
            </div>
          </button>
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monadoms;

            if (state.tokenFilter.monadom !== undefined && state.tokenFilter.monadom !== null && state.tokenFilter.monadom !== '') {
              monadoms = [state.tokenFilter.monadom];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monadom'], value: monadoms });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      {/*
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            let addressOwners;

            if (state.tokenFilter.addressOwner !== undefined && state.tokenFilter.addressOwner !== null && state.tokenFilter.addressOwner !== '') {
              addressOwners = [ ...state.tokenFilter.conditions.addressOwners, state.tokenFilter.addressOwner ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', 'addressOwners'], value: addressOwners });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'addressOwner'], value: '' });
            }
          }}
        >
          <div>
            {words.filter[state.language]}
          </div>
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      */}
      <div className='flexRow justifyContentFlexStart' >
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            const conditionsInitial = {
              assetCommon: [],
              addressOwners: [],
              lockStatus: [],
              monacardName: [],
              monacardIssuerNames: [],
              monacardDescription: [],
              monacardTags: [],
              monadom: [],
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditions.length], value: conditionsInitial });
            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: state.tokenFilter.conditions.length });
          }}
        >
          {words.addAndCondition[state.language]}
        </button>
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            const conditionsInitial = [
              {
                assetCommon: [],
                addressOwners: [],
                lockStatus: [],
                monacardName: [],
                monacardIssuerNames: [],
                monacardDescription: [],
                monacardTags: [],
                monadom: [],
              },
            ];

            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditionsInitial });
            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: 0 });
          }}
        >
          {words.clear[state.language]}
        </button>
      </div>
    </div>
  );
}

// CART MONA POPUP
function CartMonaPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;


  return (
    <div className='flexColumn alignItemsCenter'>
      <div className='flexRow justifyContentCenter ' >
        {
          Object.keys(state.cartMona).map( addressFrom => {
            const actionItems = state.cartMona[addressFrom];
            return (
              <div className='flexColumn borderMonacotto margin0p5 ' >
                <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
                  <div className='margin0p5 ' >
                    from
                  </div>
                  <button className='backgroundColorTransparent borderNone margin0p5 '
                    onClick={ (event) => {
                      const actionItemsMultiSources = state.cartMona;
                      delete actionItemsMultiSources[addressFrom];
                      dispatch({ type: 'setState', key: 'cartMona', value: actionItemsMultiSources });

                      event.stopPropagation();
                    }}
                  >
                    <div className='font2 ' >
                      ×
                    </div>
                  </button>
                </div>
                <div className='margin0p5 ' >
                  { addressFrom }
                </div>
                <div className='' >
                  { actionItems.map( (dummy, index) => <ActionItemMona addressFrom={addressFrom} index={index} /> ) }
                </div>
              </div>
            );
          })
        }
      </div>
      {/* commit button */}
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter margin0p5 ' tabindex='0'
        onClick={ async　() => {
          const actionItemsMultiSources = state.cartMona;

          for (const addressFrom of Object.keys(actionItemsMultiSources)) {
            ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
              actionItem.status = 'unprocessed';
            }
          }

          const keyPairs = getValidKeyPairs(state, dispatch);

          dispatch({ type: 'setState', key: 'accessing', value: true });

          const actionItemsMultiSourcesSigned = await buildMonaTransactions(
            state, dispatch, keyPairs, actionItemsMultiSources,
            {
              provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
              transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
            }
          );

          devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

          dispatch({ type: 'setState', key: 'accessing', value: false });

          handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'mona');
        }}
      >
        <div>
          {words.send[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>
  );
}

// ACTION ITEM MONA
function ActionItemMona(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressFrom = props.addressFrom;
  const index = props.index;
  const actionItems = state.cartMona[addressFrom];
  const actionItem = state.cartMona[addressFrom]?.[index];
  let actionItemBox;

  if (actionItem.action === 'send') {
    actionItemBox =
    <div className='flexColumn borderMonacottoPale padding0p5 margin0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
        { words[actionItem.action][state.language] }
        <button className='backgroundColorTransparent borderNone '
          onClick={ (event) => {
            actionItems.splice(index, 1);
            // dispatch({ type: 'setStateMultiLayers', keys: ['cartMona', addressFrom], value: actionItems });
            const cartMona = state.cartMona;
            cleanUpObject(state, dispatch, cartMona)
            dispatch({ type: 'setState', key: 'cartMona', value: cartMona});

            event.stopPropagation();
          }}
        >
          <div className='font2 ' >
            ×
          </div>
        </button>
      </div>
      <div className='flexColumn ' >
        {
          (actionItem?.recipients || []).map( (recipient, indexRecipient) => {
            return (
              <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
                <div className='' >
                  { recipient.addressTo }
                </div>
                <div className='flexRow justifyContentFlexEnd alignItemsCenter ' >
                  <div className='marginLeft0p5 ' >
                    { new decimal(recipient.amountWatanabe).div(100000000).toNumber() }
                  </div>
                  <div className='marginLeft0p5 ' >
                    { 'MONA' }
                  </div>
                  <button className='backgroundColorTransparent borderNone '
                    onClick={ (event) => {
                      actionItem.recipients.splice(indexRecipient, 1);
                      // dispatch({ type: 'setStateMultiLayers', keys: ['cartMona', addressFrom, index, 'recipients'], value: actionItem.recipients });
                      const cartMona= state.cartMona;
                      cleanUpObject(state, dispatch, cartMona)

                      if (actionItem.recipients === undefined) {
                        state.cartMona[addressFrom]?.splice(index, 1);
                      }

                      cleanUpObject(state, dispatch, cartMona)
                      dispatch({ type: 'setState', key: 'cartMona', value: cartMona});

                      event.stopPropagation();
                    }}
                  >
                    <div className='font2 ' >
                      ×
                    </div>
                  </button>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>;
  }

  return actionItemBox;
}


// ACTION ITEMS MULTI SOURCE MONA VIEW
function ActionItemsMultiSourceMonaView(props) {
  const [state, dispatch] = useContext(GlobalState);
  const actionItemsMultiSources = props.actionItemsMultiSources;

  // メッセージ

  let message;

  const success = Object.keys(actionItemsMultiSources).some( addressFrom =>
      actionItemsMultiSources[addressFrom].some( actionItem =>
        actionItem.statusDetail === 'signed'
      )
  )

  const fail = Object.keys(actionItemsMultiSources).some( addressFrom =>
      actionItemsMultiSources[addressFrom].some( actionItem =>
        actionItem.status === 'failed'
      )
  )

  const unprocessed = Object.keys(actionItemsMultiSources).reduce( (acc, cur) =>
    acc + actionItemsMultiSources[cur].filter( actionItem =>
      actionItem.status === 'unprocessed'
    ).length,
    0
  )

  if (success && fail) {
    message = words.doYouWantToProceedAlreadyFailedWillNot[state.language];
  }
  else if (success) {
    message = words.doYouWantToProceed[state.language];
  }
  else { // fail
    if (unprocessed >= 1) {
      message = words.failedToBuildTransactionOkOrCancel[state.language];
    }
    else {
      message = words.failedToBuildTransaction[state.language];
    }
  }


  return (
    <div>
      <div className='margin1 preWrap'>
        { message }
      </div>
      <div className='flexRow justifyContentCenter ' >
        {
          Object.keys(actionItemsMultiSources).map( addressFrom => {
            const actionItems = actionItemsMultiSources[addressFrom];

            if (actionItems.filter( actionItem => actionItem.statusDetail === 'signed' || actionItem.status === 'failed' ).length === 0) {
              return null;
            }

            return (
              <div className='flexColumn borderMonacotto margin0p5 ' >
                <div className='flexRow justifyContentFlexStart alignItemsCenter ' >
                  <div className='margin0p5 ' >
                    from
                  </div>
                </div>
                <div className='margin0p5 ' >
                  { addressFrom }
                </div>
                <div className='flexColumn alignItemsCenter padding0p5 ' >
                  {
                    actionItems
                    // .filter( actionItem => actionItem.statusDetail === 'signed' )
                    .map( actionItem => <ActionItemMonaView actionItem={actionItem} displayType='confirmation' /> )
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

// ACTION ITEM MONA VIEW
function ActionItemMonaView(props) {
  const [state, dispatch] = useContext(GlobalState);
  const actionItem = props.actionItem;
  const displayType = props.displayType;
  let actionItemBox;
  
  if (actionItem.status === 'unprocessed') {
    return null;
  }

  // if (actionItem.action === 'send' || actionItem.action === 'issue') {
    let status;
    let statusDetail;
    let addressSource;

    if (displayType === 'confirmation') {
      if (actionItem.status === 'unprocessed' || actionItem.statusDetail === 'broadcasted') {
        return null;
      }

      if (actionItem.status === 'failed') {
        status =
        <div className='' >
          { words[actionItem.status][state.language] }
        </div>;

        statusDetail =
        <div className='' >
          { actionItem.statusDetail }
        </div>;
      }
    }
    else { // history
      if (actionItem.status === 'succeeded' && actionItem.statusDetail !== 'broadcasted') {
        status =
        <div className='' >
          { words.canceled[state.language] }
        </div>;
      }
      else {
        status =
        <div className='' >
          { words[actionItem.status][state.language] }
        </div>;
      }

      statusDetail =
      <div className='' >
        { actionItem.statusDetail }
      </div>;

      if (actionItem.action === 'send') {
        addressSource =
        <div className='' >
          { 'from: ' +  actionItem.addressFrom }
        </div>;
      }
      else if (actionItem.action === 'issue') {
        addressSource =
        <div className='' >
          { 'owner: ' +  actionItem.addressIssuer }
        </div>;
      }
      else if (actionItem.action === 'editMonacard' || actionItem.action === 'additionalIssuance' || actionItem.action === 'lockIssuance' || actionItem.action === 'editAdvancedToken') {
        addressSource =
        <div className='' >
          { 'owner: ' +  actionItem.addressOwner }
        </div>;
      }
      else if (actionItem.action === 'transferOwnership') {
        addressSource =
        <div className='' >
          { 'current owner: ' +  actionItem.addressOwner }
        </div>;
      }
    }

    let subView;

    if (actionItem.transactionType === 'sendMona') {
      subView = <ActionItemMonaSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'sendToken') {
      subView = <ActionItemMonapartySubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'issue') {
      subView = <ActionItemIssuanceSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'editMonacard') {
      subView = <ActionItemEditMonacardSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'additionalIssuance') {
      subView = <ActionItemAdditionalIssuanceSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'lockIssuance') {
      subView = <ActionItemLockIssuanceSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'transferOwnership') {
      subView = <ActionItemTransferOwnershipSubView actionItem={actionItem} />;
    }
    else if (actionItem.transactionType === 'monaparty' && actionItem.action === 'editAdvancedToken') {
      subView = <ActionItemEditAdvancedTokenSubView actionItem={actionItem} />;
    }

    let transactionFee;

    if (actionItem.transactionFee !== undefined) {
      transactionFee =
      <div className='flexColumn alignItemsFlexStart ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.transactionFee[state.language] }
        </div>
        <div className='' >
          { new decimal(actionItem.transactionFee).div(100000000).toNumber() + ' MONA' }
        </div>
      </div>;
    }

    let expenseExcludedTransactionFee;

    if ( (actionItem.transactionType === 'sendToken' || actionItem.transactionType === 'monaparty') && actionItem.outValueExcludeChange !== undefined) {
      expenseExcludedTransactionFee =
      <div className='flexColumn alignItemsFlexStart ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.expenseExcludedTransactionFee[state.language] }
        </div>
        <div className='' >
          { new decimal(actionItem.outValueExcludeChange).div(100000000).toNumber() + ' MONA' }
        </div>
      </div>;
    }

    actionItemBox =
    <div className='flexColumn widthMax borderMonacottoPale padding0p5 ' >
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
        <div className='' >
          { words[actionItem.action][state.language] }
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter ' >
          { status }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTop1 ' >
        { statusDetail }
      </div>
      <div className='flexColumn alignItemsFlexStart marginTop1 ' >
        { addressSource }
      </div>
      <div className='flexColumn marginTopBottom1 ' >
        { subView }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter ' >
        { transactionFee }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5 ' >
        { expenseExcludedTransactionFee }
      </div>
    </div>;
  // }

  return actionItemBox;
}

// ACTION ITEM MONA SUB VIEW
function ActionItemMonaSubView(props) {
  const actionItem = props.actionItem;
  let actionItemBox;

  if (actionItem.action === 'send') {
    actionItemBox = actionItem.recipients.map( recipient => {
      return (
        <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
          <div className='' >
            { recipient.addressTo }
          </div>
          <div className='flexRow justifyContentFlexEnd alignItemsCenter ' >
            <div className='marginLeft0p5 ' >
              { new decimal(recipient.amountWatanabe).div(100000000).toNumber() }
            </div>
            <div className='marginLeft0p5 ' >
              { 'MONA' }
            </div>
          </div>
        </div>
      );
    })
  }

  return actionItemBox;
}

// ACTION ITEM MONAPARTY SUB VIEW
function ActionItemMonapartySubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  if (actionItem.action === 'send') {
    actionItemBox = Object.keys(actionItem.recipients).map( addressTo => {
      return (
        <div className='flexColumn alignItemsFlexEnd borderMonacottoPale padding0p5 margin0p5 ' >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
            <div className='' >
              { addressTo }
            </div>
          </div>
          <div className='flexColumn ' >
            {
              Object.keys(actionItem.recipients[addressTo]).map( asset => {
                return (
                  <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
                    <AssetNameBox asset={asset} />
                    <div className='marginLeft0p5 ' >
                      { quantityForDisplay(state, { asset }, actionItem.recipients[addressTo][asset].quantity) }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      );
    })
  }

  return actionItemBox;
}

// ACTION ITEM ISSUANCE SUB VIEW
function ActionItemIssuanceSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  let tokenIssuanceFee;

  if (actionItem.assetType === 'tokenNameSpecified') {
    tokenIssuanceFee = 50;
  }
  else if (actionItem.assetType === 'subAsset') {
    tokenIssuanceFee = 25;
  }
  else {
    tokenIssuanceFee = 0;
  }

  if (actionItem.action === 'issue') {
    actionItemBox =
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <div className='' >
          { actionItem.assetCommon }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.amountToBeIssued[state.language] }
        </div>
        <div className='' >
          { quantityForDisplay(state, actionItem) }
        </div>
      </div>
      <div className='marginBottom0p5 ' >
        <MonacardSection description={actionItem.description} />
      </div>
      <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
        <div className='marginBottom0p5 ' >
          { 'description' }
        </div>
        <div className='scrollY breakAll ' >
          { actionItem.description }
        </div>
      </div>
      <div className='' >
        { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
      </div>
      <div className='' >
        { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
      </div>
      <div className='' >
        { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
      </div>
      <div className='' >
        { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
      </div>
      <div className='flexColumn alignItemsFlexStart widthMax marginTop0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenIssuanceFee[state.language] }
        </div>
        <div className='' >
          { tokenIssuanceFee + ' XMP' }
        </div>
      </div>
    </div>;
  }

  return actionItemBox;
}

// ACTION ITEM EDIT MONACARD SUB VIEW
function ActionItemEditMonacardSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  if (actionItem.action === 'editMonacard') {
    actionItemBox =
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
      <div className='marginBottom0p5 ' >
        <MonacardSection description={actionItem.description} />
      </div>
      <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
        <div className='marginBottom0p5 ' >
          { 'description' }
        </div>
        <div className='scrollY breakAll ' >
          { actionItem.description }
        </div>
      </div>
      <div className='' >
        { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
      </div>
      <div className='' >
        { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
      </div>
      <div className='' >
        { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
      </div>
      <div className='' >
        { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
      </div>
    </div>;
  }

  return actionItemBox;
}

// ACTION ITEM ADDITIONAL ISSUANCE SUB VIEW
function ActionItemAdditionalIssuanceSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
    <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.tokenName[state.language] }
      </div>
      <AssetNameBox asset={actionItem.asset} />
    </div>
    <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.amountToBeIssued[state.language] }
      </div>
      <div className='' >
        { quantityForDisplay(state, actionItem) }
      </div>
    </div>
  </div>;

  return actionItemBox;
}

// ACTION ITEM LOCK ISSUANCE SUB VIEW
function ActionItemLockIssuanceSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
    <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.tokenName[state.language] }
      </div>
      <AssetNameBox asset={actionItem.asset} />
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM TRANSFER OWNERSHIP SUB VIEW
function ActionItemTransferOwnershipSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
    <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.tokenName[state.language] }
      </div>
      <AssetNameBox asset={actionItem.asset} />
    </div>
    <div className='flexColumn alignItemsFlexEnd ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.addressNewOwner[state.language] }
      </div>
      <div className='' >
        { actionItem.addressNewOwner }
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM EDIT ADVANCED TOKEN SUB VIEW
function ActionItemEditAdvancedTokenSubView(props) {
  const [state] = useContext(GlobalState);
  const actionItem = props.actionItem;
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5 ' >
    <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
      <div className='borderBottomMonacottoPale2Px ' >
        { words.tokenName[state.language] }
      </div>
      <AssetNameBox asset={actionItem.asset} />
    </div>
    <div className='marginBottom0p5 ' >
      <MonacardSection description={actionItem.description} />
    </div>
    <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
      <div className='marginBottom0p5 ' >
        { 'description' }
      </div>
      <div className='scrollY breakAll ' >
        { actionItem.description }
      </div>
    </div>
    <div className='' >
      { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
    </div>
    <div className='' >
      { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
    </div>
    <div className='' >
      { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
    </div>
    <div className='' >
      { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
    </div>
  </div>;


  return actionItemBox;
}

// ASSET NAME BOX
function AssetNameBox(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;

  let assetBox;

  if (state.assetInfo[asset]?.asset_longname) {
    assetBox =
    <div className='flexColumn alignItemsFlexEnd' >
      <div className='' >
        { state.assetInfo[asset].asset_longname }
      </div>
      <div className='' >
        { `[${asset}]` }
      </div>
    </div>
  }
  else {
    assetBox =
    <div className='' >
      <div className='' >
        { asset }
      </div>
    </div>
  }

  return assetBox;
}

// CART MONAPARTY POPUP
function CartMonapartyPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;


  return (
    <div className='flexColumn alignItemsCenter '>
      <div className='flexRow justifyContentCenter ' >
        {
          Object.keys(state.cartMonaparty).map( addressSource => {
            const actionItems = state.cartMonaparty[addressSource];
            return (
              <div className='flexColumn borderMonacotto margin0p5' >
                <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
                  <div className='margin0p5 ' >
                    from
                  </div>
                  <button className='backgroundColorTransparent borderNone '
                    onClick={ (event) => {
                      const actionItemsMultiSources = state.cartMonaparty;
                      delete actionItemsMultiSources[addressSource];
                      dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

                      event.stopPropagation();
                    }}
                  >
                    <div className='font2 ' >
                      ×
                    </div>
                  </button>
                </div>
                <div className='margin0p5 ' >
                  { addressSource }
                </div>
                <div className='' >
                  {
                    actionItems.map( (actionItem, index) => {
                      if (actionItem.action === 'send') {
                        return <ActionItemMonaparty addressFrom={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'issue') {
                        return <ActionItemMonapartyIssue addressIssuer={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'editMonacard') {
                        return <ActionItemMonapartyEditMonacard addressIssuer={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'additionalIssuance') {
                        return <ActionItemMonapartyAdditionalIssuance addressIssuer={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'lockIssuance') {
                        return <ActionItemMonapartyLockIssuance addressOwner={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'transferOwnership') {
                        return <ActionItemMonapartyTransferOwnership addressIssuer={addressSource} index={index} />;
                      }
                      else if (actionItem.action === 'editAdvancedToken') {
                        return <ActionItemMonapartyEditAdvancedToken addressIssuer={addressSource} index={index} />;
                      }
                      else {
                        return null;
                      }
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
      {/* commit button */}
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter margin0p5 ' tabindex='0'
        onClick={ async () => {
          const actionItemsMultiSources = state.cartMonaparty;
          const keyPairs = getValidKeyPairs(state, dispatch);

          for (const addressSource of Object.keys(actionItemsMultiSources)) {
            ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressSource]) {
              actionItem.status = 'unprocessed';
            }
          }

          const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
          devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

          handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
        }}
      >
        <div>
          {words.send[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt='' />
      </button>
    </div>
  );
}

// ACTION ITEM MONAPARTY
function ActionItemMonaparty(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressFrom = props.addressFrom;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressFrom];
  const actionItem = state.cartMonaparty[addressFrom]?.[index];
  let actionItemBox;

  if (actionItem.action === 'send') {
    actionItemBox =
    <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
        { words[actionItem.action][state.language] }
        <button className='backgroundColorTransparent borderNone '
          onClick={ (event) => {
            actionItems.splice(index, 1);
            // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressFrom], value: actionItems });
            const cartMonaparty = state.cartMonaparty;
            cleanUpObject(state, dispatch, cartMonaparty)
            dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

            event.stopPropagation();
          }}
        >
          <div className='font2 ' >
            ×
          </div>
        </button>
      </div>
      <div className='flexColumn ' >
        {
          Object.keys(actionItem.recipients || {}).map( addressTo => {
            return (
              <div className='flexColumn alignItemsFlexEnd borderMonacottoPale padding0p5 margin0p5 ' >
                <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
                  <div className='' >
                    { addressTo }
                  </div>
                  <button className='backgroundColorTransparent borderNone '
                    onClick={ (event) => {
                      delete actionItem.recipients[addressTo];
                      // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressFrom, index, 'recipients'], value: actionItem.recipients });
                      const cartMonaparty = state.cartMonaparty;
                      cleanUpObject(state, dispatch, cartMonaparty)

                      if (actionItem.recipients === undefined) {
                        state.cartMonaparty[addressFrom]?.splice(index, 1);
                      }

                      cleanUpObject(state, dispatch, cartMonaparty)
                      dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

                      event.stopPropagation();
                    }}
                  >
                    <div className='font2 ' >
                      ×
                    </div>
                  </button>
                </div>
                <div className='flexColumn ' >
                  {
                    Object.keys(actionItem.recipients[addressTo]).map( asset => {
                      return (
                        <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
                          <AssetNameBox asset={asset} />
                          <div className='flexRow justifyContentFlexEnd alignItemsCenter ' >
                            <div className='marginLeft0p5 ' >
                              { quantityForDisplay(state, { asset }, actionItem.recipients[addressTo][asset].quantity) }
                            </div>
                            <button className='backgroundColorTransparent borderNone '
                              onClick={ (event) => {
                                delete actionItem.recipients[addressTo][asset];
                                // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressFrom, index, 'recipients', addressTo], value: actionItem.recipients[addressTo] });
                                const cartMonaparty = state.cartMonaparty;
                                cleanUpObject(state, dispatch, cartMonaparty)

                                if (actionItem.recipients === undefined) {
                                  state.cartMonaparty[addressFrom]?.splice(index, 1);
                                }

                                cleanUpObject(state, dispatch, cartMonaparty)
                                dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

                                event.stopPropagation();
                              }}
                            >
                              <div className='font2 ' >
                                ×
                              </div>
                            </button>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>;
  }

  return actionItemBox;
}

// ACTION ITEM MONAPARTY ISSUE
function ActionItemMonapartyIssue(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressIssuer = props.addressIssuer;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressIssuer];
  const actionItem = state.cartMonaparty[addressIssuer][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressIssuer], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <div className='' >
          { actionItem.assetCommon }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.amountToBeIssued[state.language] }
        </div>
        <div className='' >
          { quantityForDisplay(state, actionItem) }
        </div>
      </div>
      <div className='marginBottom0p5 ' >
        <MonacardSection description={actionItem.description} />
      </div>
      <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
        <div className='marginBottom0p5 ' >
          { 'description' }
        </div>
        <div className='scrollY breakAll ' >
          { actionItem.description }
        </div>
      </div>
      <div className='' >
        { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
      </div>
      <div className='' >
        { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
      </div>
      <div className='' >
        { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
      </div>
      <div className='' >
        { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM MONAPARTY EDIT MONACARD
function ActionItemMonapartyEditMonacard(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressIssuer = props.addressIssuer;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressIssuer];
  const actionItem = state.cartMonaparty[addressIssuer][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale maxWidthMaxMinus1 padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressIssuer], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
      <div className='marginBottom0p5 ' >
        <MonacardSection description={actionItem.description} />
      </div>
      <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
        <div className='marginBottom0p5 ' >
          { 'description' }
        </div>
        <div className='scrollY breakAll ' >
          { actionItem.description }
        </div>
      </div>
      <div className='' >
        { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
      </div>
      <div className='' >
        { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
      </div>
      <div className='' >
        { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
      </div>
      <div className='' >
        { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM MONAPARTY ADDITIONAL ISSUANCE
function ActionItemMonapartyAdditionalIssuance(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressIssuer = props.addressIssuer;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressIssuer];
  const actionItem = state.cartMonaparty[addressIssuer][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressIssuer], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.amountToBeIssued[state.language] }
        </div>
        <div className='' >
          { quantityForDisplay(state, actionItem) }
        </div>
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM MONAPARTY LOCK ISSUANCE
function ActionItemMonapartyLockIssuance(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressOwner = props.addressOwner;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressOwner];
  const actionItem = state.cartMonaparty[addressOwner][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressOwner], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM MONAPARTY TRANSFER OWNERSHIP
function ActionItemMonapartyTransferOwnership(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressIssuer = props.addressIssuer;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressIssuer];
  const actionItem = state.cartMonaparty[addressIssuer][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressIssuer], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
      <div className='flexColumn alignItemsFlexEnd ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.addressNewOwner[state.language] }
        </div>
        <div className='' >
          { actionItem.addressNewOwner }
        </div>
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// ACTION ITEM MONAPARTY EDIT ADVANCED TOKEN
function ActionItemMonapartyEditAdvancedToken(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const addressIssuer = props.addressIssuer;
  const index = props.index;
  const actionItems = state.cartMonaparty[addressIssuer];
  const actionItem = state.cartMonaparty[addressIssuer][index];
  let actionItemBox;

  actionItemBox =
  <div className='flexColumn borderMonacottoPale padding0p5 margin0p5 ' >
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter ' >
      { words[actionItem.action][state.language] }
      <button className='backgroundColorTransparent borderNone '
        onClick={ (event) => {
          actionItems.splice(index, 1);
          // dispatch({ type: 'setStateMultiLayers', keys: ['cartMonaparty', addressIssuer], value: actionItems });
          const cartMonaparty = state.cartMonaparty;
          cleanUpObject(state, dispatch, cartMonaparty)
          dispatch({ type: 'setState', key: 'cartMonaparty', value: cartMonaparty });

          event.stopPropagation();
        }}
      >
        <div className='font2 ' >
          ×
        </div>
      </button>
    </div>
    <div className='flexColumn alignItemsFlexEnd marginTopBottom0p5' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.tokenName[state.language] }
        </div>
        <AssetNameBox asset={actionItem.asset} />
      </div>
      <div className='marginBottom0p5 ' >
        <MonacardSection description={actionItem.description} />
      </div>
      <div className='flexColumn alignItemsFlexStart box4 box1Width flexWrapNoWrap padding0p5 marginBottom0p5 ' >
        <div className='marginBottom0p5 ' >
          { 'description' }
        </div>
        <div className='scrollY breakAll ' >
          { actionItem.description }
        </div>
      </div>
      <div className='' >
        { actionItem.divisible ? words.divisible[state.language] : words.notDivisible[state.language] }
      </div>
      <div className='' >
        { actionItem.listed ? words.listed[state.language] : words.notListed[state.language] }
      </div>
      <div className='' >
        { actionItem.vendable ? words.vendable[state.language] : words.notVendable[state.language] }
      </div>
      <div className='' >
        { actionItem.reassignable ? words.reassignable[state.language] : words.notReassignable[state.language] }
      </div>
    </div>
  </div>;


  return actionItemBox;
}

// MONACARD SECTION
function MonacardSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const description = props.description;

  let descriptionObj;

  try {
    descriptionObj = JSON.parse(description);
  }
  catch (error) {
    // 何もしない
  }

  let monacardSection;

  if (
    descriptionObj?.monacard?.name !== undefined && descriptionObj.monacard.name !== null && descriptionObj.monacard.name !== '' &&
    descriptionObj?.monacard?.owner !== undefined && descriptionObj.monacard.owner !== null && descriptionObj.monacard.owner !== '' &&
    descriptionObj?.monacard?.desc !== undefined && descriptionObj.monacard.desc !== null && descriptionObj.monacard.desc !== '' &&
    descriptionObj?.monacard?.cid !== undefined && descriptionObj.monacard.cid !== null && descriptionObj.monacard.cid !== ''
  ) {
    monacardSection =
    <div className='flexColumn alignItemsFlexEnd ' >
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.monacardName[state.language] }
        </div>
        <div className='' >
          { descriptionObj.monacard.name }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.issuerName[state.language] }
        </div>
        <div className='' >
          { descriptionObj.monacard.owner }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexEnd marginBottom0p5 ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.monacardDescription[state.language] }
        </div>
        <div className='' >
          { descriptionObj.monacard.desc }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexEnd ' >
        <div className='borderBottomMonacottoPale2Px ' >
          { words.monacardTag[state.language] }
        </div>
        <div className='' >
          { descriptionObj.monacard.tag }
        </div>
      </div>
    </div>;
  }


  return monacardSection;
}

/*
// CONDITION DIVISION
function ConditionDivision(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const condition = props.condition;
  const conditionKey = props.conditionKey;

  if (condition[conditionKey].length <= 0) {
    return null;
  }

  return (
    <div className='flexColumn alignItemsCenter borderMonacottoPale margin0p5'>
      <div className='flexColumn margin0p5 ' >
        { conditionKey }
      </div>
      {
        condition[conditionKey].map( value =>
          <ValueForDisplayInList popupLayer={popupLayer} conditionKey={conditionKey} value={value} />
        )
      }
    </div>
  );
}
*/

// CONDITION SECTION
function ConditionSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const condition = props.condition;
  const conditionKey = props.conditionKey;

  if (condition[conditionKey].length <= 0) {
    return null;
  }

  return (
    <div className='flexColumn alignItemsCenter borderMonacottoPale margin0p5'>
      <div className='flexColumn margin0p5 ' >
        { words[conditionKey][state.language] }
      </div>
      {
        condition[conditionKey].map( value =>
          <ValueForDisplayInList popupLayer={popupLayer} conditionKey={conditionKey} value={value} />
        )
      }
    </div>
  );
}

// VALUE FOR DISPLAY IN LIST
function ValueForDisplayInList(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const conditionKey = props.conditionKey;
  const value = props.value;

  if (conditionKey === 'addressOwners') {
    return (
      <button className='backgroundColorMonacottoPale borderNone margin0p5 '
        onClick={ () => {
          const body = value;
          const popup = { type: 'generalItems', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
        }}
      >
        { value.substring(value.length - 6) }
      </button>
    );
  }
  else {
    return (
      <button className='backgroundColorMonacottoPale borderNone margin0p5 ' >
        { value }
      </button>
    );
  }
}

// ACTION HISTORY
function ActionHistory(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  const records = state.actionHistory.map( actionItem => {
    let txid;

    if (actionItem.txid !== undefined) {
      txid =
      <div className='margin0p5 '>
        { 'TXID: ' + actionItem.txid }
      </div>
    }

    return (
      <div className='widthMax '>
        {/* PC */}
        <div className='visibleMiddleOrMore boxMonacotto1 flexColumn justifyContentCenter alignItemsFlexStart padding0p5 margin0p5' >
          {/*
            <div className='margin0p5 '>
              { actionItem.statusDetail }
            </div>
          */}
          <div className='breakAll' >
            { txid }
          </div>
          <ActionItemMonaView actionItem={actionItem} displayType='history' />
        </div>
        {/* SP */}
        <div className='visibleSmallOrLess boxMonacotto1SmallScreen flexColumn justifyContentCenter alignItemsFlexStart padding0p5 ' >
          {/*
            <div className='margin0p5 '>
              { actionItem.statusDetail }
            </div>
          */}
          <div className='breakAll' >
            { txid }
          </div>
          <ActionItemMonaView actionItem={actionItem} displayType='history' />
        </div>
      </div>
    );
  });


  return (
    <div className='width98vw '>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <div className='flexRow ' >
            <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
              { words.back[state.language] }
            </button>
          </div>
        </div>
        <div className='flexColumn alignItemsFlexStart ' >
          { records }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn widthMax ">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        { records }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// ISSUANCE
function Issuance(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  const resetParent = (addressIssuer) => {
    if ( state.ownedAssets[addressIssuer] !== undefined ) {
      if ( !state.ownedAssets[addressIssuer].includes(state.issuance.parent) ) {
        const parentDefault = state.ownedAssets[addressIssuer]
        .filter( asset => !asset.asset.startsWith('A') )
        .map( asset => {
          return { value: asset.asset, label: asset.asset };
        })[0];

        dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'parent'], value: parentDefault.value });
      }
      else {
        // 何もしない。
      }
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'parent'], value: '' });
    }
  };

  useEffect( () => {
    getAssetNames(state, dispatch);
    resetParent(state.issuance.addressIssuer);
  }, []);

  const issueModeOption = [
    { value: 'issueMonacard', label: words.issueMonacard[state.language] },
    { value: 'issueAdvancedToken', label: words.issueAdvancedToken[state.language] },
  ];

  const issueModeDropdown =
  <select className="custom-dropdown" value={state.issuance.issueMode}
    onChange={ event => {
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'issueMode'], value: event.target.value });
    }}
  >
    {
      issueModeOption.map( option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))
    }
  </select>

  const assetTypeOption = [
    { value: 'tokenNameLeftToTheSystem', label: words.tokenNameLeftToTheSystem[state.language] },
    { value: 'tokenNameSpecified', label: words.tokenNameSpecified[state.language] },
    { value: 'subAsset', label: words.subAsset[state.language] },
  ];

  const assetTypeDropdown =
  <select className="custom-dropdown" value={state.issuance.assetType}
    onChange={ event => {
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'assetType'], value: event.target.value });
    }}
  >
    {
      assetTypeOption.map( option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))
    }
  </select>

  const dispatchDescription = (key, value) => {
    const description = {
      monacard: {
          name: state.issuance.monacardName,
          owner: state.issuance.monacardIssuerName,
          desc: state.issuance.monacardDescription,
          tag: state.issuance.monacardTag,
          cid: '',
          ver: '2',
      }
    };

    description.monacard[key] = value;

    dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'description'], value: JSON.stringify(description) });
  };

  const getExtraFunctions = (key) => {
    return [
      {
        function: dispatchDescription,
        arguments: [ key ],
        targetValue: true,
      }
    ];
  };

  // 画像セクション

  let image;

  if (state.issuance.imagesNew.monacardUrl !== undefined && state.issuance.imagesNew.monacardUrl !== null) {
    image = <img className='monacardImageAbsolute' src={state.issuance.imagesNew.monacardUrl} alt='' />;
  }

  const onDropAccepted = useCallback((acceptedFiles) => {
    devLog('acceptedFiles', acceptedFiles);
    dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'imagesNew', 'monacard'], value: acceptedFiles[0] });
    const dataUrl = URL.createObjectURL(acceptedFiles[0]);
    devLog('dataUrl', dataUrl);
    dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'imagesNew', 'monacardUrl'], value: dataUrl });
  }, []);

  const maxFiles = 1;
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
  };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({ onDropAccepted, maxFiles, accept });

  const dropZoneStyle = {
    width: '272px',
    height: '388px',
    border: "1px dotted #888",
  };

  const imageSection =
  <div className='relative margin1 '>
      <div {...getRootProps()} style={dropZoneStyle} >
        <input {...getInputProps} style={{ display: 'none' }} />
        <div className='padding0p5'>
          { words.monacardImageSpecifications[state.language] }<br/>
        </div>
        <div className='padding0p5'>
          { words.dragAndDrop[state.language] }<br/>
        </div>
      </div>
      { image }
  </div>;

  const inputImageFile = useRef(null);

  const imageSectionSp =
  <div>
    <input ref={inputImageFile} className='invisible' type='file' accept='image/png, .png, image/jpeg, .jpg, .jpeg, image/gif, .gif' 
      onChange={ (e) => {
        devLog('acceptedFiles', JSON.stringify(e.currentTarget.files[0].name));
        dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'imagesNew', 'monacard'], value: e.currentTarget.files[0] });
        const dataUrl = URL.createObjectURL(e.currentTarget.files[0]);
        devLog('dataUrl', dataUrl);
        dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'imagesNew', 'monacardUrl'], value: dataUrl });
      }}
    />
    <button className='monacardImageContainer flexColumn justifyContentFlexStart margin1 ' onClick={ () => { inputImageFile.current.click(); } }>
      <div className='padding0p5 textLeft'>
        { words.monacardImageSpecifications[state.language] }<br/>
      </div>
      <div className='padding0p5 textLeft'>
        { words.tap[state.language] }<br/>
      </div>
      { image }
    </button>
  </div>;

  // トークン名セクション

  let assetCommonSection;
  let parentOption;

  if (state.ownedAssets[state.issuance.addressIssuer] !== undefined) {
    parentOption = state.ownedAssets[state.issuance.addressIssuer]
    .filter( asset => !asset.asset.startsWith('A') )
    .map( asset => {
      return { value: asset.asset, label: asset.asset };
    });
  }
  else {
    parentOption = [];
  }

  const parentDropdown =
  <select className="custom-dropdown" value={state.issuance.parent}
    onChange={ event => {
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'parent'], value: event.target.value });
    }}
  >
    {
      parentOption.map( option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))
    }
  </select>

  if (state.issuance.assetType === 'tokenNameSpecified') {
    assetCommonSection =
    <TextLine3 fieldName='issuanceAssetCommon' keys={['issuance', 'assetCommon']} face={words.assetCommon[state.language]} tooltip={true} />;
  }
  else if (state.issuance.assetType === 'subAsset') {
    assetCommonSection =
    <div className='flexColumn alignItemsCenter ' >
      { parentDropdown }
      <TextLine3 fieldName='issuanceSubAsset' keys={['issuance', 'subAsset']} face={words.subAsset[state.language]} tooltip={true} />
    </div>;
  }

  // アドバンストセクション

  let advancedSection;

  if (state.issuance.issueMode === 'issueAdvancedToken') {
    advancedSection =
    <div className='flexColumn alignItemsCenter ' >
      <TextArea2 fieldName='issuanceDescription' keys={['issuance', 'description']} face='description'
        outerClass='box1Width' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1'
      />
      <div className='flexColumn alignItemsFlexStart ' >
        {/*
          <button className='flexRow justifyContentCenter backgroundColorTransparent borderNone font1 marginTopBottom0p5 '
            onClick={ () => {
              dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'lock'], value: !state.issuance.lock });
            }}
          >
            <div className={'width1 height1 borderMonacottoNoRadius marginSide0p5 ' + (state.issuance.lock ? 'backgroundColorPinkPale' : 'backgroundColorTransparent')} >
            </div>
            <div className='font1 marginSide0p5' >
              { 'lock' }
            </div>
          </button>
        */}
        <button className='flexRow justifyContentCenter backgroundColorTransparent borderNone font1 marginTopBottom0p5 '
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'divisible'], value: !state.issuance.divisible });
          }}
        >
          <div className={'width1 height1 borderMonacottoNoRadius marginSide0p5 ' + (state.issuance.divisible ? 'backgroundColorPinkPale' : 'backgroundColorTransparent')} >
          </div>
          <div className='font1 marginSide0p5' >
            { 'divisible' }
          </div>
        </button>
        <button className='flexRow justifyContentCenter backgroundColorTransparent borderNone font1 marginTopBottom0p5 '
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'listed'], value: !state.issuance.listed });
          }}
        >
          <div className={'width1 height1 borderMonacottoNoRadius marginSide0p5 ' + (state.issuance.listed ? 'backgroundColorPinkPale' : 'backgroundColorTransparent')} >
          </div>
          <div className='font1 marginSide0p5' >
            { 'listed' }
          </div>
        </button>
        <button className='flexRow justifyContentCenter backgroundColorTransparent borderNone font1 marginTopBottom0p5 '
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'vendable'], value: !state.issuance.vendable });
          }}
        >
          <div className={'width1 height1 borderMonacottoNoRadius marginSide0p5 ' + (state.issuance.vendable ? 'backgroundColorPinkPale' : 'backgroundColorTransparent')} >
          </div>
          <div className='font1 marginSide0p5' >
            { 'vendable' }
          </div>
        </button>
        <button className='flexRow justifyContentCenter backgroundColorTransparent borderNone font1 marginTopBottom0p5 '
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'reassignable'], value: !state.issuance.reassignable });
          }}
        >
          <div className={'width1 height1 borderMonacottoNoRadius marginSide0p5 ' + (state.issuance.reassignable ? 'backgroundColorPinkPale' : 'backgroundColorTransparent')} >
          </div>
          <div className='font1 marginSide0p5' >
            { 'reassignable' }
          </div>
        </button>
      </div>
    </div>;
  }

  // コミットボタン

  let commitButtonWord;

  if (state.issuance.issueMode === 'issueMonacard') {
    commitButtonWord = words.issueMonacard[state.language];
  }
  else if (state.issuance.issueMode === 'issueAdvancedToken') {
    commitButtonWord = words.issueToken[state.language];
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <div className='flexRow ' >
            <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
              { words.back[state.language] }
            </button>
          </div>
        </div>
        <div className='marginBottom0p5 ' >
          { issueModeDropdown }
        </div>
        <div className='' >
          { assetTypeDropdown }
        </div>
        { imageSection }
        <TextLine3 fieldName='issuanceAddressIssuer' keys={['issuance', 'addressIssuer']} face={words.addressIssuer[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: (addressIssuer) => resetParent(addressIssuer),
              arguments: [],
              targetValue: true,
            }
          ]}
        />
        { assetCommonSection }
        <TextLine3 fieldName='issuanceAmount' keys={['issuance', 'amount']} face={words.amountToBeIssued[state.language]} type='setStateMultiLayersFloat' tooltip={true} />
        <TextLine3 fieldName='issuanceMonacardName' keys={['issuance', 'monacardName']} face={words.monacardName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('name')} />
        <TextLine3 fieldName='issuanceMonacardIssuerName' keys={['issuance', 'monacardIssuerName']} face={words.issuerName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('owner')} />
        <TextLine3 fieldName='issuanceMonacardDescription' keys={['issuance', 'monacardDescription']} face={words.monacardDescription[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('desc')} />
        <TextLine3 fieldName='issuanceMonacardTag' keys={['issuance', 'monacardTag']} face={words.monacardTag[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('tag')} />
        { advancedSection }
        <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
          onClick={ async () => {
            handleClickIssueAsset(state, dispatch);
          }}
        >
          <div>
            { commitButtonWord }
          </div>
          <img className='size2x2' src={iconSign} alt='' />
        </button>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='marginBottom0p5 ' >
          { issueModeDropdown }
        </div>
        <div className='' >
          { assetTypeDropdown }
        </div>
        { imageSectionSp }
        <TextLine3 fieldName='issuanceAddressIssuer' keys={['issuance', 'addressIssuer']} face={words.addressIssuer[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: (addressIssuer) => resetParent(addressIssuer),
              arguments: [],
              targetValue: true,
            }
          ]}
        />
        { assetCommonSection }
        <TextLine3 fieldName='issuanceAmount' keys={['issuance', 'amount']} face={words.amountToBeIssued[state.language]} type='setStateMultiLayersFloat' tooltip={true} />
        <TextLine3 fieldName='issuanceMonacardName' keys={['issuance', 'monacardName']} face={words.monacardName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('name')} />
        <TextLine3 fieldName='issuanceMonacardIssuerName' keys={['issuance', 'monacardIssuerName']} face={words.issuerName[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('owner')} />
        <TextLine3 fieldName='issuanceMonacardDescription' keys={['issuance', 'monacardDescription']} face={words.monacardDescription[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('desc')} />
        <TextLine3 fieldName='issuanceMonacardTag' keys={['issuance', 'monacardTag']} face={words.monacardTag[state.language]} tooltip={true} extraFunctionOnChange={getExtraFunctions('tag')} />
        { advancedSection }
        <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
          onClick={ async () => {
            handleClickIssueAsset(state, dispatch);
          }}
        >
          <div>
            { commitButtonWord }
          </div>
          <img className='size2x2' src={iconSign} alt='' />
        </button>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}


// POPUP
function Popup(props) {
  const [state, dispatch] = useContext(GlobalState);

  const popup = state.popup[props.layer];

  const popupComponent = {
    generalList: <GeneralList popupLayer={props.layer} />,
    generalItems: <GeneralItems popupLayer={props.layer} />,
    confirmationPopup: <ConfirmationPopup popupLayer={props.layer} />,
    itemDetail: <ItemDetail popupLayer={props.layer} />,
    itemDetailMore: <ItemDetailMore popupLayer={props.layer} />,
    monadomInfo: <MonadomInfo popupLayer={props.layer} />,
    userRegistrationPopup: <UserRegistrationPopup popupLayer={props.layer} />,
    loginPopup: <LoginPopup popupLayer={props.layer} />,
    menuPopup: <MenuPopup popupLayer={props.layer} />,
    selectSessionPopup: <SelectSessionPopup popupLayer={props.layer} />,
    viewSessionPopup: <ViewSessionPopup popupLayer={props.layer} />,
    sendMonaPopoup: <SendMonaPopup popupLayer={props.layer} />,
    sendXmpPopoup: <SendXmpPopup popupLayer={props.layer} />,
    sendTokenPopup: <SendTokenPopup popupLayer={props.layer} />,
    editAssetInfoPopup: <EditAssetInfoPopup popupLayer={props.layer} />,
    tokenFilterPopup: <TokenFilterPopup popupLayer={props.layer} />,
    cartMonaPopup: <CartMonaPopup popupLayer={props.layer} />,
    cartMonapartyPopup: <CartMonapartyPopup popupLayer={props.layer} />,
    displayQrPopup: <DisplayQrPopup popupLayer={props.layer} />,
    creatorInformation: <CreatorInformation popupLayer={props.layer} />,
  };

  if (state.popup[props.layer]?.type === undefined || state.popup[props.layer].type === null) {
    return (
      <div className='invisible' />
    );
  }
  else {
    return (
      <div>
        {/* PC */}
        <div className="visibleMiddleOrMore">
          <div className={'popupBackgroundMultiLayer' + (popup.extendedClassesBackGround !== undefined ? ' ' + popup.extendedClassesBackGround : '')} style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayer' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose font2 focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                ×
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
        {/* SP */}
        <div className="visibleSmallOrLess">
          <div className='popupBackgroundMultiLayer' style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayerSmallScreen ' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose font2 focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                ×
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // else {
  //   return (
  //     <div className='' >
  //     </div>
  //   );
  // }
}


// GENERAL LIST
function GeneralList(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { body.options }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.options }
        </div>
      </div>
    </div>
  );
}

// GENERAL ITEMS
function GeneralItems(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className='flexColumn alignItemsCenter'>
      { body }
    </div>
  );
}

// CONFIRMATION POPUP
function ConfirmationPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const message = state.popup[popupLayer].body.message;
  const buttonFaceOk = state.popup[popupLayer].body.buttonFaceOk;
  const buttonFaceCancel = state.popup[popupLayer].body.buttonFaceCancel;
  const callbackOk = state.popup[popupLayer].body.callbackOk;
  const callbackCancel = state.popup[popupLayer].body.callbackCancel;

  return (
    <div className='flexColumn alignItemsCenter'>
      <div className='flexColumn alignItemsCenter margin1 '>
        { message }
      </div>
      <div className='flexRow justifyContentCenter margin1 '>
        <button className='button2 margin1 ' onClick={ callbackOk } >
          { buttonFaceOk }
        </button>
        <button className='button2 margin1 ' onClick={ callbackCancel } >
          { buttonFaceCancel }
        </button>
      </div>
    </div>
  );
}

// GENERAL OPTION
function GeneralOption(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const recordKey = props.recordKey;
  const sortedKeys = props.sortedKeys;
  const stateKeys = props.stateKeys;
  const layer = props.layer;
  let value;

  if (Object.keys(record).length === 1) {
    if (Object.values(record)[0].value !== undefined) {
      value = Object.values(record)[0].value;
    }
    else {
      value = Object.keys(record)[0];
    }
  }
  else {
    if (record[recordKey].value !== undefined) {
      value = record[recordKey].value;
    }
    else {
      value = recordKey;
    }
  }

  const factors = sortedKeys.map( key => {
    if (record[key].face !== undefined) {
      return <div className={record[key].style} >
        { record[key].face }
      </div>;
    }
    else {
      return <div className={record[key].style} >
        { key }
      </div>;
    }
  });


  return (
    <div className=''>
      {/* PC */}
      <button className='visibleMiddleOrMore boxMonacotto1 margin0p5 cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: value });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        { factors }
      </button>
      {/* SP */}
      <button className='visibleSmallOrLess boxMonacotto1SmallScreen cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: value });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        { factors }
      </button>
    </div>
  );
}

// FOR DEVELOPMENT
function ForDevelopment() {
  const [state] = useContext(GlobalState);

  return (
    <div className='invisibleSp' >
        <hr/>
        <div className='widthVW'>
          {'<<active>> ' + JSON.stringify(state.active)}<br/>
          {'<<session>> ' + JSON.stringify(state.session)}<br/>
          {'<<configure>> ' + JSON.stringify(state.configure)}<br/>
          {'<<balance>> ' + JSON.stringify(state.balance)}<br/>
          {'<<assetInfo>> ' + JSON.stringify(state.assetInfo)}<br/>
          {'<<monacard>> ' + JSON.stringify(state.monacard)}<br/>
          {'<<config>> ' + JSON.stringify(state.config)}<br/>
          {'<<configMP>> ' + JSON.stringify(state.configMP)}<br/>
          {'<<popup>> ' + JSON.stringify(state.popup)}<br/>
        </div>
    </div>
  );
}


// TEXT LINE MULTI LAYERS STATE ON PRESS KEY
function TextLine3(props) {
  const [state, dispatch] = useContext(GlobalState);
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  let value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  if (type === 'setStateMultiLayersFloat') {
    value = value.face;
  }

  let boxClass;

  if (props.boxClass !== undefined) {
    boxClass = props.boxClass;
  }
  else {
    boxClass = 'box1 widthWithButton1NarrowSp';
  }     

  let textLineClass;

  if (props.textLineClass !== undefined) {
    textLineClass = props.textLineClass;
  }
  else {
    textLineClass = 'textLine1';
  }     

  let tooltipClass;

  if (props.tooltip === 'left') {
    tooltipClass = 'tooltipLeft breakAll'
  }
  else if (props.tooltip === 'leftLong') {
    tooltipClass = 'tooltipLeft'
  }
  else if (props.tooltip === 'right') {
    tooltipClass = 'tooltipRight breakAll'
  }
  else if (props.tooltip === 'rightLong') {
    tooltipClass = 'tooltipRight'
  }
  else if (props.tooltip === false) {
    tooltipClass = 'invisible'
  }
  else if (props.tooltip !== undefined) {
    tooltipClass = 'tooltipRight'
  }
  else {
    tooltipClass = 'invisible'
  }

  let disabled;

  if (props.disabled) {
    disabled = true;
  }
  else {
    disabled = false;
  }

  return (
    <div>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className={boxClass}
              onMouseEnter={ () => {
                if (props.tooltip !== undefined && value !== undefined && value !== null && value !== '') {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=true; 
                }
              }}
              onMouseLeave={ () => {
                if (props.tooltip !== undefined) {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                }
              }}
            >
              <input className={textLineClass} type="text" value={value} placeholder='' disabled={disabled}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 

                  if (type === 'setStateMultiLayersFloat') {
                    dispatch({type: 'adjustFace', keys: props.keys});
                  }
                }}
                onChange={ (e) => {
                  let targetValueAdjusted = e.target.value;

                  if (type === 'setStateMultiLayersFloat') {
                    targetValueAdjusted = adjustFloat(props.adjustType, e.target.value, props.adjustExp);
                  }

                  dispatch({type: type, keys: props.keys, value: e.target.value, adjustType: props.adjustType, adjustExp: props.adjustExp});

                  if (props.extraDispatch !== undefined) {
                    for (const aDispatch of props.extraDispatch) {
                      dispatch({type: aDispatch.type, keys: aDispatch.keys, value: e.target.value});
                    }
                  }

                  if (props.extraFunctionOnChange !== undefined) {
                    for (const aFunction of props.extraFunctionOnChange) {
                      let argumentsRevised = aFunction.arguments;

                      if (aFunction.targetValue !== undefined) {
                        argumentsRevised.splice(aFunction.targetValue === true ? argumentsRevised.length : aFunction.targetValue, 0, targetValueAdjusted);
                      }

                      devLog('argumentsRevised', argumentsRevised);
                      aFunction.function(...argumentsRevised);
                    }
                  }

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onKeyPress={ (e) => {
                  if (e.which === props.keyNo) {
                    if (props.onKeyPressFunction !== undefined) {
                      props.onKeyPressFunction();
                    }
                  }
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === undefined || value === '' || value === null ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Tooltip'} type='checkbox' />
              <div className={tooltipClass} >
                {value}
              </div>
            </div>
    </div>
  );
}

// TEXT AREA MULTI LAYERS STATE
function TextArea2(props) {
  const [state, dispatch] = useContext(GlobalState);
  const value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  return (
    <div className={'flexColumn alignItemsCenter ' + props.outerClass}>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className={props.boxClass}>
              <textarea className={props.textAreaClass} value={value}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 
                }}
                onChange={ (e) => {
                  dispatch({type: type, keys: props.keys, value: e.target.value});
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === '' ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
            </div>
    </div>
  );
}

// NOTIFICATION FADING OUT
function NotificationFadingOut() {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    return () => {
      dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
    };
  }, []);

  return (
    <CSSTransition in={state.notification.inAnimation} timeout={5000} classNames="messageFade"
      onEntered={ () => {
                    dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
                  }
               }
    >
      <div className={state.notification.inAnimation ? 'maxWidth98vw' : 'invisible'} >
          { state.notification.body[state.notification.index] }
      </div>
    </CSSTransition>
  );
}

// ROLLING PEARL
function RollingPearl() {
  const [state] = useContext(GlobalState);

  let magic;

  if (state.accessing) {
    magic = <div className='pearlRollingCenter' />;
  }
  else {
    magic = null;
  }

  return (
    <div>
      { magic }
    </div>
  );
}



// ---------------------------

// HANDLE CLICK GO TOP
function handleClickGoTop(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top');
}

// HANDLE CLICK SETTING
function handleClickSetting(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/setting' : '/setting');
}


// HANDLE CLICK MPURSE
async function handleClickMpurse(state, dispatch, stateKeys, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  devLog('handleClickMpurse', JSON.stringify(stateKeys));

  const callback = (keyPairs) => {
    const keyPair = keyPairs[0];
    const result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      const address= result.address;
      dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: address });
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected'};
    }

    // keepKeyPairsInMemory(state, dispatch, keyPairs);
  };

  if (state.walletType === 'mpurse') {
    if (window.mpurse) {
      const result = await window.mpurse.getAddress()
      .then( (result) => {
        dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: result });

        return {
          status: 'fulfilled',
          body: result,
        };
      })
      .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });

        return {
          status: 'rejected',
        };
      });

      return result;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });

      return {
        status: 'rejected',
      };
    }
  }
  else if (state.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    handleClickNfc(state, dispatch, callback);
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
  }
}

// 「設定」ボタン押下 HANDLE CLICK SETUP
async function handleClickSetup(state, dispatch, keyPairs) {
  let addressMainActual;
  let message;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    addressMain: state.configure.addressMain,
    userName: state.configure.userName,
    // profileText: state.configure.profileText,
    // imageMain: state.configure.imagesNew.main,
    acceptedVersionOfTheTermsAndConditions: state.configure.acceptedVersionOfTheTermsAndConditions,
    acceptedVersionOfPrivacyPolicy: state.configure.acceptedVersionOfPrivacyPolicy,
    signatureVersion: state.config.clientParameters.signatureVersion.configure,
    // addressCheck: state.addressCheck,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // if (keyPairs !== undefined && keyPairs !== null) {
  //   keyPair = keyPairs[0];
  //   const result = getAddressFromKey(state, dispatch, keyPair);

  //   if (result.status === 'fulfilled') {
  //     stateFixed.addressMain = result.address;
  //   }
  //   else {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
  //     return 'rejected';
  //   }
  // }

  // // 必須項目チェック
  // if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
  //   return;
  // }

  if (stateFixed.userName === undefined || stateFixed.userName === null || stateFixed.userName === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.userNameMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfTheTermsAndConditions === undefined ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === null ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptTheTermsAndConditions[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfPrivacyPolicy === undefined ||
      stateFixed.acceptedVersionOfPrivacyPolicy === null ||
      stateFixed.acceptedVersionOfPrivacyPolicy === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptThePrivacyPolicy[state.language] });
    return;
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const clientTime = Date.now();

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    addressMainActual = stateFixed.addressMain;

    message = `I want to use monashell. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPairsInPool[stateFixed.addressMain].keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      stateFixed.addressMain = addressMainActual;
    }

    message = `I want to use monashell. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // Mpurseのアドレスが合ってるかチェック
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          return { status: 'rejected' };
        }
      }
      else {
        stateFixed.addressMain = addressMainActual;
      }

      message = `I want to use monashell. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      devLog('message', message);
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }
  else {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }


  // const clientTime = Date.now();
  // const message = `I want to use monashell. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
  // devLog('message', message);

  // if (keyPair !== undefined) {
  //   addressMainActual = stateFixed.addressMain;

  //   result = signWithKey(state, dispatch, keyPair, message);

  //   if (result.status === 'fulfilled') {
  //     signature = result.signature;
  //   }
  //   else {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
  //     return 'rejected';
  //   }
  // }
  // else {
  //   // Mpurseのアドレスが合ってるかチェック
  //   result = await window.mpurse.getAddress()
  //   .then( (result) => {
  //     addressMainActual = result;

  //     if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
  //       if (addressMainActual !== stateFixed.addressMain) {
  //         dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
  //         return 'rejected';
  //       }
  //       else {
  //         return 'fulfilled';
  //       }
  //     }
  //     else { // off
  //       return 'fulfilled';
  //     }
  //   })
  //   .catch( (error) => {
  //       dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
  //       return 'rejected';
  //   });

  //   if (result === 'rejected') {
  //     return { status: 'rejected' };
  //   }

  //   // 署名
  //   result = await window.mpurse.signMessage(message)
  //   .then( result => {
  //     signature = result;
  //     return 'fulfilled';
  //   })
  //   .catch( err => {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
  //     return 'rejected';
  //   });

  //   if (result === 'rejected') {
  //     return { status: 'rejected' };
  //   }

  //   // try {
  //   //   signature= await window.mpurse.signMessage(message).then( result => result );
  //   // }
  //   // catch (err) {
  //   //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
  //   //   return;
  //   // }
  // }


  const formData = new FormData();

  const requestJson = JSON.stringify({
    "body": {
      "addressMain": stateFixed.addressMain,
      "addressMainActual": addressMainActual,
      "userName": stateFixed.userName,
      // "profileText": stateFixed.profileText,
      "acceptedVersionOfTheTermsAndConditions": stateFixed.acceptedVersionOfTheTermsAndConditions,
      "acceptedVersionOfPrivacyPolicy": stateFixed.acceptedVersionOfPrivacyPolicy,
      "clientTime" : clientTime,
      "signature" : signature,
      "signatureVersion" : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  // request.headers = formData.getHeaders();
  // request.headers = {
  //   'Accept': 'application/json',
  //   'Content-Type': 'multipart/form-data; charset=utf-8',
  // };
  request.url = 'https://' + process.env.REACT_APP_MS_API_DOMAIN + '/configure';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    /*
    // 基本情報をconfigureにセット
    dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });
    */

    // 各種stateにメインアドレスをセット
    dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['sendMona', 'addressFrom'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['sendXmp', 'addressFrom'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['sendToken', 'addressFrom'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'addressIssuer'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'parent'], value: '' });
    dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'addressOwner'], value: stateFixed.addressMain });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersMonacotto[stateFixed.addressMain] !== undefined ? state.usersMonacotto[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // 保有アセット取得
    getBalancesMonaparty(state, dispatch, stateFixed.addressMain)
    .then( (result) => {
      let assets;

      if (result.status === 'fulfilled') {
        const balance = result.body;
        assets = Object.keys(balance);
      }
      else {
        throw new Error(result.error);
      }

      // アセット情報取得
      return getAssetInfo(state, dispatch, assets);
    })
    .then( (result) => {
      let assetInfo;

      if (result.status === 'fulfilled') {
        assetInfo = result.body;
      }
      else {
        throw new Error(result.error);
      }

      // モナカード情報取得
      const assetCommons = Object.keys(assetInfo)
      .filter( asset => {
        try {
          return JSON.parse(assetInfo[asset]?.description).monacard === undefined;
        }
        catch (error) {
          return true;
        }
      })
      .map( asset => assetInfo[asset].asset_longname === null ? asset : assetInfo[asset].asset_longname );

      getMonacard(state, dispatch, assetCommons);
    })
    .catch( (error) => {
      // エラー＆再ログインしてください通知。
    })

    // MONA残高取得

    getAddressInfo(state, stateFixed.addressMain)
    .then( (result) => {
      if (result.status === 'fulfilled') {
        const balance = result.body.balance;
        dispatch({ type: 'setStateMultiLayers', keys: ['balance', 'mona', stateFixed.addressMain], value: balance });
      }
      else {
        devLog('failed to get address info.');
        // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });
      }
    });

    // オウンドアセット情報取得
    getOwnedAssets(state, dispatch, stateFixed.addressMain)
    .then( (result) => {
      if (result.status === 'fulfilled') {
      }
      else {
        devLog('failed to get owned asset.');
        // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });
      }
    });

    // // デッキ取得
    // loadDeck(state, dispatch, { addressMain: stateFixed.addressMain, sessionId: response.body.sessionId.addressMain });
  }
  else {
  }

  return response;
}


// 「ログイン」ボタン押下 HANDLE CLICK LOGIN
async function handleClickLogin(state, dispatch, popupLayer, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const popupLayerNext = (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0;
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.login.addressMain,
    // signatureVersion: state.config.clientParameters.signatureVersion.login,
    signatureVersion: '1',
    // addressCheck: state.addressCheck,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。
  devLog('session', JSON.stringify(state.session));
  devLog('session keys', JSON.stringify(Object.keys(state.session)));

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'login', stateFixed, certifications, now, null, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'login', stateFixed, certifications, now, null, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, null, 'login');
  }

  // 対象がなかったり、addressMainが指定されているにも関わらずcertificationsに含まれていなかった場合は戻る。ただし、KeyPairsが渡されていない場合は、鍵を要求する。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      "certifications": certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MS_API_DOMAIN + '/login';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'user', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    let certification;

    for (const address of Object.keys(response.body)) {

      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });

        // certification設定
        certification = { addressMain: address, sessionId: response.body[address]['sessionId'] };
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }

      // MONA残高・カード関連情報取得
      if (response.body[address]['certificatedBy'] === 'signature' || response.body[address]['certificatedBy'] === 'sessionId') {

        // 保有アセット取得
        getBalancesMonaparty(state, dispatch, address)
        .then( (result) => {
          let assets;

          if (result.status === 'fulfilled') {
            const balance = result.body;
            assets = Object.keys(balance);
          }
          else {
            throw new Error(result.error);
          }

          // アセット情報取得
          return getAssetInfo(state, dispatch, assets);
        })
        .then( (result) => {
          let assetInfo;

          if (result.status === 'fulfilled') {
            assetInfo = result.body;
          }
          else {
            throw new Error(result.error);
          }

          // モナカード情報取得
          const assetCommons = Object.keys(assetInfo)
          .filter( asset => {
            try {
              return JSON.parse(assetInfo[asset]?.description).monacard === undefined;
            }
            catch (error) {
              return true;
            }
          })
          .map( asset => assetInfo[asset].asset_longname === null ? asset : assetInfo[asset].asset_longname );

          getMonacard(state, dispatch, assetCommons);
        })
        .catch( (error) => {
          // stateFixed.addressMainだったら、エラー＆再ログインしてください通知。
        });

        // MONA残高取得

        getAddressInfo(state, address)
        .then( (result) => {
          if (result.status === 'fulfilled') {
            const balance = result.body.balance;
            dispatch({ type: 'setStateMultiLayers', keys: ['balance', 'mona', address], value: balance });
          }
          else {
            devLog('failed to get address info.');
            // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });
          }
        });

        // オウンドアセット情報取得
        getOwnedAssets(state, dispatch, address)
        .then( (result) => {
          if (result.status === 'fulfilled') {
          }
          else {
            devLog('failed to get owned asset.');
            // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });
          }
        });

        // let activeCertification;

        // if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        //   if  (response.body[stateFixed.addressMain].certificatedBy === 'signature') {
        //     activeCertification = {
        //       address: stateFixed.addressMain,
        //       sessionId: response.body[stateFixed.addressMain].sessionId,
        //       expirationOfSession: response.body[stateFixed.addressMain].expirationOfSession,
        //     };
        //   }
        // }

        // const result = await handleClickGetPointSum(state, dispatch, activeCertification);
        // devLog('point sum', JSON.stringify(result.body));
      }
    }

    // // デッキ取得
    // loadDeck(state, dispatch, certification);
  }
  else {
    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    if  (response.body[stateFixed.addressMain].certificatedBy === 'sessionId' || response.body[stateFixed.addressMain].certificatedBy === 'signature') {
      // 明示的ログインアドレスでログイン成功

      // 基本情報をconfigureにセット
      dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

      // 各種stateにログインアドレスをセット
      dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['sendMona', 'addressFrom'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['sendXmp', 'addressFrom'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['sendToken', 'addressFrom'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'addressIssuer'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['issuance', 'parent'], value: '' });
      dispatch({ type: 'setStateMultiLayers', keys: ['editAssetInfo', 'addressOwner'], value: stateFixed.addressMain });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.login.addressMain === undefined || state.login.addressMain === null || state.login.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: stateFixed.addressMain });
      }

      // if (state.exhibit.addressMain === undefined || state.exhibit.addressMain === null || state.exhibit.addressMain === '') {
      //   dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
      // }

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersMonacotto[stateFixed.addressMain] !== undefined ? state.usersMonacotto[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'login',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

      // ログインポップアップを消す
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
    }
    else {
      // 明示的ログインアドレスでログイン失敗

      if (response.body[stateFixed.addressMain].applicationMessage === 'not accept TAC yet.') {
        // ユーザー登録を促す。
        dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseRegisterAsAUser[state.language] });

        const popup = { type: 'userRegistrationPopup' };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the terms and conditions of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、規約同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions) {
          // クライアントには最新バージョンがロードされているので、規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードと規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });
        }
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the privacy policy of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、プライバシーポリシーに同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
          // クライアントには最新バージョンがロードされているので、プライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードとプライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });
        }
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.youFailedToLogin[state.language] });
      }
    }
  }
  else {
  }
}

// リロード
// HANDLE CLICK RELOAD
async function handleClickReload(state, dispatch) {
  const now = new Date();

  for (const address of Object.keys(state.session)) {
    if (state.session[address].expirationOfSession >= now.getTime() + marginOfSessionTime) {

      // 保有アセット取得
      const monapartyPromise = getBalancesMonaparty(state, dispatch, address)
      .then( (result) => {
        let assets;

        if (result.status === 'fulfilled') {
          const balance = result.body;
          assets = Object.keys(balance);
        }
        else {
          throw new Error(result.error);
        }

        // アセット情報取得
        return getAssetInfo(state, dispatch, assets);
      })
      .then( (result) => {
        let assetInfo;

        if (result.status === 'fulfilled') {
          assetInfo = result.body;
        }
        else {
          throw new Error(result.error);
        }

        // モナカード情報取得
        const assetCommons = Object.keys(assetInfo)
        .filter( asset => {
          try {
            return JSON.parse(assetInfo[asset]?.description).monacard === undefined;
          }
          catch (error) {
            return true;
          }
        })
        .map( asset => assetInfo[asset].asset_longname === null ? asset : assetInfo[asset].asset_longname );

        getMonacard(state, dispatch, assetCommons);
      })
      .catch( (error) => {
        // stateFixed.addressMainだったら、エラー＆再ログインしてください通知。
      });

      // MONA残高取得

      const monaPromise = getAddressInfo(state, address)
      .then( (result) => {
        if (result.status === 'fulfilled') {
          const balance = result.body.balance;
          dispatch({ type: 'setStateMultiLayers', keys: ['balance', 'mona', address], value: balance });
        }
        else {
          devLog('failed to get address info.');
          // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });
        }
      });

      dispatch({ type: 'setState', key: 'accessing', value: true });

      await Promise.all([monapartyPromise, monaPromise]);

      dispatch({ type: 'setState', key: 'accessing', value: false });

      // let activeCertification;

      // if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
      //   if  (response.body[stateFixed.addressMain].certificatedBy === 'signature') {
      //     activeCertification = {
      //       address: stateFixed.addressMain,
      //       sessionId: response.body[stateFixed.addressMain].sessionId,
      //       expirationOfSession: response.body[stateFixed.addressMain].expirationOfSession,
      //     };
      //   }
      // }

      // const result = await handleClickGetPointSum(state, dispatch, activeCertification);
      // devLog('point sum', JSON.stringify(result.body));
    }
  }
}

// HANDLE CLICK VIEW SESSION
function handleClickViewSession(state, dispatch, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  let sessionAddresses = Object.keys(state.session);

  // if (addressHistory === undefined) {
  //   addressHistory = [];
  // }

  sessionAddresses = sessionAddresses.filter( address =>
    state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
  );
  const records = sessionAddresses.map( address => <SessionRecord record={ {address} } /> );

  const popup = {
    type: 'generalList',
    body: {
      title: <SessionTitle />,
      options: records,
    },
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });

  if (sessionAddresses.length === 0) {
    dispatch({ type: 'setState', key: 'session', value: {} });
  }
}

// HANDLE CLICK COMMIT MONA TRANSACTION
function handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, network) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  devLog('popupLayer popupLayerNext', popupLayer, popupLayerNext);

  const buttonFaceOk =
  <div>
    OK
  </div>;

  const buttonFaceCancel =
  <div>
    CANCEL
  </div>;

  const callbackOk = async () => {
    dispatch({ type: 'setState', key: 'accessing', value: true });

    await broadcastMonaTransactions(state, dispatch, actionItemsMultiSourcesSigned);
    devLog('resultOfBroadcast', JSON.stringify(actionItemsMultiSourcesSigned));

    dispatch({ type: 'setState', key: 'accessing', value: false });

    if (
      Object.keys(actionItemsMultiSourcesSigned).some( source => 
        actionItemsMultiSourcesSigned[source].some( actionItem => actionItem.status === 'unprocessed' )
      )
    ) {
      const keyPairs = getValidKeyPairs(state, dispatch);

      dispatch({ type: 'setState', key: 'accessing', value: true });

      if (network === 'mona') {
        await buildMonaTransactions(
          state, dispatch, keyPairs, actionItemsMultiSourcesSigned,
          {
            provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
            transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
          }
        );
      }
      else { // monaparty
        await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSourcesSigned);
      }

      devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

      dispatch({ type: 'setState', key: 'accessing', value: false });

      handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, network);
    }
    else {
      const historyThisTime = await pushIntoHistoryMonaTransactions(state, dispatch, actionItemsMultiSourcesSigned);
      devLog('historyThisTime', JSON.stringify(historyThisTime));

      let cart;

      if (network === 'mona') {
        cart = 'cartMona';
      }
      else { // monaparty
        cart = 'cartMonaparty';
      }

      updateCart(state, dispatch, actionItemsMultiSourcesSigned, cart);

      if (historyThisTime.length === 0) {
        dispatch({ type: 'setNotification', key: 'notification', value: 'no action item.' });
      }
      else if (historyThisTime.every( actionItem => actionItem.status === 'succeeded' )) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.succeededAtLast[state.language] });
      }
      else {
        if (historyThisTime.some( actionItem => actionItem.status === 'succeeded' )) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.succeededPartially[state.language] });
        }
        else {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedAtLast[state.language] });
        }
      }

      for (let i = 0; i <= popupLayerNext; i++) {
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', i], value: { type: null, body: null } });
      }
    }
  }

  const callbackCancel = async () => {
    const resultOfPushingIntoHistory = await pushIntoHistoryMonaTransactions(state, dispatch, actionItemsMultiSourcesSigned);
    devLog('resultOfPushingIntoHistory', JSON.stringify(resultOfPushingIntoHistory));

    dispatch({ type: 'setNotification', key: 'notification', value: words.canceledRemainingInCart[state.language] });

    for (let i = 0; i <= popupLayerNext; i++) {
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', i], value: { type: null, body: null } });
    }
  }

  const popup = {
    type: 'confirmationPopup',
    body: {
      message: <ActionItemsMultiSourceMonaView actionItemsMultiSources={actionItemsMultiSourcesSigned} />,
      buttonFaceOk,
      buttonFaceCancel,
      callbackOk,
      callbackCancel,
    },
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
}

// HANDLE CLICK ISSUE ASSET
async function handleClickIssueAsset(state, dispatch, popupLayer) {
  let assetCommon;
  let quantity;
  let description;
  let descriptionObj;

  if (Object.keys(state.cartMonaparty).length > 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseEmptyTheCartBeforeIssuing[state.language] });
    return { status: 'rejected' };
  }

  // 発行者アドレスチェック

  if (state.issuance.addressIssuer === undefined || state.issuance.addressIssuer === null || state.issuance.addressIssuer === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressIssuerIsRequired[state.language] });
    return { status: 'rejected' };
  }

  // トークン名生成・チェック

  if (state.issuance.assetType === 'tokenNameLeftToTheSystem') {
    while (true) {
      assetCommon = 'A';

      // 最初の桁は0以外の数字（1〜9）にする
      assetCommon += Math.floor(Math.random() * 9) + 1;

      // 残りの18桁は0〜9のランダムな数字
      for (let i = 2; i <= 19; i++) {
        assetCommon += Math.floor(Math.random() * 10);
      }

      if (!state.assetNames.includes(assetCommon)) {
        break;
      }
    }
  }
  else if (state.issuance.assetType === 'tokenNameSpecified') {
    const regex = /^[B-Z][A-Z]{3,11}$/;

    if (regex.test(state.issuance.assetCommon)) {
      assetCommon = state.issuance.assetCommon;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theTokenNameDoesNotMeetTheRequirements[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (state.issuance.assetType === 'subAsset') {
    const regex = /^[A-Za-z0-9.\-_@!]+$/;

    if (regex.test(state.issuance.subAsset)) {
      assetCommon = state.issuance.parent + '.' + state.issuance.subAsset;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theSubAssetNameDoesNotMeetTheRequirements[state.language] });
      return { status: 'rejected' };
    }
  }
  else {
    // unknown asset type
  }
  
  // divible, listed, vendable, reassignableチェック

  if (state.issuance.divisible === undefined || state.issuance.divisible === null) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.divisibleIsRequired[state.language] });
    return { status: 'rejected' };
  }

  if (state.issuance.listed === undefined || state.issuance.listed === null) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.listedIsRequired[state.language] });
    return { status: 'rejected' };
  }

  if (state.issuance.vendable === undefined || state.issuance.vendable === null) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.vendableIsRequired[state.language] });
    return { status: 'rejected' };
  }

  if (state.issuance.reassignable === undefined || state.issuance.reassignable === null) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.reassignableIsRequired[state.language] });
    return { status: 'rejected' };
  }

  if (state.issuance.amount === undefined || state.issuance.amount === null) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToIssueIsRequired[state.language] });
    return { status: 'rejected' };
  }

  // 発行量補正・チェック

  if (state.issuance.divisible === true) {
    quantity = new decimal(state.issuance.amount.value).times(100000000).toNumber();

    if (!Number.isInteger(quantity)) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.limitTheAmountToIssueToEightDecimalPlaces[state.language] });
      return { status: 'rejected' };
    }
  }
  else {
    quantity = state.issuance.amount.value;

    if (!Number.isInteger(quantity)) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.amountToIssueMustBeInteger[state.language] });
      return { status: 'rejected' };
    }
  }

  // description生成・チェック

  try {
    descriptionObj = JSON.parse(state.issuance.description);
  }
  catch (error) {
    description = state.issuance.description;
  }

  // モナカードのときの必須チェック その1

  if (state.issuance.issueMode === 'issueMonacard') {
    if (descriptionObj?.monacard?.name === undefined || descriptionObj.monacard.name === null || descriptionObj.monacard.name === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardNameIsRequired[state.language] });
      return { status: 'rejected' };
    }

    if (descriptionObj?.monacard?.owner === undefined || descriptionObj.monacard.owner === null || descriptionObj.monacard.owner === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardIssuerNameIsRequired[state.language] });
      return { status: 'rejected' };
    }

    if (descriptionObj?.monacard?.desc === undefined || descriptionObj.monacard.desc === null || descriptionObj.monacard.desc === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardDescriptionIsRequired[state.language] });
      return { status: 'rejected' };
    }
  }

  // 画像アップロード&CID取得

  if (state.issuance.imagesNew.monacard !== undefined && state.issuance.imagesNew.monacard !== null) {
    try {
      descriptionObj.monacard.cid = 'dummy';
      const cid = await uploadImageAndGetCid(state, dispatch, ['issuance', 'imagesNew', 'monacard']);
      descriptionObj.monacard.cid = cid;
    }
    catch (error) {
      // 何もしない。
    }
  }

  // モナカードのときの必須チェック その2

  if (state.issuance.issueMode === 'issueMonacard') {
    if (descriptionObj?.monacard?.cid === undefined || descriptionObj.monacard.cid === null || descriptionObj.monacard.cid === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardCidIsRequired[state.language] });
      return { status: 'rejected' };
    }
  }

  if (descriptionObj !== undefined) {
    description = JSON.stringify(descriptionObj);
  }


  const actionItemsMultiSources = addIssuanceToTheCartMonaparty(
    state,
    dispatch,
    state.issuance.addressIssuer,
    assetCommon,
    quantity,
    description,
    state.issuance.divisible,
    state.issuance.listed,
    state.issuance.vendable,
    state.issuance.reassignable,
    state.issuance.assetType,
  );

  const keyPairs = getValidKeyPairs(state, dispatch);

  for (const address of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[address]) {
      actionItem.status = 'unprocessed';
    }
  }

  const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
  devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

  handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
}

// HANDLE CLICK EDIT ASSET INFO
async function handleClickEditAssetInfo(state, dispatch, popupLayer, editMode) {
  if (Object.keys(state.cartMonaparty).length > 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseEmptyTheCartBeforeEditing[state.language] });
    return { status: 'rejected' };
  }

  // description生成・チェック

  let description;
  let descriptionObj;

  try {
    descriptionObj = JSON.parse(state.editAssetInfo.description);
  }
  catch (error) {
    description = state.editAssetInfo.description;
  }

  // モナカードのときの必須チェック その1

  if (state.editAssetInfo.editMode === 'editMonacard') {
    if (descriptionObj?.monacard.name === undefined || descriptionObj.monacard.name === null || descriptionObj.monacard.name === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardNameIsRequired[state.language] });
      return { status: 'rejected' };
    }

    if (descriptionObj?.monacard.owner === undefined || descriptionObj.monacard.owner === null || descriptionObj.monacard.owner === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardIssuerNameIsRequired[state.language] });
      return { status: 'rejected' };
    }

    if (descriptionObj?.monacard.desc === undefined || descriptionObj.monacard.desc === null || descriptionObj.monacard.desc === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardDescriptionIsRequired[state.language] });
      return { status: 'rejected' };
    }
  }

  // 画像アップロード&CID取得

  if (state.editAssetInfo.imagesNew.monacard !== undefined && state.editAssetInfo.imagesNew.monacard !== null) {
    try {
      descriptionObj.monacard.cid = 'dummy';
      const cid = await uploadImageAndGetCid(state, dispatch, ['editAssetInfo', 'imagesNew', 'monacard']);
      descriptionObj.monacard.cid = cid;
    }
    catch (error) {
      // 何もしない。
    }
  }

  // モナカードのときの必須チェック その2

  if (state.editAssetInfo.editMode === 'editMonacard') {
    if (descriptionObj?.monacard.cid === undefined || descriptionObj.monacard.cid === null || descriptionObj.monacard.cid === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.monacardCidIsRequired[state.language] });
      return { status: 'rejected' };
    }
  }

  if (descriptionObj !== undefined) {
    description = JSON.stringify(descriptionObj);
  }


  const actionItemsMultiSources = addEditMonacardToTheCartMonaparty(
    state,
    dispatch,
    editMode,
    state.editAssetInfo.addressOwner,
    state.editAssetInfo.asset,
    state.editAssetInfo.assetCommon,
    // 0, // quantity
    description,
    // state.editAssetInfo.lock,
    state.editAssetInfo.divisible,
    state.editAssetInfo.listed,
    state.editAssetInfo.vendable,
    state.editAssetInfo.reassignable,
  );

  const keyPairs = getValidKeyPairs(state, dispatch);

  for (const address of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[address]) {
      actionItem.status = 'unprocessed';
    }
  }

  const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
  devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

  handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
}

// HANDLE CLICK ADDITIONAL ISSUANCE
async function handleClickAdditionalIssuance(state, dispatch, popupLayer) {
  if (Object.keys(state.cartMonaparty).length > 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseEmptyTheCartBeforeIssuing[state.language] });
    return { status: 'rejected' };
  }

  let quantity;

  if (state.editAssetInfo.divisible === true) {
    quantity = new decimal(state.editAssetInfo.amount.value).times(100000000).toNumber();

    if (!Number.isInteger(quantity)) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.limitTheAmountToIssueToEightDecimalPlaces[state.language] });
      return { status: 'rejected' };
    }
  }
  else {
    quantity = state.editAssetInfo.amount.value;

    if (!Number.isInteger(quantity)) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.amountToIssueMustBeInteger[state.language] });
      return { status: 'rejected' };
    }
  }

  const actionItemsMultiSources = addAdditionalIssuanceToTheCartMonaparty(
    state,
    dispatch,
    state.editAssetInfo.addressOwner,
    state.editAssetInfo.asset,
    state.editAssetInfo.assetCommon,
    quantity,
    state.editAssetInfo.description,
    state.editAssetInfo.divisible,
    state.editAssetInfo.listed,
    state.editAssetInfo.vendable,
    state.editAssetInfo.reassignable,
  );

  const keyPairs = getValidKeyPairs(state, dispatch);

  for (const address of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[address]) {
      actionItem.status = 'unprocessed';
    }
  }

  const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
  devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

  handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
}

// HANDLE CLICK LOCK ISSUANCE
async function handleClickLockIssuance(state, dispatch, popupLayer) {
  if (state.assetInfo[state.editAssetInfo.asset]?.locked) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.theAssetIsAlreadyLocked[state.language] });
    return { status: 'rejected' };
  }

  if (Object.keys(state.cartMonaparty).length > 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseEmptyTheCartBeforeLocking[state.language] });
    return { status: 'rejected' };
  }

  const actionItemsMultiSources = addLockIssuanceToTheCartMonaparty(
    state,
    dispatch,
    state.editAssetInfo.addressOwner,
    state.editAssetInfo.asset,
    state.editAssetInfo.assetCommon,
    state.editAssetInfo.divisible,
    state.editAssetInfo.listed,
    state.editAssetInfo.vendable,
    state.editAssetInfo.reassignable,
  );

  const keyPairs = getValidKeyPairs(state, dispatch);

  for (const address of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[address]) {
      actionItem.status = 'unprocessed';
    }
  }

  const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
  devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

  handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
}

// HANDLE CLICK TRANSFER OWNERSHIP
async function handleClickTransferOwnership(state, dispatch, popupLayer) {
  if (Object.keys(state.cartMonaparty).length > 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseEmptyTheCartBeforeTransferring[state.language] });
    return { status: 'rejected' };
  }

  if (state.editAssetInfo.addressNewOwner === undefined || state.editAssetInfo.addressNewOwner === null || state.editAssetInfo.addressNewOwner === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressNewOwnerIsRequired[state.language] });
    return { status: 'rejected' };
  }

  if (state.editAssetInfo.addressNewOwner === state.editAssetInfo.addressOwner) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.theNewOwnerIsTheSameAsTheCurrentOwner[state.language] });
    return { status: 'rejected' };
  }

  const actionItemsMultiSources = addTransferOwnershipToTheCartMonaparty(
    state,
    dispatch,
    state.editAssetInfo.addressOwner,
    state.editAssetInfo.asset,
    state.editAssetInfo.assetCommon,
    state.editAssetInfo.addressNewOwner,
    state.editAssetInfo.description,
    state.editAssetInfo.divisible,
    state.editAssetInfo.listed,
    state.editAssetInfo.vendable,
    state.editAssetInfo.reassignable,
  );

  const keyPairs = getValidKeyPairs(state, dispatch);

  for (const address of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[address]) {
      actionItem.status = 'unprocessed';
    }
  }

  const actionItemsMultiSourcesSigned = await buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources);
  devLog('actionItemsMultiSourcesSigned', JSON.stringify(actionItemsMultiSourcesSigned));

  handleClickCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, 'monaparty');
}

// SET SIGNATURE BY MAIN ADDRESS
async function setSignatureByAddressMain(state, dispatch, stateFixed, certifications, clientTime, extendedCertificationProperties, messageType) {
  // アクティブなMONAアドレスが有り、そのアドレスがcertificationsに含まれていなければ、署名する。
  // if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== '' && !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)) {
       // (stateFixed[stateFixed.addressMain] === undefined || stateFixed[stateFixed.addressMain].expirationOfSession < clientTime + marginOfSessionTime)) {

    // -- Mpurseのアドレス取得
    const result = await window.mpurse.getAddress()
    .then( async (result) => {
      const addressActual = result;
      let address;

      // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
          if (addressActual !== stateFixed.addressMain) {
            dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressAndTheAddressInWalletAreDifferent[state.language] });
            return 'rejected';
          }
          else {
            // 続行
          }
        }
        else { // off
          // 続行
        }

        address = stateFixed.addressMain;
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }

      devLog('address', address);
      devLog('certifications', JSON.stringify(certifications));

      if (!certifications.map( certification => certification.address ).includes(address)) {
        let message;

        if (messageType === 'login') {
          message = `I want to log in to monashell as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
        else if (messageType === 'information') {
          message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }

        devLog('message', message);

        let signatureMona;

        try {
          signatureMona = await window.mpurse.signMessage(message).then( result => result );

          const certification = {
            // addressCoinType: 'mona',
            address: address,
            addressActual: addressActual,
            clientTime: clientTime,
            signatureVersion: stateFixed.signatureVersion,
            signature: signatureMona,
            lastEvaluatedKey: stateFixed.lastEvaluatedKey,
          };

          // 拡張プロパティ
          if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
            for (const property of extendedCertificationProperties) {
              certification[property.key] = property.value;
            }
          }

          devLog('certification addressMain', JSON.stringify(certification));

          certifications.push(certification);
        }
        catch (err) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        }
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
    });
  // }
}


// GET BALANCES
async function getBalancesMonaparty(state, dispatch, address) {
  let balancesArray =[];
  const balances = {};
  // const defaultLimit = state.config.clientParameters.counterparty.getTableDefaultLimit;
  const defaultLimit = 500;
  let offset = 0;
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  SERVER: while (true) {
    balancesArray =[];
    offset = 0;

    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    while (true) {
      let balancesArrayPartial;
      const bodyObj = {
        jsonrpc: "2.0",
        id: 0,
        method: "proxy_to_counterpartyd",
        params: {
          method: "get_balances",
          params: {
            filters: [
              {field: "address", op: "==", value: address}
            ],
            offset: offset,
            limit: defaultLimit,
          },
        }
      };

      request.body = JSON.stringify(bodyObj);
      devLog('get balances; request', JSON.stringify(request));

      const response = await syncHttpRequest(request);
      devLog('get balances; response', JSON.stringify(response));

      if (response.status === 'rejected') {
        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          return {
            status: 'rejected',
            error: {}
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }
      else if (response.body.error !== undefined) {
        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          // -- 失敗 エラー返却
          return {
            status: 'rejected',
            error: response.body.error
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }
      else if (response.body.result.length === 0) {
        // -- バランス無し
        balancesArrayPartial = response.body.result;
      }
      else {
        // -- 成功
        balancesArrayPartial = response.body.result;
      }

      balancesArray = balancesArray.concat(balancesArrayPartial);

      if (balancesArrayPartial.length < defaultLimit) {
        break SERVER;
      }
      else {
        offset += defaultLimit;
      }
    }
  }

  // state格納
  for (const balance of balancesArray) {
    balances[balance.asset] = balance;
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['balance', 'monaparty', address], value: balances });

  if (balancesArray.length === 0) {
    return {
      status: 'notFound',
      body: {},
      bodyArray: [],
    };
  }
  else {
    return {
      status: 'fulfilled',
      body: balances,
      bodyArray: balancesArray,
    };
  }
}


// GET OWNED ASSETS
async function getOwnedAssets(state, dispatch, address) {
  let ownedAssetsArray =[];
  const ownedAssets = {};
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method: "get_owned_assets",
    params: {
      addresses: [
        address,
      ],
    },
  };

  request.body = JSON.stringify(bodyObj);

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    devLog('get owned assets; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get owned assets; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        return {
          status: 'rejected',
          error: {}
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.error !== undefined) {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        // -- 失敗 エラー返却
        return {
          status: 'rejected',
          error: response.body.error
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.result.length === 0) {
      // -- オウンドアッセトは存在しない
      ownedAssetsArray = response.body.result;
    }
    else {
      // -- 成功
      ownedAssetsArray = response.body.result;
      break SERVER;
    }
  }

  // state格納

  ownedAssets[address] = [];

  for (const asset of ownedAssetsArray) {
    ownedAssets[address].push(asset);
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['ownedAssets', address], value: ownedAssets[address] });

  // if (ownedAssetsArray.length === 0) {
  //   return {
  //     status: 'notFound',
  //     bodyArray: [],
  //   };
  // }
  // else {
    return {
      status: 'fulfilled',
      bodyArray: ownedAssetsArray,
    };
  // }
}


// GET ASSET INFO
async function getAssetInfo(state, dispatch, assets) {
  let assetInfosArray;
  const assetInfos = state.assetInfo;
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method: "get_assets_info",
    params: {
      assetsList: assets,
    }
  };

  request.body = JSON.stringify(bodyObj);

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';
    // request.body = `{"jsonrpc":"2.0","id":0,"method":"get_assets_info","params":{"assetsList":["${tokenName}"]}}`;

    devLog('get asset info; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get asset info; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        return {
          status: 'rejected',
          error: {}
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.error !== undefined) {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        // -- 失敗 エラー返却
        return {
          status: 'rejected',
          error: response.body.error
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.result.length === 0) {
      // -- そんなトークンは存在しない
      assetInfosArray = response.body.result;
    }
    else {
      // -- 成功
      assetInfosArray = response.body.result;
      break SERVER;
    }
  }

  // state格納
  for (const assetInfo of assetInfosArray) {
    assetInfos[assetInfo.asset] = assetInfo;
  }

  dispatch({ type: 'setState', key: 'assetInfo', value: assetInfos });

  if (assetInfosArray.length === 0) {
    return { status: 'notFound', body: {} };
  }
  else {
    return { status: 'fulfilled', body: assetInfos };
  }
}

// GET ASSET NAMES
async function getAssetNames(state, dispatch) {
  let assetNames = [];
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method:"proxy_to_counterpartyd",
    params: {
      method: "get_asset_names",
    }
  };

  request.body = JSON.stringify(bodyObj);

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    devLog('get asset names; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get asset names; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        return {
          status: 'rejected',
          error: {}
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.error !== undefined) {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        // -- 失敗 エラー返却
        return {
          status: 'rejected',
          error: response.body.error
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.result.length === 0) {
      // -- そんなトークンは存在しない
      assetNames = response.body.result;
    }
    else {
      // -- 成功
      assetNames = response.body.result;
      break SERVER;
    }
  }

  // state格納
  dispatch({ type: 'setState', key: 'assetNames', value: assetNames });

  if (assetNames.length === 0) {
    return { status: 'notFound', body: [] };
  }
  else {
    return { status: 'fulfilled', body: assetNames };
  }
}

// GET MONACARD
async function getMonacard(state, dispatch, assets) {
//  const tokenNamesCSV = tokenNames.reduce( (acc, cur) => `${acc},${cur}`, '' );

  const assetCsvsDivided = [];
  let csv = '';

  for (const asset of assets) {
    if ( (new Blob([csv])).size > 1700 ) {
      assetCsvsDivided.push(csv);
      csv = '';
    }

    csv += ',' + asset;
  }

  assetCsvsDivided.push(csv);

  let request = {};
  let response;
  let monacardsArray = [];
  let monacards = state.monacard;

  request.method = 'GET';
  request.body = undefined;

  for (const assetCSV of assetCsvsDivided) {
    request.url = `https://card.mona.jp/api/card_detail?assets=${assetCSV}`
    devLog('get monacard; request', JSON.stringify(request));

    response = await syncHttpRequest(request);
    devLog('get monacard; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      return { status: 'rejected' };
    }
    else if (response.body.error !== undefined) {
      if (response.body.error.message === 'No matched assets.') {
//        return { status: 'notFound', body: [] };
      }
      else {
        return { status: 'rejected', error: response.body.error.message };
      }
    }
    else if (response.body.details === undefined) {
      return { status: 'rejected', error: 'details undefined' };
    }
    else {
      // -- get成功！
//      return { status: 'fulfilled', body: response.body.details };
      monacardsArray = monacardsArray.concat(response.body.details);
    }
  }

  // state格納
  for (const monacard of monacardsArray) {
    monacards[monacard.asset] = monacard;
  }

  dispatch({ type: 'setState', key: 'monacard', value: monacards });

  if (monacardsArray.length === 0) {
    return { status: 'notFound', body: [] };
  }
  else {
    return { status: 'fulfilled', body: monacards };
  }
}


// // LOAD DECK
// async function loadDeck(state, dispatch, certification) {
//   let request = {};
//   let messages = {};
//   const now = Date.now();
// 
//   // state固定
//   const stateFixed = {
//   };
// 
//   const addressMainsValid = Object.keys(state.session).filter( addressMain => state.session[addressMain].expirationOfSession >= now + marginOfSessionTime )
//   let certifications = addressMainsValid.map( addressMain => { return { addressMain: addressMain, sessionId: state.session[addressMain].sessionId }; } )
// 
//   if (certification !== undefined && certification !== null && !addressMainsValid.includes(certification.addressMain)) {
//     certifications = certifications.concat([certification]);
//   }
// 
//   request.body = JSON.stringify({
//     body: {
//       certifications: certifications,
//     }
//   });
// 
//   request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/load_deck';
//   request.method = 'POST';
// 
//   messages = {
//   };
// 
//   const response = await syncHttpRequest2(request, 'deckSet', undefined, undefined, messages, dispatch);
// 
//   if ( response.status === 'rejected' ) {
//     const deckSets = {};
// 
//     for (const addressMain of Object.keys(state.session)) {
//       deckSets[addressMain] = {
//         currentDeckNo: 1,
//         decks: [
//           null,
//           {
//             deckName: 'deck1',
//             cards: [
//             ],
//           },
//         ],
//       };
//     }
// 
//     dispatch({ type: 'setState', key: 'deckSet', value: deckSets });
// 
//     return {
//       status: 'rejected',
//       body: deckSets,
//     };
//   }
// 
//   return {
//     status: 'fulfilled',
//     body: response.body,
//   };
// }


async function getConfig(dispatch, project, keys, stateKey) {
  let request = {};
  let messages = {};

  request.body = JSON.stringify({
    body: {
      project: project,
      keys: keys,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MS_API_DOMAIN + '/get_client_parameter';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, stateKey, undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return {
      status: 'fulfilled',
      body: response,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}


// HANDLE CLICK DISPLAY MNEMONIC
function handleClickDisplayMnemonic(state, dispatch, mnemonics) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: mnemonics[0] });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });

  return;
}

// HANDLE CLICK CLEAR MNEMONIC
function handleClickClearMnemonic(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: '' });

  return;
}

// HANDLE CLICK DISPLAY WIF
function handleClickDisplayWif(state, dispatch, mnemonics, keyPairs) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: keyPairs[0].toWIF() });

  return;
}

// HANDLE CLICK CLEAR WIF
function handleClickClearWif(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: '' });

  return;
}

// HANDLE CLICK DISPLAY ADDRESS
function handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs) {
  let address;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });

  const result = getAddressFromKey(state, dispatch, keyPairs[0]);

  if (result.status === 'fulfilled') {
    address = result.address;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: address }); 
    return result;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return result;
  }
}

// HANDLE CLICK CLEAR ADDRESS
function handleClickClearAddress(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: '' });

  return;
}

// HANDLE CLICK SWAP MNEMONICS
async function handleClickSwapMnemonics(state, dispatch) {
  const swapStatus = localStorage.getItem('swapStatus');
  // const oldAddress = localStorage.getItem('oldAddress');
  const oldMnemonic = localStorage.getItem('oldMnemonic');
  const oldKeyPair = getKeyPairsFromMnemonic(oldMnemonic)[0];
  const newAddress = localStorage.getItem('newAddress');
  const newMnemonic = localStorage.getItem('newMnemonic');
  const sweepAssetsTxid = localStorage.getItem('sweepAssetsTxid');
  const sweepMonaTxid = localStorage.getItem('sweepMonaTxid');

  // devLog('swapStatus', swapStatus);
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: swapStatus });

  if (swapStatus === undefined || swapStatus === null) {
    // ニュートラル状態だから、はじめるよ！
    // まず新しいニーモニックを生成して、それと古いニーモニックをローカルストレージに保存するよ。
    // localStorage.setItem('swapStatus', 'keepingMnemonics');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'keepingMnemonics' }); // 暫定

    const callback = (mnemonics, keyPairs) => keepMnemonics(state, dispatch, mnemonics, keyPairs);
    handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
  }
  else if (swapStatus === 'keptMnemonics') {
    // トークンをスウィープするよ！
    // localStorage.setItem('swapStatus', 'transfering');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfering' }); // 暫定

    const result = await sweepAssets(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      const txid = result.body;
      localStorage.setItem('sweepAssetsTxid', txid);
      localStorage.setItem('swapStatus', 'sweepingAssets');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweepAssetsTxid ' + txid }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }
  }
  else if (swapStatus === 'sweepingAssets') {
    // トークンスウィープが承認されたかどうかチェックするよ！
    let confirmations;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const result = await getTransaction(state, sweepAssetsTxid);

    if (result.status === 'fulfilled') {
      confirmations = result.body.confirmations;
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'confirmations of sweep assets ' + confirmations }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

    if (confirmations >= 1) {
      localStorage.setItem('swapStatus', 'sweptAssets');
    }
    else {
      return {
        status: 'rejected',
        error: 'not confirmed yet.',
      };
    }

    // MONA移転するぞ！
    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'sweptAssets') {
    // トークンスウィープが完了したので、MONA移転するよ！
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfering MONA' }); // 暫定

    const result = await sweepMona(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      const txid = result.body;
      localStorage.setItem('sweepMonaTxid', txid);
      localStorage.setItem('swapStatus', 'sweepingMona');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweepMonaTxid ' + txid }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep MONA; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

  }
  else if (swapStatus === 'sweepingMona') {
    // MONA移転が承認されたかどうかチェックするよ！
    let confirmations;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const result = await getTransaction(state, sweepMonaTxid);

    if (result.status === 'fulfilled') {
      confirmations = result.body.confirmations;
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'confirmations of send MONA ' + confirmations }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

    if (confirmations >= 1) {
      localStorage.setItem('swapStatus', 'sweptMona');
    }
    else {
      return {
        status: 'rejected',
        error: 'not confirmed yet.',
      };
    }

    // 移転完了NFCタグに書き込むぞ！

    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'sweptMona') {
    // MONA移転が完了したので、NFCタグ書き換えるよ！
    let newMnemonicEncrypted;

    // localStorage.setItem('swapStatus', 'transfered');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const password = state.config.clientParameters.specialMessage;
    const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
    const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];

    try {
      newMnemonicEncrypted = await encrypt(state, dispatch, newMnemonic, password, salt, iv);
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'new mnemonic encrypted ' + newMnemonicEncrypted }); // 暫定
      // await setTimeout(10000);
      await sleep(10000);
    }
    catch (error) {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'new mnemonic encrypted; rejected ' + error }); // 暫定
      throw new Error(error);
    }

    try {
      await writeMnemonicOnNfc(state, dispatch, newMnemonicEncrypted);
      localStorage.setItem('swapStatus', 'wroteMnemonic');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'write mnemonic succeeded.' }); // 暫定
    }
    catch (error) {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'write mnemonic; rejected ' + error }); // 暫定
      throw new Error(error);
    }

    // NFCタグちゃんと書き込まれたかチェックするぞ！

    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'wroteMnemonic') {
    // NFCタグ書き込みが完了したので、ちゃんと書き込まれたかチェックするよ！

    // localStorage.setItem('swapStatus', 'transfered');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const callback = (mnemonics) => checkMnemonicOnNfc(state, dispatch, newMnemonic, mnemonics);
    handleClickNfc(state, dispatch, callback, { args: ['mnemonics'] });
  }
  else if (swapStatus === 'transfered') {
    localStorage.removeItem('swapStatus');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' }); // 暫定
  }
  else if (swapStatus === 'keepingMnemonics' || swapStatus === 'transfering') {
    localStorage.removeItem('swapStatus');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' });
  }
  else {
    devLog('swapStatus', swapStatus);
  }

  // if (swapStatus === undefined || swapStatus === null) {
  //   localStorage.setItem('swapStatus', 'readOldMnemonic');
  // }
  // else if (swapStatus === 'readOldMnemonic') {
  //   localStorage.setItem('swapStatus', 'keptMnemonics');
  // }
  // else if (swapStatus === 'keptMnemonics') {
  //   localStorage.setItem('swapStatus', 'sentToken');
  // }
  // else if (swapStatus === 'sentToken') {
  //   localStorage.setItem('swapStatus', 'sentMona');
  // }
  // else if (swapStatus === 'sentMona') {
  //   localStorage.setItem('swapStatus', 'confirmSentToken');
  // }
  // else if (swapStatus === 'confirmSentToken') {
  //   localStorage.setItem('swapStatus', 'confirmSentMona');
  // }
  // else if (swapStatus === 'confirmSentMona') {
  //   localStorage.setItem('swapStatus', 'transferMonatokaPoint');
  // }
  // else if (swapStatus === 'transferMonatokaPoint') {
  //   localStorage.removeItem('swapStatus');
  // }
  // else {
  //   devLog('swapStatus', swapStatus);
  // }
}

// KEEP MNEMONICS
function keepMnemonics(state, dispatch, oldMnemonics, oldKeyPairs) {
  let oldAddress;
  let newMnemonic;
  let newAddress;
  let result;

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, oldKeyPairs[0]);

  if (result.status === 'fulfilled') {
    oldAddress = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return 'rejected';
  }

  // 新ニーモニック生成

  result = handleClickGenerateMnemonic(state, dispatch);

  if (result.status === 'fulfilled') {
    newMnemonic = result.mnemonic;
    newAddress = result.address;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'error keepMnemonics' });
    return;
  }

  localStorage.setItem('oldMnemonic', oldMnemonics[0]);
  localStorage.setItem('oldKeyPair', oldKeyPairs[0]);
  localStorage.setItem('oldAddress', oldAddress);
  localStorage.setItem('newMnemonic', newMnemonic);
  localStorage.setItem('newAddress', newAddress);
  localStorage.setItem('swapStatus', 'keptMnemonics');

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: localStorage.getItem('oldMnemonic') + ' : ' + newMnemonic + ' : ' + localStorage.getItem('oldKeyPair') }); // 暫定

  // 移転するぞ！

  // handleClickSwapMnemonics(state, dispatch);
}

// SWEEP ASSETS
async function sweepAssets(state, dispatch, oldKeyPair, newAddress) {
  let oldAddress;
  let balanceAssets;
  let ownedAssets;
  let sweepAssetsTxid;
  let result;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'tra tra' + oldKeyPair.privateKey }); // 暫定

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, oldKeyPair);

  if (result.status === 'fulfilled') {
    oldAddress = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    throw new Error(result);
  }

  // モナパトークン保有チェック

  result = await getBalancesMonaparty(state, dispatch, oldAddress)

  if (result.status === 'fulfilled') {
    balanceAssets = result.bodyArray;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'balance' + JSON.stringify(result.error) }); // 暫定
    throw new Error(result);
  }

  // モナパトークンオウンチェック

  result = await getOwnedAssets(state, dispatch, oldAddress)

  if (result.status === 'fulfilled') {
    ownedAssets = result.bodyArray;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'own' + JSON.stringify(result.error) }); // 暫定
    throw new Error(result);
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: `balance: ${balanceAssets.length}, own: ${ownedAssets.length}` }); // 暫定

  if (balanceAssets.length > 0 || ownedAssets.length > 0) {
    // モナパトークンsweep

    result = await sweepAssetsSequence(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      sweepAssetsTxid = result.body;
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result);
    }
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'no asset' }); // 暫定

    return {
      status: 'rejected',
      error: 'no asset',
    };
  }

  return {
    status: 'fulfilled',
    body: sweepAssetsTxid,
  };
}

// HANDLE CLICK DISPLAY WIF FROM MNEMONIC FILLED IN
function handleClickDisplayWifFromMnemonicFilledIn(state, dispatch) {
  const mnemonicFilledIn = state.displaySecret.mnemonicToWif;

  if (!bip39.validateMnemonic(mnemonicFilledIn)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.invalidMnemonic[state.language] });
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: '' });

    throw new Error('invalidMnemonic');
  }

  const keyPairs = getKeyPairsFromMnemonic(mnemonicFilledIn);
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: keyPairs[0].toWIF() });

  return;
}

// HANDLE CLICK CLEAR MNEMONIC AND WIF
function handleClickClearMnemonicAndWif(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicToWif'], value: '' });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: '' });

  return;
}


// BUILD MONA TRANSACTIONS
async function buildMonaTransactions(state, dispatch, keyPairs, actionItemsMultiSources, { provisionalTransactionFee, transactionFeeUpperBound }) {
  let result;
  let utxos;
  let feeRate;
  let transactionFee;
  let change;
  let virtualSize;
  let transactionUnsignedPreBuild;
  let transactionSignedPreBuild;
  let transactionUnsigned;
  let transactionSigned;

  // const keyPairDummy = bitcoin.ECPair.fromWIF(state.config.clientParameters.wifDummy, networkMona);

  devLog('provisionalTransactionFee', provisionalTransactionFee);

  SOURCE: for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    const keyPair = keyPairs[addressFrom]?.keyPair;

    ACTION_ITEM: for (const [index, actionItem] of actionItemsMultiSources[addressFrom].entries()) {
      // actionItem単位でトランザクションを作成し、ブロードキャストする。

      if (actionItem.status === 'unprocessed') {
        if (actionItem.action === 'send' && actionItem.recipients.length >= 1) {
          const amountWatanabeTotal = actionItem.recipients.reduce((acc, cur) => acc + cur.amountWatanabe, 0);

          // UTXO取得
          result = await getUtxo(state, addressFrom);

          if (result.status === 'fulfilled' && result.body.error === undefined) {
            utxos = result.body;
          }
          else {
            const error = result.status !== 'fulfilled' ? result.error : result.body.error;

            devLog(`failed to get UTXO.; ${error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to get UTXO.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetUtxos';
            actionItem.error = error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // UTXO選定
          utxos.sort( (a, b) => a.amount - b.amount );
          const utxosSelected = selectUtxoWatanabe(utxos, amountWatanabeTotal + provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
          devLog('utxosSelected', JSON.stringify(utxosSelected));

          if (utxosSelected === null) {
            devLog('short of MONA');
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToBuildTransaction[state.language] + '; short of MONA' });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'shortOfMona';
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // UTX raw transaction取得
          result = await getRawTransaction(state, utxosSelected);

          if (result.status === 'rejected') {
            devLog(`failed to get unspent raw transactions.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]} ; failed to get unspent raw transactions.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetUnspentRawTransactions';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // fee rate取得
          result = await getFeeRate(state);

          if (result.status === 'fulfilled' && result.body.error === undefined) {
            feeRate = result.body;
          }
          else {
            const error = result.status !== 'fulfilled' ? result.error : result.body.error;

            devLog(`failed to get fee rate.; ${error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to get fee rate.; ${error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetFeeRate';
            actionItem.error = error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // input, output作成

          const changeOfZeroFee = utxosSelected.reduce((acc, cur) => acc + cur.satoshis, 0) - amountWatanabeTotal;

          const inputs = utxosSelected.map( utxo => {
            return {
              txid: utxo.txid,
              vout: utxo.vout,
              rawTransaction: utxo.rawTransaction,
            };
          });

          devLog('inputs', JSON.stringify(inputs));

          const outputs = actionItem.recipients.map( recipient => {
            return {
              address: recipient.addressTo,
              amount: recipient.amountWatanabe,
            };
          });

          const outputsPreBuild = [ ...outputs ];

          outputsPreBuild.push(
            {
              address: addressFrom,
              amount: changeOfZeroFee,
            }
          );

          devLog('outputsPreBuild', JSON.stringify(outputsPreBuild));


          // 仮トランザクション作成(トランザクションサイズ取得のため)

          result = buildUnsignedTransactionSendMona(inputs, outputsPreBuild);

          if (result.status === 'fulfilled') {
            transactionUnsignedPreBuild = result.body;
          }
          else {
            devLog(`failed to pre-build transaction.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to pre-build transaction.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToPreBuildTransaction';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          if (keyPair !== undefined) {
            // 仮トランザクション署名(トランザクションサイズ取得のため)

            result = signTransaction(transactionUnsignedPreBuild, keyPair);

            if (result.status === 'fulfilled') {
              transactionSignedPreBuild = result.body;
            }
            else {
              devLog(`failed to pre-sign transaction.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to pre-sign transaction.; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToPreSign';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }

            // トランザクションサイズ取得

            virtualSize = bitcoin.Transaction.fromHex(transactionSignedPreBuild).virtualSize();
            console.log('virtualSize', virtualSize);
          }
          else {
            // トランザクションサイズ取得

            virtualSize = bitcoin.Transaction.fromHex(transactionUnsignedPreBuild).virtualSize();
            console.log('approximated virtualSize', virtualSize);
          }

          // 本トランザクション作成

          // お釣り計算

          result = calculateChangeWatanabe(utxosSelected, amountWatanabeTotal, feeRate, virtualSize, transactionFeeUpperBound);

          if (result.status === 'fulfilled') {
            change = result.body.change;
            transactionFee = result.body.transactionFee;
          }
          else {
            devLog(`failed to calculate change.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to calculate change.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToCalculateChange';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // トランザクション作成

          outputs.push(
            {
              address: addressFrom,
              amount: change,
            }
          );

          devLog('outputs', JSON.stringify(outputs));

          result = buildUnsignedTransactionSendMona(inputs, outputs);

          if (result.status === 'fulfilled') {
            transactionUnsigned = result.body;
          }
          else {
            devLog(`failed to build transaction.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; failed to build transaction.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToBuildTransaction';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // トランザクション署名

          if (keyPair !== undefined) {
            result = signTransaction(transactionUnsigned, keyPair);

            if (result.status === 'fulfilled') {
              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              devLog(`failed to sign transaction.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]}; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithKey';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
          else if (window.mpurse) { // Mpurse
            result = await window.mpurse.signRawTransaction(transactionUnsigned)
            .then( async (result) => {
              devLog('succeeded to sign transaction.');
              devLog(result);

              return {
                status: 'fulfilled',
                body: result,
              };
            })
            .catch( (error) => {
              devLog('failed to sign transaction with Mpurse.');
              devLog(error);

              // notification
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]}; ${error}` });

              return {
                status: 'rejected',
                body: error
              };
            });

            if (result.status === 'fulfilled') {
              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithMpurse';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
          else {
            devLog('failed to sign transaction.; no keyPair and no Mpurse.');
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]} ${words.pleaseLogin[state.language]}` });
          }
        }
        else { // sendしかない
        }

        break SOURCE;
      }
    }
  }

  return actionItemsMultiSources;
}


// BROADCAST MONA TRANSACTIONS
async function broadcastMonaTransactions(state, dispatch, actionItemsMultiSources) {

  // ブロードキャスト

  for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
      if (actionItem.statusDetail === 'signed') {
        const result = await broadcastTransaction(state, actionItem.transactionSigned);

        if (result.status === 'fulfilled') {
          actionItem.txid = result.body;
          actionItem.status = 'succeeded';
          actionItem.statusDetail = 'broadcasted';

          // notification
          dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] });
        }
        else {
          devLog('failed to broadcast transaction.');
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '8' });

          actionItem.status = 'failed';
          actionItem.statusDetail = 'failedToBroadcastTransaction';
          continue ACTION_ITEM;
        }
      }
    }
  }

  return actionItemsMultiSources;
}

// PUSH INTO HISTORY MONA TRANSACTIONS
function pushIntoHistoryMonaTransactions(state, dispatch, actionItemsMultiSources) {

  // 履歴に格納

  const history = state.actionHistory;
  const historyThisTime = [];

  for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
      let actionItemInHistory;

      if (actionItem.transactionType === 'sendMona') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressFrom: actionItem.addressFrom, // 変わらない
          recipients: actionItem.recipients.map( recipient => {
            return {
              addressTo: recipient.addressTo,
              amountWatanabe: recipient.amountWatanabe,
            };
          }), // 変わる
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
        };
      }
      else if (actionItem.transactionType === 'sendToken') {
        let recipients = {};

        for (const addressTo of Object.keys(actionItem.recipients)) {
          recipients[addressTo] = {};

          for (const asset of Object.keys(actionItem.recipients[addressTo])) {
            recipients[addressTo][asset] = {
              quantity: actionItem.recipients[addressTo][asset].quantity,
            };
          }
        }

        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressFrom: actionItem.addressFrom, // 変わらない
          recipients, // 変わる
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
        };
      }
      else if (actionItem.action === 'issue') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressIssuer: actionItem.addressIssuer, // 変わらない
          assetCommon: actionItem.assetCommon, // 変わらない
          quantity: actionItem.quantity, // 変わらない
          description: actionItem.description, // 変わらない
          // lock: actionItem.lock, // 変わらない
          divisible: actionItem.divisible, // 変わらない
          listed: actionItem.listed, // 変わらない
          vendable: actionItem.vendable, // 変わらない
          reassignable: actionItem.reassignable, // 変わらない
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
          assetType: actionItem.assetType, // 変わらない
        };
      }
      else if (actionItem.action === 'editMonacard' || actionItem.action === 'editAdvancedToken') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressOwner: actionItem.addressOwner, // 変わらない
          asset: actionItem.asset, // 変わらない
          assetCommon: actionItem.assetCommon, // 変わらない
          quantity: actionItem.quantity, // 変わらない
          description: actionItem.description, // 変わらない
          divisible: actionItem.divisible, // 変わらない
          listed: actionItem.listed, // 変わらない
          vendable: actionItem.vendable, // 変わらない
          reassignable: actionItem.reassignable, // 変わらない
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
        };
      }
      else if (actionItem.action === 'additionalIssuance') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressOwner: actionItem.addressOwner, // 変わらない
          asset: actionItem.asset, // 変わらない
          assetCommon: actionItem.assetCommon, // 変わらない
          quantity: actionItem.quantity, // 変わらない
          description: actionItem.description, // 変わらない
          divisible: actionItem.divisible, // 変わらない
          listed: actionItem.listed, // 変わらない
          vendable: actionItem.vendable, // 変わらない
          reassignable: actionItem.reassignable, // 変わらない
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
        };
      }
      else if (actionItem.action === 'lockIssuance') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressOwner: actionItem.addressOwner, // 変わらない
          asset: actionItem.asset, // 変わらない
          assetCommon: actionItem.assetCommon, // 変わらない
          quantity: actionItem.quantity, // 変わらない
          description: actionItem.description, // 変わらない
          divisible: actionItem.divisible, // 変わらない
          listed: actionItem.listed, // 変わらない
          vendable: actionItem.vendable, // 変わらない
          reassignable: actionItem.reassignable, // 変わらない
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
        };
      }
      else if (actionItem.action === 'transferOwnership') {
        actionItemInHistory = {
          transactionType: actionItem.transactionType, // 変わらない
          action: actionItem.action, // 変わらない
          addressOwner: actionItem.addressOwner, // 変わらない
          asset: actionItem.asset, // 変わらない
          assetCommon: actionItem.assetCommon, // 変わらない
          addressNewOwner: actionItem.addressNewOwner, // 変わらない
          quantity: actionItem.quantity, // 変わらない
          divisible: actionItem.divisible, // 変わらない
          listed: actionItem.listed, // 変わらない
          vendable: actionItem.vendable, // 変わらない
          reassignable: actionItem.reassignable, // 変わらない
          status: actionItem.status, // 変わる
          statusDetail: actionItem.statusDetail, // 変わる
          transactionSigned: actionItem.transactionSigned, // 変わる
          txid: actionItem.txid, // 変わる
          transactionFee: actionItem.transactionFee, // 変わる
          outValueExcludeChange: actionItem.outValueExcludeChange, // 変わる
        };
      }

      historyThisTime.unshift(actionItemInHistory);
    }
  }

  history.unshift(...historyThisTime);
  dispatch({ type: 'setState', key: 'actionHistory', value: history });
  devLog('historyThisTime', JSON.stringify(historyThisTime));

  return historyThisTime;
}

// UPDATE CART
function updateCart(state, dispatch, actionItemsMultiSources, cart) {

  // カート更新

  const actionItemsMultiSourcesNew = {};

  for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    const actionItemsNew = actionItemsMultiSources[addressFrom].filter( actionItem => actionItem.status === 'failed' );

    if (actionItemsNew.length >= 1) {
      actionItemsMultiSourcesNew[addressFrom] = actionItemsNew;
    }
  }

  dispatch({ type: 'setState', key: cart, value: actionItemsMultiSourcesNew });

  return actionItemsMultiSourcesNew;
}

// CLEAN UP OBJECT
function cleanUpObject(state, dispatch, object) {
  if (Array.isArray(object)) {
    for (const [index, value] of object.entries()) {
      const isEmpty = cleanUpObject(state, dispatch, value);

      if (isEmpty) {
        object.splice(index, 1);
      }
    }

    if (object.length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }
  else if (typeof object === 'object' && object !== null) {
    for (const key of Object.keys(object)) {
      const isEmpty = cleanUpObject(state, dispatch, object[key]);

      if (isEmpty) {
        delete object[key];
      }
    }

    if (Object.keys(object).length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}

// BUILD MONAPARTY TRANSACTIONS
async function buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources) {
  let requestToMonapartyServer = {};
  let bodyObject = {};
  let result = {};
  let response = {};
  let outValueExcludeChange;
  let change;
  let transactionFee;
  let transactionUnsigned;
  let transactionSigned;
  let txid;
  let serverIndex = 0;

  requestToMonapartyServer.method = 'POST';

  devLog('actionItemsMultiSources', JSON.stringify(actionItemsMultiSources));

  SOURCE: for (const source of Object.keys(actionItemsMultiSources)) {
    const keyPair = keyPairs[source]?.keyPair;

    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[source]) {
      // actionItem単位でトランザクションを作成し、ブロードキャストする。

      if (actionItem.status === 'unprocessed') {
        if (actionItem.action === 'send') {
          // パラメータ作成

          const destinations = [];
          const assets = [];
          const quantitys = [];

          for (const destination of Object.keys(actionItem.recipients)) {
            for (const asset of Object.keys(actionItem.recipients[destination])) {
              destinations.push(destination);
              assets.push(asset);
              quantitys.push(actionItem.recipients[destination][asset].quantity);
            }
          }

          if (destinations.length < 1 || assets.length < 1 || quantitys.some( quantity => quantity <= 0)) {
            continue ACTION_ITEM;
            // break SOURCE;
          }

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_send",
              "params": {
                "source": source,
                "destination": destinations.length >= 2 ? destinations : destinations[0],
                "asset": assets.length >= 2 ? assets : assets[0],
                "quantity": quantitys.length >= 2 ? quantitys : quantitys[0],
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);
        }
        else if (actionItem.action === 'issue') {
          // パラメータ作成

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_issuance",
              "params": {
                "source": source,
                "asset": actionItem.assetCommon,
                "quantity": actionItem.quantity,
                "description": actionItem.description,
                "divisible": actionItem.divisible,
                "listed": actionItem.listed,
                "vendable": actionItem.vendable,
                "reassignable": actionItem.reassignable,
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);
        }
        else if (actionItem.action === 'editMonacard' || actionItem.action === 'additionalIssuance' || actionItem.action === 'editAdvancedToken') {
          // パラメータ作成

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_issuance",
              "params": {
                "source": source,
                "asset": actionItem.asset,
                "quantity": actionItem.quantity,
                "description": actionItem.description,
                "divisible": actionItem.divisible,
                "listed": actionItem.listed,
                "vendable": actionItem.vendable,
                "reassignable": actionItem.reassignable,
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);
        }
        else if (actionItem.action === 'lockIssuance') {
          // パラメータ作成

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_issuance",
              "params": {
                "source": source,
                "asset": actionItem.asset,
                "quantity": actionItem.quantity,
                "description": actionItem.description,
                "divisible": actionItem.divisible,
                "listed": actionItem.listed,
                "vendable": actionItem.vendable,
                "reassignable": actionItem.reassignable,
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);
        }
        else if (actionItem.action === 'transferOwnership') {
          // パラメータ作成

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_issuance",
              "params": {
                "source": source,
                "asset": actionItem.asset,
                "transfer_destination": actionItem.addressNewOwner,
                "quantity": actionItem.quantity,
                "description": actionItem.description,
                "divisible": actionItem.divisible,
                "listed": actionItem.listed,
                "vendable": actionItem.vendable,
                "reassignable": actionItem.reassignable,
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);
        }
        // else if (actionItem.action === 'additionalIssuance') {
        //   // パラメータ作成

        //   bodyObject = {
        //     "jsonrpc":"2.0",
        //     "id":0,
        //     "method":"proxy_to_counterpartyd",
        //     "params": {
        //       "method":"create_issuance",
        //       "params": {
        //         "source": source,
        //         "asset": actionItem.assetCommon,
        //         "quantity": actionItem.quantity,
        //         // "description": actionItem.description,
        //         // "lock": actionItem.lock,
        //         "divisible": actionItem.divisible,
        //         "listed": actionItem.listed,
        //         "vendable": actionItem.vendable,
        //         "reassignable": actionItem.reassignable,
        //         "extended_tx_info": true,
        //       }
        //     }
        //   };

        //   requestToMonapartyServer.body = JSON.stringify(bodyObject);
        // }
        else { // unknown action
        }

        // トランザクション作成

        let buildTransactionRetryCount = 0;

        while (response.status !== 'fulfilled' || response.body.error !== undefined) {
          requestToMonapartyServer.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
          console.log('request create_send', JSON.stringify(requestToMonapartyServer));

          response = await syncHttpRequest(requestToMonapartyServer);
          console.log('response', JSON.stringify(response));

          if (response.status === 'rejected' || response.body.error !== undefined) {
            devLog('build transaction in counterpartyd; rejected');
            buildTransactionRetryCount++;

            if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
              serverIndex = 0;
            }
            else {
              serverIndex++;
            }

            await sleep(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
            // await setTimeout(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
            // keepSqsMessage = true;
            // continue;
          }
          else {
            transactionUnsigned = response.body.result.tx_hex;
            outValueExcludeChange = response.body.result.btc_out;
            change = response.body.result.btc_change;
            transactionFee = response.body.result.btc_fee;
            devLog('transactionUnsigned', transactionUnsigned);
          }

          if (buildTransactionRetryCount > state.config.clientParameters.retry.buildTransaction.retryMax) {
            // -- retry over エラー終了
            const error = response.status === 'rejected' ? response.error : response.body.error;

            devLog(`build transaction retry over.; ${error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToBuildTransaction[state.language]}; ${JSON.stringify(error)}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToBuildTransaction';
            actionItem.error = error;
            continue ACTION_ITEM;
            // break SOURCE;
          }
        }

        // トランザクション署名

        if (keyPair !== undefined) {
          result = {};

          result = signTransaction(transactionUnsigned, keyPair);
          devLog('sign transaction; result', JSON.stringify(result));

          if (result.status === 'fulfilled') {
            devLog('succeeded to sign transaction.');

            transactionSigned = result.body;
            actionItem.transactionSigned = transactionSigned;
            actionItem.outValueExcludeChange = outValueExcludeChange;
            actionItem.change = change;
            actionItem.transactionFee = transactionFee;
            actionItem.status = 'succeeded';
            actionItem.statusDetail = 'signed';
          }
          else {
            devLog(`failed to sign transaction.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]}; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToSignWithKey';
            actionItem.error = result.error;
            continue ACTION_ITEM;
            // break SOURCE;
          }
        }
        else if (window.mpurse) { // Mpurse
          result = {};

          result = await window.mpurse.signRawTransaction(transactionUnsigned)
          .then( async (result) => {
            devLog('succeeded to sign transaction.');
            devLog(result);

            return {
              status: 'fulfilled',
              body: result,
            };
          })
          .catch( (error) => {
            // devLog('failed to sign transaction.');
            // devLog(error);

            // notification
            // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSignTransaction[state.language] });

            return {
              status: 'rejected',
              body: error
            };
          });

          if (result.status === 'fulfilled') {
            devLog('succeeded to sign transaction.');

            transactionSigned = result.body;
            actionItem.transactionSigned = transactionSigned;
            actionItem.outValueExcludeChange = outValueExcludeChange;
            actionItem.transactionFee = transactionFee;
            actionItem.status = 'succeeded';
            actionItem.statusDetail = 'signed';
          }
          else {
            devLog(`failed to sign transaction with Mpurse.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]}; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToSignWithMpurse';
            actionItem.error = result.error;
            continue ACTION_ITEM;
            // break SOURCE;
          }
        }
        else {
          devLog('failed to sign transaction.; no keyPair and no Mpurse.');
          dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSignTransaction[state.language]} ${words.pleaseLogin[state.language]}` });
        }

        // break SOURCE;
      }
    }
  }

  return actionItemsMultiSources;
}

// SWEEP ASSETS SEQUENCE
async function sweepAssetsSequence(state, dispatch, keyPair, addressTo, { sweepFlag = 3 } = {}) {
  let requestToCounterblockMona = {};
  let bodyObject = {};
  let addressFrom;
  let result;
  let response = {};
  let transactionUnsigned;
  let transactionSigned;
  let txid;
  let serverIndex = 0;

  // requestToCounterblockMona.url = 'https://monapa.electrum-mona.org/_api';
  // requestToCounterblockMona.url = 'https://wallet.monaparty.me/_api';
  // requestToCounterblockMona.url = 'https://counterblock.api.monaparty.me/';

  requestToCounterblockMona.method = 'POST';

  dispatch({ type: 'setNotification', key: 'notification', value: 'start sweepAssetsSequence.' });

  // sweep元アドレス取得

  result = getAddressFromKey(state, dispatch, keyPair);

  if (result.status === 'fulfilled') {
    addressFrom = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return result;
  }

  // トランザクション作成

  bodyObject = {
    "jsonrpc":"2.0",
    "id":0,
    "method":"proxy_to_counterpartyd",
    "params":{
      "method":"create_sweep",
      "params": {
        "source": addressFrom,
        "destination": addressTo,
        "flags": sweepFlag,
      }
    }
  };

  requestToCounterblockMona.body = JSON.stringify(bodyObject);

  let buildTransactionRetryCount = 0;

  while (response.status !== 'fulfilled') {
    requestToCounterblockMona.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    console.log('request create_sweep', JSON.stringify(requestToCounterblockMona));

    response = await syncHttpRequest(requestToCounterblockMona);
    console.log('response', JSON.stringify(response));

    if (response.status === 'rejected') {
      devLog('create_sweep to counterpartyd; rejected');
      dispatch({ type: 'setNotification', key: 'notification', value: 'create_sweep to counterpartyd; rejected' });
      buildTransactionRetryCount++;

      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        serverIndex = 0;
      }
      else {
        serverIndex++;
      }

      await sleep(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
      // await setTimeout(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
      // keepSqsMessage = true;
      // continue;
    }
    else {
      transactionUnsigned = response.body.result;
      devLog('transactionUnsigned', transactionUnsigned);
      dispatch({ type: 'setNotification', key: 'notification', value: 'create_sweep to counterpartyd; succeeded ' + JSON.stringify(response.body) });
    }

    if (buildTransactionRetryCount > state.config.clientParameters.retry.buildTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('build transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'build transaction retry over.' });
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to build transaction.' });
      return result;
    }
  }


  // トランザクション署名

  result = {};
  let signTransactionRetryCount = 0;

  while (result.status !== 'fulfilled') {
    result = signTransaction(transactionUnsigned, keyPair);
    devLog('sign transaction; result', JSON.stringify(result));

    if (result.status === 'fulfilled') {
      devLog('succeeded to sign transaction.');
      transactionSigned = result.body;
      dispatch({ type: 'setNotification', key: 'notification', value: 'succeeded to sign transaction. ' + transactionSigned });
    }
    else {
      devLog('failed to sign transaction.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to sign transaction.' });
      signTransactionRetryCount++;
      // await setTimeout(state.config.clientParameters.retry.signTransaction.retryWaitingTime);
      await sleep(state.config.clientParameters.retry.signTransaction.retryWaitingTime);
    }

    if (signTransactionRetryCount > state.config.clientParameters.retry.signTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('sign transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to sign transaction.' });
      return result;
    }
  }

  // ブロードキャスト

  result = {};
  let broadcastTransactionRetryCount = 0;

  while (result.status !== 'fulfilled') {
    result = await broadcastTransaction(state, transactionSigned);
    devLog('broadcast transaction; result', JSON.stringify(result));

    if (result.status === 'fulfilled') {
      devLog('succeeded to broadcast transaction.');
      txid = result.body;
      dispatch({ type: 'setNotification', key: 'notification', value: 'succeeded to broadcast transaction. ' + txid });
    }
    else {
      devLog('failed to broadcast transaction.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to broadcast transaction.' });
      broadcastTransactionRetryCount++;
      // await setTimeout(state.config.clientParameters.retry.broadcastTransaction.retryWaitingTime);
      await sleep(state.config.clientParameters.retry.broadcastTransaction.retryWaitingTime);
    }

    if (broadcastTransactionRetryCount > state.config.clientParameters.retry.broadcastTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('send transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to broadcast transaction.' });
      return result;
    }
  }

  return {
    status: 'fulfilled',
    body: txid,
  };
}

// SWEEP MONA
async function sweepMona(state, dispatch, keyPair, addressTo, { sweepFlag = 3 } = {}) {
  let result;
  let addressFrom;
  let utxos;
  let allMonaBalance;
  let feeRate;
  let unsignedTxHex;
  let signedTxHex;
  let txid;

  const provisionalTransactionFee = state.config.clientParameters.provisionalTransactionFee;
  const transactionFeeUpperBound = state.config.clientParameters.transactionFeeUpperBound;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'tra MONA' }); // 暫定

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, keyPair);

  if (result.status === 'fulfilled') {
    addressFrom = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    throw new Error(result);
  }

  devLog('provisionalTransactionFee', provisionalTransactionFee);

  // UTXO取得
  result = await getUtxo(state, addressFrom);

  if (result.status === 'fulfilled') {
    utxos = result.body;
  }
  else {
    devLog('failed to get UTXO.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + result.error });
    return {
      status: 'rejected',
      error: 'failed to get UTXO.',
    };
  }

  // UTX raw transaction取得
  result = await getRawTransaction(state, utxos);

  if (result.status === 'rejected') {
    devLog('failed to get unspent raw transactions.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3' });
    return {
      status: 'rejected',
      error: 'failed to get unspent raw transactions.',
    };
  }

  // 全MONA計算

  result = await getAddressInfo(state, addressFrom);

  if (result.status === 'fulfilled') {
    allMonaBalance = result.body.balance;
  }
  else {
    devLog('failed to get address info.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });

    return {
      status: 'rejected',
      error: 'failed to get address info.',
    };
  }

  // const allMonaBalance = utxos.reduce( (acc, cur) => {
  //   cur.vout
  //   acc + cur
  // }, 0);

  // fee rate取得
  result = await getFeeRate(state);

  if (result.status === 'fulfilled') {
    feeRate = result.body;
  }
  else {
    devLog('failed to get fee rate.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '4' });
    return {
      status: 'rejected',
      error: 'failed to get fee rate.',
    };
  }

  // 概算バイト数計算
  const approximatedTransactionBytes = 1000;

  // transactionFee計算

  const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
  const transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(approximatedTransactionBytes).toNumber(), 0 ); 
  devLog(`transactionFee ${transactionFee}`);

  // transactionFee歯止め
  if (transactionFee > transactionFeeUpperBound) {
    devLog('transactionFee is too high.');
    return {
      status: 'rejected',
      error: 'transactionFeeIsTooHigh',
    };
  }

  // トランザクション作成
  const inputs = utxos.map( utxo => {
    return {
      txid: utxo.txid,
      vout: utxo.vout,
      rawTransaction: utxo.rawTransaction,
    };
  });

  devLog('inputs', JSON.stringify(inputs));

  const outputs = [
    {
      address: addressTo,
      amount: allMonaBalance - transactionFee,
    },
  ];

  devLog('outputs', JSON.stringify(outputs));

  result = buildUnsignedTransactionSendMona(inputs, outputs);

  if (result.status === 'fulfilled') {
    unsignedTxHex = result.body;
  }
  else {
    devLog('failed to build transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '6' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // トランザクション署名
  result = signTransaction(unsignedTxHex, keyPair);

  if (result.status === 'fulfilled') {
    signedTxHex = result.body;
  }
  else {
    devLog('failed to sign transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '7' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // ブロードキャスト
  result = await broadcastTransaction(state, signedTxHex);

  if (result.status === 'fulfilled') {
    txid = result.body;
  }
  else {
    devLog('failed to broadcast transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '8' });
    return {
      status: 'rejected',
      error: 'failed to broadcast transaction.',
    };
  }

  return {
    status: 'fulfilled',
    body: txid,
  };
}

// GET UTXO
async function getUtxo(state, address) {
  let serverIndex = 0;

  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.utxoPath}${address}`;
    devLog('get UTXO; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get UTXO; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      const utxos = response.body;
      devLog('utxos', JSON.stringify(utxos));
      return {
        status: 'fulfilled',
        body: utxos,
      };
    }
    else {
      devLog('get UTXO; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.body + request.url,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// SELECT UTXO MONA
// モナコインUTXO選定
// -- utxosは昇順ソート
function selectUtxoMona(utxos, amount) {
  devLog('selectUtxoMona', JSON.stringify(utxos), amount);
  const minimumGrater= minimumGraterUtxo(utxos, amount);
  devLog('minimumGrater', JSON.stringify(minimumGrater));

  if (minimumGrater !== null) {
    return [minimumGrater];
  }
  else {
    if (utxos.length <= 1) {
      return null;
    }

    const maximum = utxos.pop();
    devLog('maximum', JSON.stringify(maximum));
    const supplement = selectUtxoMona(utxos, amount - maximum.amount);

    if (supplement !== null) {
      supplement.unshift(maximum);
      return supplement;
    }
    else {
      return null;
    }
  }
}

function minimumGraterUtxo(utxos, bound) {
  devLog('minimumGraterUtxo', JSON.stringify(utxos), bound);
  let result = null;

  for(const utxo of utxos) {
   if (utxo.amount >= bound) {
     result = utxo;
     break;
   } 
  }

  return result;
}

// SELECT UTXO WATANABE
// モナコインUTXO選定
// -- utxosは昇順ソート
function selectUtxoWatanabe(utxos, amount) {
  devLog('selectUtxoWatanabe', JSON.stringify(utxos), amount);
  const minimumGrater= minimumGraterUtxoWatanabe(utxos, amount);
  devLog('minimumGrater', JSON.stringify(minimumGrater));

  if (minimumGrater !== null) {
    return [minimumGrater];
  }
  else {
    if (utxos.length <= 1) {
      return null;
    }

    const maximum = utxos.pop();
    devLog('maximum', JSON.stringify(maximum));
    const supplement = selectUtxoWatanabe(utxos, amount - maximum.satoshis);

    if (supplement !== null) {
      supplement.unshift(maximum);
      return supplement;
    }
    else {
      return null;
    }
  }
}

function minimumGraterUtxoWatanabe(utxos, bound) {
  devLog('minimumGraterUtxo', JSON.stringify(utxos), bound);
  let result = null;

  for(const utxo of utxos) {
   if (utxo.satoshis >= bound) {
     result = utxo;
     break;
   } 
  }

  return result;
}

// GET TRANSACTION
async function getTransaction(state, txid) {
  let transaction;
  let serverIndex = 0;

  // transaction取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.transactionDetailPath}${txid}`;
    devLog('get transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      transaction = response.body;
      devLog('transaction', JSON.stringify(transaction));

      return {
        status: 'fulfilled',
        body: transaction,
      };
    }
    else {
      devLog('get transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET RAW TRANSACTION
async function getRawTransaction(state, utxosSelected) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    for (const utxo of utxosSelected) {
      request.body = `{"jsonrpc":"2.0","id":0,"method":"proxy_to_counterpartyd","params":{"method":"getrawtransaction","params":{"tx_hash":"${utxo.txid}"}}}`;
      devLog('get UTX raw transaction; request', JSON.stringify(request));

      const response = await syncHttpRequest(request);
      devLog('get UTX raw transaction; response', JSON.stringify(response));

      if ( response.status === 'rejected' || response.body.error !== undefined ) {
        devLog('get UTXO; rejected');
        const error = response.status === 'rejected' ? response.error : response.body.error;

        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          // -- 失敗 エラー返却
          return {
            status: 'rejected',
            error: error
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }

      utxo.rawTransaction = response.body.result;
    }

    break SERVER;
  }

  return {
    status: 'fulfilled',
    body: utxosSelected,
  };
}

// GET ADDRESS INFO
async function getAddressInfo(state, address) {
  let addressInfo;
  let serverIndex = 0;

  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.addressPath}${address}?details=basic`;
    devLog('get address info; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get address info; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      addressInfo = response.body;
      devLog('address info', JSON.stringify(addressInfo));

      return {
        status: 'fulfilled',
        body: addressInfo,
      };
    }
    else {
      devLog('get address info; rejected', response.error);

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET FEE RATE
async function getFeeRate(state) {
  let feeRate;
  let serverIndex = 0;

  // fee rate取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };
  // delete request.body;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.feePath}${state.config.clientParameters.blockbook.feeBlocks}`;
    devLog('get fee rate; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get fee rate; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      if (response.body.result === '0') {
        feeRate = 0.002;
      }
      else {
        feeRate = toFloat(response.body.result);
      }

      devLog('feeRate', feeRate);

      return {
        status: 'fulfilled',
        body: feeRate,
      };
    }
    else {
      devLog('get fee rate; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// CALCULATE CHANGE WATANABE
function calculateChangeWatanabe(utxosSelected, baseAmountWatanabe, feeRate, virtualSize, transactionFeeUpperBound) {
  // 概算バイト数計算

  const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
  const transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(virtualSize).toNumber(), 0 ); 
  const amountToPay = baseAmountWatanabe + transactionFee;
  const change = utxosSelected.reduce( (acc, cur) => acc + cur.satoshis, 0 ) - amountToPay;
  devLog(`transactionFee ${transactionFee}, amountToPay ${amountToPay}, change ${change}`);

  // transactionFee歯止め

  if (transactionFee > transactionFeeUpperBound) {
    devLog('transactionFee is too high.');
    return {
      status: 'rejected',
      error: 'transactionFeeIsTooHigh',
    };
  }

  return {
    status: 'fulfilled',
    body: {
      change,
      transactionFee,
    },
  };
}

// BUILD UNSIGNED TRANSACTION SEND MONA
function buildUnsignedTransactionSendMona(inputs, outputs) {
  try {
    console.log('A');

    const txb = new bitcoin.TransactionBuilder(networkMona);

    console.log('B');

    for (const input of inputs) {
      const inputTransaction = bitcoin.Transaction.fromHex(input.rawTransaction);
      console.log(JSON.stringify(inputTransaction.outs[input.vout].script));
      input.scriptPubkey = inputTransaction.outs[input.vout].script;
      console.log(input.scriptPubkey);
      txb.addInput(input.txid, input.vout, null, input.scriptPubkey);
    }

    console.log('C');

    for (const output of outputs) {
      txb.addOutput(output.address, output.amount);
    }

    console.log('D');

    const unsignedTx = txb.buildIncomplete();

    console.log('E');
    console.log(inputs[0].scriptPubkey);

    for (let i = 0; i <= unsignedTx.ins.length - 1; i++) {
      console.log(i);
      unsignedTx.ins[i].script = inputs[i].scriptPubkey;
    }

    console.log('F');

    const unsignedTxHex = unsignedTx.toHex();

    return {
      status: 'fulfilled',
      body: unsignedTxHex,
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// SIGN TRANSACTION
function signTransaction(txHex, keyPair, wif) {
  try {
    if (keyPair === undefined || keyPair === null) {
      keyPair = bitcoin.ECPair.fromWIF(wif, networkMona);
    }

    const tx = bitcoin.Transaction.fromHex(txHex);   // The unsigned second part of the 2 part P2SH transactions
    const txb = bitcoin.TransactionBuilder.fromTransaction(tx, networkMona);

    for (let i = 0; i < tx.ins.length; i++) {
      txb.sign(i, keyPair);
    }

    const signedTxHex = txb.build().toHex();

    console.log('inner result', signedTxHex);

    return {
      status: 'fulfilled',
      body: signedTxHex, // The resulting signed transaction in raw hex, ready to be broadcasted
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// GET OUTPUT VALUE TOTAL
function getOutputValueTotal(txHex) {
  try {
    const tx = bitcoin.Transaction.fromHex(txHex);
    const outputValueTotal = tx.outs.reduce( (acc, cur) => acc + cur.value, 0 );

    return {
      status: 'fulfilled',
      body: outputValueTotal,
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// BROADCAST TRANSACTION
async function broadcastTransaction(state, signedTxHex) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';
  request.headers = {
    'Accept': 'application/json',
  };
  request.body = signedTxHex;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.sendTransactionPath}`;
    devLog('broadcast transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('broadcast transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' && response.body.result !== undefined ) {
      const txid = response.body.result;
      devLog('txid', txid);

      return {
        status: 'fulfilled',
        body: txid,
      };
    }
    else {
      devLog('broadcast transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

async function handleClickSendMona(state, dispatch, popupLayer) {
  let request = {};
  let response = {};
  let stateFixed = {};
  let messages = {};
  let utxos;
  let utxosSelected;
  let approximatedTransactionBytes;
  let feeRate;
  let transactionFee;
  let change;
  let amountToPay;
  let inputs;
  let outputs;
  let unsignedTransaction;

  // state固定
  if (popupLayer !== undefined && popupLayer !== null) {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.popup[popupLayer].body.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.popup[popupLayer].body.priceMona,
      priceMonaWatanabe: state.popup[popupLayer].body.priceMonaWatanabe,
      proceedsNetMona: state.popup[popupLayer].body.proceedsNetMona,
      royaltyMona: state.popup[popupLayer].body.royaltyMona,
      feeMona: state.popup[popupLayer].body.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
    };
  }
  else {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.itemDetail.item.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.itemDetail.item.priceMona,
      priceMonaWatanabe: state.itemDetail.item.priceMonaWatanabe,
      proceedsNetMona: state.itemDetail.item.proceedsNetMona,
      royaltyMona: state.itemDetail.item.royaltyMona,
      feeMona: state.itemDetail.item.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
    };
  }

  if (window.mpurse) {
    // Mpurseのアドレスが合ってるかチェック
    let result = await window.mpurse.getAddress()
      .then( (result) => {
        if (result !== stateFixed.addressPayFrom) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      })
      .catch( (error) => {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
          devLog('CNBG', error);
          return 'rejected';
      });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // UTXO取得

    // request.url = `https://${state.config.blockbook.domain}${state.config.blockbook.utxoPath}${stateFixed.addressPayFrom}`;
    // request.method = 'GET';
    // request.headers = {
    //   'Accept': 'application/json',
    // };

    // devLog('get UTXO; request', JSON.stringify(request));

    // response = await syncHttpRequest(request);

    response = await getUtxo(state, stateFixed.addressPayFrom);

    if ( response.status === 'fulfilled' ) {
      utxos = response.body;
      devLog('utxos', JSON.stringify(utxos));
    }
    else {
      devLog('get UTXO; rejected');
      return { status: 'rejected' };
    }

    // UTXO選定
    devLog('priceMona', stateFixed.priceMona, 'amountToBuy', stateFixed.amountToBuy, 'provisionalTransactionFee', stateFixed.provisionalTransactionFee);

    utxos.sort( (a, b) => a.amount - b.amount );
    utxosSelected = selectUtxoMona(utxos, stateFixed.priceMona * stateFixed.amountToBuy + stateFixed.provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
    devLog('utxosSelected', JSON.stringify(utxosSelected));

    if (utxosSelected === null) {
      devLog('short of MONA');
      return { status: 'rejected' };
    }

    // UTX raw transaction取得
    result = await getRawTransaction(state, utxosSelected);

    // request.url = 'https://monapa.electrum-mona.org/_api';
    // // request.url = 'https://wallet.monaparty.me/_api';
    // // request.url = 'https://counterblock.api.monaparty.me/';
    // request.method = 'POST';

    // for (const utxo of utxosSelected) {
    //   request.body = `{"jsonrpc":"2.0","id":0,"method":"proxy_to_counterpartyd","params":{"method":"getrawtransaction","params":{"tx_hash":"${utxo.txid}"}}}`;
    //   devLog('get UTX raw transaction; request', JSON.stringify(request));

    //   response = await syncHttpRequest(request);
    //   devLog('get UTX raw transaction; response', JSON.stringify(response));

    //   utxo.rawTransaction = response.body.result;
    // }

    // 概算バイト数計算
    approximatedTransactionBytes = 1000;

    // fee rate取得

    // request.url = `https://${state.config.blockbook.domain}${state.config.blockbook.feePath}${state.config.blockbook.feeBlocks}`;
    // request.method = 'GET';
    // request.headers = {
    //   'Accept': 'application/json',
    // };
    // delete request.body;

    // devLog('get fee rate; request', JSON.stringify(request));

    // response = await syncHttpRequest(request);

    response = await getFeeRate(state);

    if ( response.status === 'fulfilled' ) {
      // if (response.body.result === '0') {
      //   feeRate = 0.002;
      // }
      // else {
      //   feeRate = toFloat(response.body.result);
      // }

      feeRate = response.body;
      devLog('feeRate', feeRate);
    }
    else {
      devLog('get fee rate; rejected');
      return { status: 'rejected' };
    }

    // transaction fee, change, amountToPay計算 (transactionFeeはここまで丁寧にする必要なし。)
    const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
    transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(approximatedTransactionBytes).toNumber(), 0 ); 
    amountToPay = stateFixed.priceMonaWatanabe * stateFixed.amountToBuy + transactionFee;
    change = utxosSelected.reduce( (acc, cur) => acc + cur.satoshis, 0 ) - amountToPay;
    devLog(`transactionFee ${transactionFee}, amountToPay ${amountToPay}, change ${change}`);

    // transactionFee歯止め
    if (transactionFee > stateFixed.transactionFeeUpperBound) {
      devLog('transactionFee is too high.');
      return { status: 'rejected' };
    }

    // トランザクション作成
    inputs = utxosSelected.map( utxo => {
      return {
        txid: utxo.txid,
        vout: utxo.vout,
        rawTransaction: utxo.rawTransaction,
      };
    });

    devLog('inputs', JSON.stringify(inputs));

    if (stateFixed.royaltyMona > 0) {
      outputs = [
        {
          address: stateFixed.addressPayProceedsTo,
          amount: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayRoyaltyTo,
          amount: stateFixed.royaltyMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.monacottoAddressMona,
          amount: stateFixed.feeMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayFrom,
          amount: change,
        },
      ];
    }
    else {
      outputs = [
        {
          address: stateFixed.addressPayProceedsTo,
          amount: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.monacottoAddressMona,
          amount: stateFixed.feeMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayFrom,
          amount: change,
        },
      ];
    }

    devLog('outputs', JSON.stringify(outputs));

    request.body = JSON.stringify({
      body: {
        inputs: inputs,
        outputs: outputs,
      }
    });

    request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/build_transaction_mona';
    request.method = 'POST'; // ← 使われない
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }; // ← 使われない

    messages = {
    };

    response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);
    devLog('build transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      unsignedTransaction = response.body.unsignedTxHex;
    }
    else {
      devLog('failed to build transaction.');
      return { status: 'rejected' };
    }

    devLog('unsignedTransaction bytes', (new Blob([unsignedTransaction])).size);

    // GO!
    // ポップアップで送金内容確認してもらったらGO!
    const popup = {
      type: 'sendMonaConfirmation',
      body: {
        addressPayProceedsTo: stateFixed.addressPayProceedsTo,
        addressPayRoyaltyTo: stateFixed.addressPayRoyaltyTo,
        monacottoAddressMona: stateFixed.monacottoAddressMona,
        proceedsNetMona: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        royaltyMona: stateFixed.royaltyMona * stateFixed.amountToBuy,
        feeMona: stateFixed.feeMona * stateFixed.amountToBuy,
        transactionFee: transactionFee,
      },
    };

    if (wallet === 'MonaPallet') {
      popup.extendedClassesBackGround = 'alignItemsFlexStart';
    }
    else { // Mpurse
    }

    dispatch({ type: 'setStateMultiLayers', keys: ['popup', (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0], value: popup });

    result = await window.mpurse.sendRawTransaction(unsignedTransaction)
      .then( async (result) => {
        devLog(result);
        // devLog('signedTransaction bytes', (new Blob([signedTransaction])).size);

        // purchase状態更新(部分クリア)
        dispatch({ type: 'setState', key: 'purchase',
          value: {
            purchaseNo: null,
            addressMain: state.purchase.addressMain,
            addressPayFrom: state.purchase.addressPayFrom,
            addressSendCardTo: state.purchase.addressSendCardTo,
            addressMainExhibitor: '',
            exhibitNo: '',
            amountToBuy: state.purchase.amountToBuy,
            signatureByAddressMain: '',
            signatureByAddressPayFrom: '',
            addressPayRoyaltyTo: null,
            disabled: false,
            status: 'waitingForSignature',
          }
        });

        // notification
        dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + result });

        // トークン送信成功通知
        request.body = JSON.stringify({
          "body": {
            addressMain: stateFixed.addressMain,
            purchaseNo: stateFixed.purchaseNo,
            signatureByAddressMain: stateFixed.signatureByAddressMain,
            sendMonaTxid: result,
          }
        });
        request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/notify_sending_mona';
        request.method = 'POST';
        messages = {
        };

        const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);
        return 'fulfilled';
      })
      .catch( (error) => {
        devLog(error);
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
        return 'rejected';
      });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });
    return { status: 'rejected' };
  }

  return { status: 'fulfilled' };
}

// GET REGISTERED CARD
async function getRegisteredCard(state, dispatch) {
  let request = {};
  let messages = {};
  const registeredCardsIndex = {};

  // state固定
  const stateFixed = {
  };

  request.body = JSON.stringify({
    "body": {
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_registered_card';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

  if ( response.status === 'rejected' ) {
    return {
      status: 'rejected',
    };
  }

  for (const registeredCard of response.body) {
    registeredCardsIndex[registeredCard.asset] = registeredCard;
  }

  dispatch({ type: 'setState', key: 'registeredCard', value: registeredCardsIndex });
  devLog('registeredCard', JSON.stringify(registeredCardsIndex));

  return {
    status: 'fulfilled',
    body: registeredCardsIndex,
  };
}


// NFCボタン押下 HANDLE CLICK NFC
async function handleClickNfc(state, dispatch, callback, { args = ['keyPairs'], recordNos = [1] } = {}) {
  if ('NDEFReader' in window) {
    const ndef = new window.NDEFReader();
    await ndef.scan();

    ndef.onreading = async event => {
      console.log("NDEF tag detected");

      const keyPairs = [];
      const mnemonics = [];

      const argumentsAll = { keyPairs, mnemonics };
      const argumentsToPass = args.map( arg => argumentsAll[arg] );

      for (const [index, record] of event.message.records.entries()) {
        if (!recordNos.includes(index)) {
          continue;
        }

        try {
          const textDecoder = new TextDecoder(record.encoding);
          const mnemonicEncrypted = textDecoder.decode(record.data);
          const pass = state.config.clientParameters.specialMessage;
          // const pass = undefined;
          const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
          const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
          const mnemonic = await decrypt(state, dispatch, mnemonicEncrypted, pass, salt, iv);
          // const mnemonic = textDecoder.decode(record.data);
          // const mnemonic = undefined;
          mnemonics.push(mnemonic);
          const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
          // const seed = bip39.mnemonicToSeedSync(mnemonic);
          // const root = bip32.fromSeed(seed)
          // const path = "m/44'/22'/0'/0/0";
          // const leaf = root.derivePath(path);
          // const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
          keyPairs.push(keyPair);
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: getAddressFromKey(state, dispatch, keyPair).address }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: JSON.stringify(keyPair) }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: typeof mnemonic }); // 暫定
          // const payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
          // const message = 'hoge';
          // let randomBytes = new Uint8Array(32);
          // window.crypto.getRandomValues(randomBytes);
          // const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) });
          // console.log(`Record type: ${record.recordType}`);
          // console.log(`MIME type: ${record.mediaType}`);
          // console.log(`Data: ${textDecoder.decode(record.data)}`);
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: signature.toString('base64')}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: payment.address}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: keyPair.toWIF()}); 

          // const encryptedMessage = 'kR10Y9tegf2qDkjOFAxK7/YuXX4J4vgHiI3uxaQJQPDVnfc0pR+j2XrK3cn8cJRMwEl9B6DT2ZDcKr2SUxehhe6OcAO57nYLLSOZe+JVP0p4uoMUctfZeoU40Bo2lVjZHnhKd9+zEeDGuqbMXz6ToccqQAa2426B44W5RGRa+9we/Zts5adaalZZu1vnpdn74w3UjBy+ggEwsuu03mjQqLjl1mBB97PaOOdG+XBiczc=';
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: mnemonic.toString() }); 
          // const encryptedU8A = Uint8Array.from(window.atob(mnemonicEncrypted), byte => byte.charCodeAt(0));
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'name'], value: mnemonicEncrypted }); 
        }
        catch (error) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        }
      }

      // 先に後処理やっちゃう。
      ndef.onreading = null;
      ndef.onreadingerror = null;

      if (argumentsToPass.some( arg => arg.length === 0 )) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        throw new Error('noArgumentToPass');
      }

      callback(...argumentsToPass);
    };

    ndef.onreadingerror = () => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });

      ndef.onreading = null;
      ndef.onreadingerror = null;
    };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
  }
}

// NFC読み込み READ NFC
async function readNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error);
  });

  return result;
}

// NFC書き込み WRITE MNEMONIC ON NFC
async function writeMnemonicOnNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error + encryptedMnemonic);
  });

  return result;
}

// NFC書き込んだニーモニックチェック CHECK MNEMONIC ON NFC
function checkMnemonicOnNfc(state, dispatch, mnemonicToBeWritten, mnemonicsWritten) {
  if (mnemonicToBeWritten === mnemonicsWritten[0]) {
    localStorage.setItem('swapStatus', 'checkedMnemonic');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic OK.' }); // 暫定
  }
  else {
    localStorage.setItem('swapStatus', 'sweptMona');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic NG.' }); // 暫定
  }
}

// mnemonicから秘密鍵取得 GET KEYPAIRS FROM MNEMONIC
function getKeyPairsFromMnemonic(mnemonic) {
  let keyPairs = [];

  try {
    const seed = bip39.mnemonicToSeedSync(mnemonic);
    const root = bip32.fromSeed(seed)
    const path = "m/44'/22'/0'/0/0";
    const leaf = root.derivePath(path);
    const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
    keyPairs.push(keyPair);
  }
  catch (error) {
    devLog('getKeyPairsFromMnemonic ; error', error);
    throw new Error('cannotGetKeyPairsFromMnemonic');
  }

  return keyPairs;
}

// 鍵渡してcertificationつくる CERTIFICATION WITH KEY
function certificationWithKey(state, dispatch, keyPairs, messageType, stateFixed, certifications, clientTime, extendedCertificationProperties, signingMode) {
  let addressIsSpecified;

  // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
  // addressMainが指定されていないということは、後ページ検索ではない。
  // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。
    
  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    addressIsSpecified = true;
  }
  else {
    addressIsSpecified = false;
  }

  if (signingMode === 'firstKey') {
    keyPairs = [ keyPairs[0] ];
  }
  else { // matchedKey
  }

  for (const keyPair of keyPairs) {
    // アドレス導出
    let payment;
    let address;
    let addressActual;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      addressActual = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    if (signingMode === 'matchedKey') {
      if (addressActual !== stateFixed.addressMain) {
        continue;
      }
      else {
        address = stateFixed.addressMain;
      }
    }
    else { // firstKey
      if (addressIsSpecified) {
        if (checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          address = stateFixed.addressMain;
        }
        else {
          continue;
        }
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }
    }

    // 渡された鍵ペアに対応するアドレスがcertificationsに含まれていなければ、署名する。
    if (address !== undefined && address !== null && address !== '' && !certifications.map( certification => certification.address ).includes(address)) {
      let message;

      if (messageType === 'login') {
        message = `I want to log in to monashell as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }
      else if (messageType === 'information') {
        message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }

      devLog('message', message);

      let signatureMona;

      const result = signWithKey(state, dispatch, keyPair, message);

      if (result.status === 'fulfilled') {
        signatureMona = result.signature;
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        continue;
      }

      const certification = {
        // addressCoinType: 'mona',
        address: address,
        addressActual: addressActual,
        clientTime: clientTime,
        signatureVersion: stateFixed.signatureVersion,
        signature: signatureMona,
        lastEvaluatedKey: stateFixed.lastEvaluatedKey,
      };

      // 拡張プロパティ
      if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
        for (const property of extendedCertificationProperties) {
          certification[property.key] = property.value;
        }
      }

      devLog('certification given key', JSON.stringify(certification));

      certifications.push(certification);
    }
  }
}

// CHECK IF ADDRESS IS MATCHED WITH AN ADDRESS
function checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, address, addressCheck) {
  if (addressCheck === 'strict' || addressCheck === 'addressSecond') {
    if (addressActual !== address) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return false;
    }
    else {
      return true;
    }
  }
  else { // off
    return true;
  }
}

// アドレス抽出 GET ADDRESS FROM KEY
function getAddressFromKey (state, dispatch, keyPair) {
  // アドレス導出
  let payment;
  let address;

  try {
    payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
    address = payment.address;

    return {
      status: 'fulfilled',
      address: address,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });

    return {
      status: 'rejected',
    };
  }
}

// 鍵渡して署名 SIGN WITH KEY
function signWithKey(state, dispatch, keyPair, message) {
  let signatureMona;

  try {
    let randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    signatureMona = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) }).toString('base64');

    return {
      status: 'fulfilled',
      signature: signatureMona,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });

    return {
      status: 'rejected'
    };
  }

}

// DECRYPT
async function decrypt(state, dispatch, encryptedData, password, salt, iv) {
  try {
    // 暗号化データをUint8Arrayに変換
    const encryptedU8A = Uint8Array.from(window.atob(encryptedData), byte => byte.charCodeAt(0));

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
    );

    // データを復号
    const decryptedABuff = await window.crypto.subtle.decrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        encryptedU8A
    );

    // 復号されたデータを文字列に変換
    const decrypted = new TextDecoder().decode(decryptedABuff);

    // トリム
    const decryptedTrimmed = decrypted.trim();

    return decryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Decryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToDecrypt[state.language] });
    throw e;
  }
}

// ENCRYPT
async function encrypt(state, dispatch, plaintext, password, salt, iv) {
  try {
    // 平文データをUint8Arrayに変換
    // const plaintextU8A = Uint8Array.from(window.atob(plaintext), byte => byte.charCodeAt(0));
    const plaintextU8A = new TextEncoder().encode(plaintext);

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["encrypt"]
    );

    // データを暗号化
    const encryptedABuff = await window.crypto.subtle.encrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        plaintextU8A
    );

    // 暗号化されたデータを文字列に変換
    // const encrypted = new TextDecoder().decode(encryptedABuff);
    const encrypted = arrayBufferToBase64(encryptedABuff);

    // トリム
    const encryptedTrimmed = encrypted.trim();

    dispatch({ type: 'setNotification', key: 'notification', value: 'encryptedTrimmed ' + encryptedTrimmed });
    return encryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Encryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToEncrypt[state.language] });
    // throw e;
  }
}

// QRボタン押下 HANDLE CLICK SCAN QR
function handleClickScanQr(state, dispatch, callback, { args = ['keyPairs'], recordNos = [0], callbackType = 'decryptMnemonic', popupLayer = 0 } = {}) {
  const popup = {
    type: 'generalItems',
    body: <QrCodeScanner popupLayer={popupLayer} callback={callback} callbackType={callbackType} options={ {args, recordNos} } />,
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: popup });
}

// DO SOMETHING WITH ENCRYPTED MNEMONICS
async function doSomethingWithEncryptedMnemonics(state, dispatch, mnemonicsEncrypted, callback, { args = ['keyPairs'], recordNos = [0] } = {}) {
  const keyPairs = [];
  const mnemonics = [];

  const argumentsAll = { keyPairs, mnemonics };
  const argumentsToPass = args.map( arg => argumentsAll[arg] );

  for (const recordNo of recordNos) {
    try {
      const pass = state.config.clientParameters.specialMessage;
      const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
      const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
      const mnemonic = await decrypt(state, dispatch, mnemonicsEncrypted[recordNo], pass, salt, iv);
      mnemonics.push(mnemonic);
      const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
      keyPairs.push(keyPair);
    }
    catch (error) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    }
  }

  if (argumentsToPass.some( arg => arg.length === 0 )) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    throw new Error('noArgumentToPass');
  }

  callback(...argumentsToPass);
}

// HANDLE CLICK GENERATE MNEMONIC
function handleClickGenerateMnemonic (state, dispatch) {
  const mnemonic = bip39.generateMnemonic(256);
  dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'addressMain'], value: mnemonic }); 

  const keyPairs = getKeyPairsFromMnemonic(mnemonic);
  const result = getAddressFromKey(state, dispatch, keyPairs[0]);
  let address;

  if (result.status === 'fulfilled') {
    address = result.address;
    dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'userName'], value: address }); 

    return {
      status: 'fulfilled',
      mnemonic: mnemonic,
      keyPairs: keyPairs,
      address: address,
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });

    return {
      status: 'rejected',
      mnemonic: mnemonic,
      keyPairs: keyPairs,
    }
  }
}

// トークン数量（表示用）
function quantityForDisplay(state, item, quantity) {
  let quantityRaw;
  let quantityForDisplay;

  if (quantity !== undefined && quantity !== null) {
    quantityRaw = quantity;
  }
  else {
    quantityRaw = item.quantity;
  }

  if (state.assetInfo[item.asset]?.divisible === true) {
    quantityForDisplay = new decimal(quantityRaw).div(100000000).toNumber();
  }
  else if (item.divisible === true) {
    quantityForDisplay = new decimal(quantityRaw).div(100000000).toNumber();
  }
  else {
    quantityForDisplay = quantityRaw;
  }

  return quantityForDisplay;
}

// MONAをカゴに入れる
// ADD SENDS TO THE CART MONA
function addSendsToTheCartMona(state, dispatch, addressFrom, addressTo, quantity) {
  const actionItemsMultiSources = state.cartMona;
  let done;

  if (actionItemsMultiSources[addressFrom] === undefined) {
    actionItemsMultiSources[addressFrom] = [];
  }

  for (const actionItem of actionItemsMultiSources[addressFrom]) {
    if (actionItem.action === 'send') {
      actionItem.recipients.push(
        {
          addressTo: addressTo,
          amountWatanabe: quantity,
        },
      );

      done = true;
      break;
    }
  }

  if (!done) {
    actionItemsMultiSources[addressFrom].push(
      {
        transactionType: 'sendMona',
        action: 'send',
        status: 'unprocessed',
        addressFrom: addressFrom,
        recipients: [
          {
            addressTo: addressTo,
            amountWatanabe: quantity,
          },
        ],
      },
    );
  }

  dispatch({ type: 'setState', key: 'cartMona', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// モナパトークンをカゴに入れる
// ADD TOKENS TO THE CART MONAPARTY
function addTokensToTheCartMonaparty(state, dispatch, addressFrom, addressTo, asset, quantity) {
  const actionItemsMultiSources = state.cartMonaparty;
  let done;

  if (actionItemsMultiSources[addressFrom] === undefined) {
    actionItemsMultiSources[addressFrom] = [];
  }

  for (const actionItem of actionItemsMultiSources[addressFrom]) {
    if (actionItem.action === 'send') {
      if (actionItem.recipients[addressTo] === undefined) {
        actionItem.recipients[addressTo] = {};
      }

      if (actionItem.recipients[addressTo][asset] === undefined) {
        actionItem.recipients[addressTo][asset] = { quantity: 0 };
      }

      actionItem.recipients[addressTo][asset].quantity += quantity;

      done = true;
      break;
    }
  }

  if (!done) {
    actionItemsMultiSources[addressFrom].push(
      {
        transactionType: 'sendToken',
        action: 'send',
        status: 'unprocessed',
        addressFrom: addressFrom,
        recipients: {
          [addressTo]: {
            [asset]: {
              quantity: quantity,
            },
          },
        },
      },
    );
  }

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// アセット発行をカゴに入れる
// ADD ISSUANCE TO THE CART MONAPARTY
function addIssuanceToTheCartMonaparty(state, dispatch, addressIssuer, assetCommon, quantity, description, divisible, listed, vendable, reassignable, assetType) {
  const actionItemsMultiSources = state.cartMonaparty;
  let done;

  if (actionItemsMultiSources[addressIssuer] === undefined) {
    actionItemsMultiSources[addressIssuer] = [];
  }

  actionItemsMultiSources[addressIssuer].push(
    {
      transactionType: 'monaparty',
      action: 'issue',
      status: 'unprocessed',
      addressIssuer: addressIssuer,
      assetCommon: assetCommon,
      quantity: quantity,
      description: description,
      divisible: divisible,
      listed: listed,
      vendable: vendable,
      reassignable: reassignable,
      assetType: assetType,
    },
  );

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// モナカード更新をカゴに入れる
// ADD EDIT MONACARD TO THE CART MONAPARTY
function addEditMonacardToTheCartMonaparty(state, dispatch, editMode, addressOwner, asset, assetCommon, description, divisible, listed, vendable, reassignable) {
  const actionItemsMultiSources = state.cartMonaparty;

  let action;

  if (editMode === 'editMonacard') {
    action = 'editMonacard';
  }
  else if (editMode === 'editAdvancedToken') {
    action = 'editAdvancedToken';
  }

  if (actionItemsMultiSources[addressOwner] === undefined) {
    actionItemsMultiSources[addressOwner] = [];
  }

  actionItemsMultiSources[addressOwner].push(
    {
      transactionType: 'monaparty',
      action: action,   
      status: 'unprocessed',
      addressOwner: addressOwner,
      asset: asset,
      assetCommon: assetCommon,
      quantity: 0,
      description: description,
      divisible: divisible,
      listed: listed,
      vendable: vendable,
      reassignable: reassignable,
    },
  );

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// 追加アセット発行をカゴに入れる
// ADD ADDITIONAL ISSUANCE TO THE CART MONAPARTY
function addAdditionalIssuanceToTheCartMonaparty(state, dispatch, addressOwner, asset, assetCommon, quantity, description, divisible, listed, vendable, reassignable) {
  const actionItemsMultiSources = state.cartMonaparty;
  let done;

  if (actionItemsMultiSources[addressOwner] === undefined) {
    actionItemsMultiSources[addressOwner] = [];
  }

  actionItemsMultiSources[addressOwner].push(
    {
      transactionType: 'monaparty',
      action: 'additionalIssuance',
      status: 'unprocessed',
      addressOwner: addressOwner,
      asset: asset,
      assetCommon: assetCommon,
      quantity: quantity,
      description: description,
      divisible: divisible,
      listed: listed,
      vendable: vendable,
      reassignable: reassignable,
    },
  );

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// 新規発行ロックをカゴに入れる
// ADD LOCK ISSUANCE TO THE CART MONAPARTY
function addLockIssuanceToTheCartMonaparty(state, dispatch, addressOwner, asset, assetCommon, divisible, listed, vendable, reassignable) {
  const actionItemsMultiSources = state.cartMonaparty;

  if (actionItemsMultiSources[addressOwner] === undefined) {
    actionItemsMultiSources[addressOwner] = [];
  }

  actionItemsMultiSources[addressOwner].push(
    {
      transactionType: 'monaparty',
      action: 'lockIssuance',
      status: 'unprocessed',
      addressOwner: addressOwner,
      asset: asset,
      assetCommon: assetCommon,
      quantity: 0,
      description: 'LOCK',
      divisible: divisible,
      listed: listed,
      vendable: vendable,
      reassignable: reassignable,
    },
  );

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// トークン所有権移転をカゴに入れる
// ADD TRANSFER OWNERSHIP TO THE CART MONAPARTY
function addTransferOwnershipToTheCartMonaparty(state, dispatch, addressOwner, asset, assetCommon, addressNewOwner, description, divisible, listed, vendable, reassignable) {
  const actionItemsMultiSources = state.cartMonaparty;
  let done;

  if (actionItemsMultiSources[addressOwner] === undefined) {
    actionItemsMultiSources[addressOwner] = [];
  }

  actionItemsMultiSources[addressOwner].push(
    {
      transactionType: 'monaparty',
      action: 'transferOwnership',
      status: 'unprocessed',
      addressOwner: addressOwner,
      asset: asset,
      assetCommon: assetCommon,
      addressNewOwner: addressNewOwner,
      quantity: 0,
      description: description,
      divisible: divisible,
      listed: listed,
      vendable: vendable,
      reassignable: reassignable,
    },
  );

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// 「ポイント合計:検索」ボタン押下 HANDLE CLICK GET POINT SUM
async function handleClickGetPointSum(state, dispatch, activeCertification, consistentRead) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = {
      address: null,
    };
  }

  if (consistentRead === undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    // addressMain: state.getPointHistory.addressMain,
    // signatureVersion: state.configMP.clientParameters.signatureVersion.getPointSum,
    // addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    if (address !== activeCertification.address) {

      // state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  // activeCertificationの処理

  if (activeCertification.address !== null) {
    // -- state固定
    stateFixed[activeCertification.address] = {
      sessionId: activeCertification.sessionId,
      expirationOfSession: activeCertification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[activeCertification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: activeCertification.address,
        sessionId: stateFixed[activeCertification.address].sessionId,
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  // アクティブなMONAアドレスが有り、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。
  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
  ];

  await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties);

  // 対象がなければ戻る。
  if (certifications.length === 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'pointSum',
      certifications: certifications,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'pointSum', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain]?.certificatedBy === 'signature' || response.body[stateFixed.addressMain]?.certificatedBy === 'sessionId') {
      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'searchPurchaseHistory',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「クリエイター情報編集」ボタン押下 HANDLE CLICK EDIT CREATOR INFORMATION
async function handleClickEditCreatorInformation(state, dispatch, asset, { keyPairs } = {}) {
  let addressMainActual;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    // addressMain: state.cardRegistration.addressMain,
    creater: state.creatorInformation.creater,
    createrText: state.creatorInformation.createrText,
    monacottoAddressMain: state.creatorInformation.monacottoAddressMain,
    createrLink: state.creatorInformation.createrLink,
    // imageMain: state.cardRegistration.imagesNew.main,
    signatureVersion: state.config.clientParameters.signatureVersion.editCreatorInformation,
    // addressCheck: state.addressCheck,
  };

  // 必須項目チェック
  if (asset === undefined || asset === null || asset === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.assetMustBeFilled[state.language] });
    return;
  }

  // // 文字数チェック
  // if (stateFixed.name.length > 25) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.nameIsTooLong[state.language] });
  //   return;
  // }

  // if (stateFixed.feature.length > 70) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.featureIsTooLong[state.language] });
  //   return;
  // }

  // アドレスは入力させていないので、ノーチェックで署名する。
  const clientTime = Date.now();

  if (keyPairs !== undefined && keyPairs !== null) {
    let result;

    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return result;
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      // if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      //   if (addressMainActual !== stateFixed.addressMain) {
      //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      //     return 'rejected';
      //   }
      //   else {
      //     return 'fulfilled';
      //   }
      // }
      // else { // off
      //   return 'fulfilled';
      // }

      return 'fulfilled';
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }

  const formData = new FormData();

  const requestJson = JSON.stringify({
    body: {
      addressMain: addressMainActual,
      addressMainActual: addressMainActual,
      asset: asset,
      creater: stateFixed.creater,
      createrText: stateFixed.createrText,
      monacottoAddressMain: stateFixed.monacottoAddressMain,
      createrLink: stateFixed.createrLink,
      clientTime : clientTime,
      signature : signature,
      signatureVersion : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/edit_creator_information';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "signature by asset owner is required.": words.signatureByAssetOwnerIsRequired[state.language],
    "asset is required; not asset_longname.": words.assetIsRequiredNotAssetLongname[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // -- 登録カード情報取得
    getRegisteredCard(state, dispatch);
  }
  else {
  }

  return response;
}

// UPLOAD IMAGE AND GET CID
async function uploadImageAndGetCid(state, dispatch, imageKeys) {
  let request = {};
  let cid;

  const image = imageKeys.reduce( (acc, cur) => acc?.[cur], state );

  const formData = new FormData();

  if (image !== undefined && image !== null) {
    formData.append('image', image);
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.noImage[state.language] });
    throw new Error('noImage');
  }

  request.body = formData;
  request.headers = {};
  request.url = 'https://card.mona.jp/api/upload_image';
  request.method = 'POST';

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest(request);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    devLog('response.body', response.body);
    cid = response.body.success.cid;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToUploadTheImage[state.language] });
    throw new Error('failedToUploadTheImage');
  }

  return cid;
}

// SORT BY TOKEN NAME
function sortByTokenName(state, assets) {
  // トークン名でソート

  return assets.sort( (a, b) => {
    let aComp;
    let bComp;

    if (state.assetInfo[a.asset]?.asset_longname !== undefined && state.assetInfo[a.asset]?.asset_longname !== null) {
      aComp = state.assetInfo[a.asset].asset_longname; 
    }
    else {
      aComp = a.asset; 
    }

    if (state.assetInfo[b.asset]?.asset_longname !== undefined && state.assetInfo[b.asset]?.asset_longname !== null) {
      bComp = state.assetInfo[b.asset].asset_longname; 
    }
    else {
      bComp = b.asset; 
    }

    if (aComp < bComp) {
      return -1;
    }
    else if (aComp > bComp) {
      return 1;
    }
    else {
      return 0;
    }
  });
}

// KEEP KEY PAIRS IN MEMORY
function keepKeyPairsInMemory(state, dispatch, keyPairs) {
  const now = new Date();

  for (const keyPair of keyPairs) {
    // アドレス導出
    let payment;
    let address;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      address = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    const keyPairData = {
      keyPair: keyPair,
      expiration: now.getTime() + state.config.clientParameters.periodToKeepKeyPairs,
    };

    dispatch({ type: 'setStateMultiLayers', keys: ['keyPairs', address], value: keyPairData });
  }
}

// GET VALID KEY PAIRS
function getValidKeyPairs(state, dispatch) {
  const keyPairs = state.keyPairs;
  const validKeyPairs = {};
  const now = new Date();

  for (const address of Object.keys(keyPairs)) {
    if (keyPairs[address].expiration > now.getTime()) {
      validKeyPairs[address] = keyPairs[address];
    }
  }

  dispatch({ type: 'setState', key: 'keyPairs', value: validKeyPairs });

  return validKeyPairs;
}


// HTTPリクエスト（同期バージョン2）
async function syncHttpRequest2(request, setResultKey, sortFunction, setNotificationKey, messages, dispatch) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  if (request.method === undefined) {
    request.method = 'POST';
  }

  /*
  const method = 'POST';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  */

  const result = await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( async json => {
           const applicationMessage = json.applicationMessage;

           devLog(applicationMessage, messages[applicationMessage], 'setNotificationKey', setNotificationKey);

           if (setNotificationKey !== undefined) {
             devLog('inner', setNotificationKey);
             dispatch({ type: 'setNotification', key: setNotificationKey,
                        value: (messages[applicationMessage] ? messages[applicationMessage] : applicationMessage) });  
           }

           if (json.applicationStatus.substring(0,1) === '2') {
             let body = json.body;

             if (setResultKey !== undefined) {
               devLog(setResultKey);
               devLog(JSON.stringify(body));

               if (sortFunction !== undefined) {
                 body.sort(sortFunction);
               }

               dispatch({ type: 'setState', key: setResultKey, value: body });
             }

             return { status: 'fulfilled', body: body, error: json.errors };
           }
           else {
             return { status: 'rejected', body: json.body };
           }
         }
  )
  .catch( error => {
           return { status: 'rejected', error: error };
          }
  );

  return result;
}

// セッション有無識別スタイル
function indicateSessions(state) {
  let loginStyle;

  if (Object.values(state.session).some( session => session.expirationOfSession > Date.now() )) {
    loginStyle = 'backgroundColorMonacottoPale';
  }
  else {
    loginStyle = 'backgroundColorMonacottoAlmostWhite';
  }

  return loginStyle;
}

// モナカード情報結局
// GET MONACARD INFO AFTER ALL
function getMonacardInfoAfterAll(state, item, size) {
  let cardName;
  let cardOwnerName;
  let cardDescription;
  let cardTag;
  let cid;
  let cardImageUrl;
  let cardImageUrlSP;
  let isAMonacard;

  if (Object.keys(state.assetInfo).length >= 1) { // 分割取得した場合、完全には働かない。
    if (state.assetInfo[item.asset]?.description !== undefined && state.assetInfo[item.asset]?.description !== null && state.assetInfo[item.asset]?.description !== '') {
      try {
        const description = JSON.parse(state.assetInfo[item.asset].description);
        cardName = description?.monacard.name;
        cardOwnerName = description?.monacard.owner;
        cardDescription = description?.monacard.desc;
        cardTag = description.monacard?.tag;
        cid = description.monacard?.cid;

        if (
          cardName !== undefined && cardName !== null && cardName !== '' &&
          cardOwnerName !== undefined && cardOwnerName !== null && cardOwnerName !== '' &&
          cardDescription !== undefined && cardDescription !== null && cardDescription !== '' &&
          cid !== undefined && cid !== null && cid !== ''
        ) {
          if (size === 'large') {
            cardImageUrl = `${state.config.clientParameters.monacardUrl}${cid}`;
            cardImageUrlSP = `${state.config.clientParameters.monacardUrl}${cid}`;
          }
          else { // small
            cardImageUrl = `${state.config.clientParameters.monacardUrl}${cid}m`;
            cardImageUrlSP = `${state.config.clientParameters.monacardUrl}${cid}t`;
          }

          isAMonacard = true;
        }
        else {
          isAMonacard = false;
        }
      }
      catch (err) {
        if (state.monacard[item.asset] !== undefined) {
          cardName = state.monacard[item.asset].card_name;
          cardOwnerName = state.monacard[item.asset].owner_name;
          cardDescription = state.monacard[item.asset].add_description;
          cardTag = state.monacard[item.asset].tag;
          cardImageUrl = state.monacard[item.asset].imgur_url;
          cardImageUrlSP = state.monacard[item.asset].imgur_url;
          isAMonacard = true;
        }
        else {
          cardName = state.assetInfo[item.asset].asset_longname || item.asset;
          cardOwnerName = null;
          cardDescription = state.assetInfo[item.asset].description;
          cardTag = null;
          cardImageUrl = null;
          cardImageUrlSP = null;

          if (Object.keys(state.monacard).length >= 1) { // 分割取得した場合、完全には働かない。
            isAMonacard = false;
          }
        }
      }
    }
    else if (state.assetInfo[item.asset] !== undefined) {
      if (state.monacard[item.asset] !== undefined) {
        cardName = state.monacard[item.asset].card_name;
        cardOwnerName = state.monacard[item.asset].owner_name;
        cardDescription = state.monacard[item.asset].add_description;
        cardTag = state.monacard[item.asset].tag;
        cardImageUrl = state.monacard[item.asset].imgur_url;
        cardImageUrlSP = state.monacard[item.asset].imgur_url;
        isAMonacard = true;
      }
      else {
        cardName = state.assetInfo[item.asset].asset_longname || item.asset;
        cardOwnerName = null;
        cardDescription = state.assetInfo[item.asset].description;
        cardTag = null;
        cardImageUrl = null;
        cardImageUrlSP = null;

        if (Object.keys(state.monacard).length >= 1) { // 分割取得した場合、完全には働かない。
          isAMonacard = false;
        }
      }
    }
    else { // このケースはありえない想定。
      cardName = null;
      cardOwnerName = null;
      cardDescription = null;
      cardTag = null;
      cardImageUrl = null;
      cardImageUrlSP = null;
      isAMonacard = false;
    }
  }
  else { // アセット情報取得途中
    cardName = null;
    cardOwnerName = null;
    cardDescription = null;
    cardTag = null;
    cardImageUrl = null;
    cardImageUrlSP = null;
  }

  return {
    cardName,
    cardOwnerName,
    cardDescription,
    cardTag,
    cid,
    cardImageUrl,
    cardImageUrlSP,
    isAMonacard,
  };
}


// HTTPリクエスト（同期バージョン）
async function syncHttpRequest(request) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  return await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( json => {
    devLog('syncHttpRequest fulfilled', JSON.stringify(json));
    return {
      status: 'fulfilled',
      body: json,
    };
  })
  .catch( error => {
    devLog('syncHttpRequest rejected', JSON.stringify(error));
    return {
      status: 'rejected',
      body: error,
      error: error,
    };
  });
}

function toFloat(string) {
  if (Number.isFinite(string) || string === Infinity) {
    return string;
  }

  const numericCharacter = string.replace(/[^\d.]/, '');
  let float = parseFloat(numericCharacter);

  if (Number.isNaN(float)) {
    float = ''
  }

  return float;
}

async function hasCamera() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'videoinput');
  } catch (error) {
    devLog("An error occurred:", error);
    return false;
  }
}

// SET EPOCH TIME
function setEpochTime(state, dispatch, keys, changedProperty, changedValue) {
  const time = keys.reduce( (acc, cur) => { return acc[cur]; }, state );

  const year = changedProperty === 'year' ? changedValue : time.year;
  const month = changedProperty === 'month' ? changedValue - 1 : time.month - 1;
  const day = changedProperty === 'day' ? changedValue : time.day;
  const hours = changedProperty === 'hours' ? changedValue : time.hours;
  const minutes = changedProperty === 'minutes' ? changedValue : time.minutes;
  const seconds = changedProperty === 'seconds' ? changedValue : time.seconds;
  const milliseconds = changedProperty === 'milliseconds' ? changedValue : time.milliseconds;

  const keysEpoch = keys.concat(['epoch']);

  dispatch({
    type: 'setStateMultiLayersNum',
    keys: keysEpoch,
    value: new Date(year, month, day, hours, minutes, seconds, milliseconds).getTime()
  });
}

// LOCAL TIME
function localTime(epoch, format) {
  const epochNum = parseInt(epoch, 10);
  let time;

  if (Number.isNaN(epochNum) || epochNum === '' || epochNum === undefined) {
    return '';
  }

  time = new Date(epochNum);

  if (format === 'yearToMinute') {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
  }
  else if (format === 'yearToSecond') {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}`;
  }
  else if (format === 'digit14Num') {
    return parseInt(`${time.getFullYear()}${(time.getMonth() + 1).toString().padStart(2, '0')}${time.getDate().toString().padStart(2, '0')}${time.getHours().toString().padStart(2, '0')}${time.getMinutes().toString().padStart(2, '0')}${time.getSeconds().toString().padStart(2, '0')}`, 10);
  }
  else {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}.${time.getMilliseconds()}`;
  }
}

// MINUTES AND SECONDS
function minutesAndSeconds(milliseconds) {
  if (milliseconds <= 0) {
    return '00:00';
  }

  const seconds = Math.floor(milliseconds / 1000); 
  return Math.floor(seconds / 60).toString().padStart(2, '0') + ':' + (seconds % 60).toString().padStart(2, '0');
}

// SLEEP
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// DECIMAL ADJUST
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  // If value is Infinity...
  if (value === Infinity) {
    return Infinity;
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// ADJUST MULTI LAYERS FLOAT
function adjustFloat(type, value, exp) {
  let number = parseFloat(value);

  if (Number.isNaN(number)) {
    number = ''
  }
  else if (type !== undefined) {
    number = decimalAdjust(type, number, exp);
  }

  return number;
}

// URL PARAMS STRINGIFY
function urlParamsStringify(urlParams) {
  return urlParams.reduce( (acc, cur) => {
    if (cur !== null) {
      return acc + (acc === '' ? '?' : '&') + cur;
    }
    else {
      return acc;
    }
  }, '' );
}

// ArrayBufferをBase64文字列に変換する関数
function arrayBufferToBase64(buffer) {
    // ArrayBufferからバイナリ文字列を生成
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    // window.btoa関数でバイナリ文字列をBase64にエンコード
    return window.btoa(binary);
}

// DEVLOG
function devLog(...words) {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    console.log(words.join(' '));
  }
  return 1;
}

// COPY TO CLIPBOARD
function copyToClipboard(text, textAreaRef) {
  if (textAreaRef.current) {
    textAreaRef.current.value = text;
    textAreaRef.current.select();
    document.execCommand('copy');
  }
}

// REDUCER
function reducer(state, action) {
  let newState = { ...state };
  let numericCharacter;
  let number;
  let isChanged;
  let items;
  let item;
  let newIndex;

  switch (action.type) {
    case 'setState':
      newState[action.key] = action.value;
      return newState;

    case 'setStateMultiLayers':
      action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          return acc[cur] = src[idx + 1];
        }
        else {
          return acc[cur];
        }
      }, newState );
      return newState;

    case 'setStateNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    case 'setStateMultiLayersNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        number = ''
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc[cur] = number;
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    case 'setStateFloat':
      number = parseFloat(action.value);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    // setStateMultiLayersFloat
    case 'setStateMultiLayersFloat':
      numericCharacter = action.value.replace(/[^\d.]/, '');
      number = parseFloat(numericCharacter);

      if (Number.isNaN(number)) {
        number = ''
      }
      else if (action.adjustType !== undefined) {
        number = decimalAdjust(action.adjustType, number, action.adjustExp);
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = numericCharacter;
          acc[cur].value = number;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // adjustFace
    case 'adjustFace':
      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = acc[cur].value;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // pushOut
    case 'pushOut':
      const target = action.targetKeys.reduce( (acc, cur) => {
          return acc[cur];
      }, newState );

      action.keys.reduce( (acc, cur, idx, src) => {
        const pushedOut = target[cur];
        target[cur] = acc;
        return  pushedOut;
      }, action.value );

      return newState;

    case 'setStateIfChange':
      if (newState[action.key] != action.value) {
        newState[action.key] = action.value;
        return newState;
      }
      else {
        return state;
     }

    case 'setStateMultiLayersIfChange':
      isChanged = action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          if (acc[cur] !== src[idx + 1]) {
            acc[cur] = src[idx + 1];
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return acc[cur];
        }
      }, newState );

      if (isChanged) {
        return newState;
      }
      else {
        return state;
      }

    // setNotification
    case 'setNotification':
      newState[action.key].body.push(action.value);

      if (newState[action.key].body.length > state.config.clientParameters.notificationMaxLength) {
        newState[action.key].body.shift();
      }

      newState[action.key].index = newState[action.key].body.length -1;

      newState[action.key].inAnimation = true;

      return newState;

    case 'stopNotificationAnimation':
      newState[action.key].inAnimation = false;
      return newState;

    // itemReplace
    case 'itemReplace':
      items = newState.items[newState.gallery.addressMain].itemPlacement[0].items;
      item = items[action.index];

      if (action.direction === 'left') {
        newIndex = action.index - 1 >= 0 ? action.index -1 : items.length - 1;
      }
      else { // right
        newIndex = action.index + 1 <= items.length - 1 ? action.index + 1 : 0;
      }

      items.splice(action.index, 1);
      items.splice(newIndex, 0, item);

      return newState;

    // default
    default:
      return state;
  }
}


export default App;
